import { Route } from 'react-router-dom'
import { HistoryProvider } from "../../../providers";
import LayoutPublic from "../Layout/LayoutPublic";
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {parse} from "query-string";
import {HistoryNext} from "../../../providers/HistoryProvider";


const noRedirectList = ["custom-system-env"]
export default (props) => {
  const { Component } = props

  const token = useSelector((state) => state?.user?.authToken)
  const tokenValue = useMemo(() => token, []) // need to get only the initial value in the store

  if (tokenValue && noRedirectList.every((path) => !props?.path?.includes(path))) {
    const queryString = parse(props.location.search)

    return <HistoryNext nextUrl={queryString.redirect}/>
  }

  return (
    <Route
      {...props}
      render={(props) => (
        <HistoryProvider {...props}>
          <LayoutPublic {...props}>
            <Component {...props} />
          </LayoutPublic>
        </HistoryProvider>
      )}
    />
  )
}
