import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormValues, submit } from 'redux-form'
import { filter, find, findIndex, orderBy } from 'lodash'
import objectAssign from 'object-assign'
import { Space, Empty } from "antd";
import { ControlOutlined, SortAscendingOutlined, TableOutlined } from '@ant-design/icons';
import { ScrollableListView, SettingsContainer } from "../providers/StyleProvider/styles";

import { Tab, Tabs } from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'

import { Icon } from '@ant-design/compatible'
import moment from 'moment'
import { parse } from 'query-string'
import Loading from '../components/Loading'

import { getEnumValues } from '../providers/ReduxProvider/actions/reportActions'
import ReportDataTable from '../components/ReportDataTable'
import ReportSearchFieldsForm from '../components/ReportSearchFieldsForm'

import {
  addAsterisks,
  capitalize,
  checkUserRights,
  getInitialPageSize,
  setInitialPageSize,
  showMessage,
  getFormEditedFields
} from '../utils/appHelper'
import ReportSnapshotDataTable from '../components/ReportSnapshotDataTable'
import Heatmap from '../components/UI/Heatmap'
import SortableSection from '../components/SortableSection'
import * as config from '../constants/globalConfiguration'
import { appDefaults, MAX_REPORT_EXPORT_SIZE } from '../constants/globalConfiguration'
import { listingLayouts } from '../constants'
import { api } from '../providers/ApiProvider'
import { hashHistory } from '../providers/HistoryProvider'
import { Tooltip } from 'antd'
import BatModal from "../components/UI/BatModal";
import { getColumnSort } from "../utils/tableUtils";
import { computeFiltersCount } from '../utils/attributeUtils'
import { __ } from '../utils/translationUtils'

const loadingBar = require('nprogress')

const cancelTokens = []
const defaultHeatMapCenter = [9.19, 45.4642]

function getDeadline({ orderDeadline, processDeadline }) {
  if ((!orderDeadline || !orderDeadline.percentage) && (!processDeadline || !processDeadline.deadline)) return null

  if ((orderDeadline && orderDeadline.percentage) && (!processDeadline || !processDeadline.percentage)) return orderDeadline

  if ((processDeadline && processDeadline.percentage) && (!orderDeadline || !orderDeadline.percentage)) return processDeadline

  return (orderDeadline && orderDeadline.percentage) > processDeadline && processDeadline.deadline
    ? orderDeadline
    : processDeadline
}
function createFeature(feature) {
  const { properties } = feature
  const { processData, priority, orderType, deadline: orderDeadline } = properties || {}
  const { title, responsibleEntities, responsibleUsers, deadline: processDeadline } = processData || {}

  const { priorityValues } = global.constants
  const deadline = getDeadline({ orderDeadline, processDeadline })

  return {
    ...feature,
    properties: {
      ...properties,
      deadlinePercentage: (deadline && deadline.percentage) ? (deadline.percentage / 100) : 0,
      deadlineColor: deadline && deadline.color,
      title,
      deadline: deadline && deadline.deadline && moment(deadline.deadline).format(appDefaults.dateTimeFormat),
      priorityLabel: priority,
      priorityIconProps: priorityValues[priority],
      users: responsibleUsers || [],
      companies: responsibleEntities || [],
      color: orderType && orderType.color
    }
  }
}

class ReportPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      initialRender: true,
      searchMasksOpen: false,
      waitingRequiredFields: false,
      sortingOpen: false,
      snapshots: [],
      resultCounts: [],
      selectedTab: 'orders',
      showAllSnapshots: true,
      reportId: this.props.match.params.id,
      orderGroupId: this.props.match.params.orderGroupId,
      reportSpec: null,
      reportData: null,
      reportDataSpecified: null,
      heatMapItems: {},
      heatMapCenter: defaultHeatMapCenter,
      users: null,
      viewMode: listingLayouts.TABLE,
      organisations: null,
      pagination: {
        pageNumber: 1,
        pageSize: getInitialPageSize('reportPage'),
        totalCount: 0
      },
      selectedColumns: [],
      sort: '',
      isExport: false,
      submittedFormValues: null
    }
    this.searchSubmitRef = React.createRef();
    this.sortSubmitRef = React.createRef();
    this.reset = this.reset.bind(this)
    this.extractConfiguration = this.extractConfiguration.bind(this)
    this.extractColumns = this.extractColumns.bind(this)
    this.getOrgs = this.getOrgs.bind(this)
    this.getReportSpec = this.getReportSpec.bind(this)
    this.getReportData = this.getReportData.bind(this)
    this.getPaginatedData = this.getPaginatedData.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
    this.setPageSize = this.setPageSize.bind(this)
    this.toggleColumnSelection = this.toggleColumnSelection.bind(this)
    this.getUsers = this.getUsers.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setSortAttributes = this.setSortAttributes.bind(this)
    this.setSortAttributeValue = this.setSortAttributeValue.bind(this)
    this.setShowAllOrders = this.setShowAllOrders.bind(this)
    this.onSortAttributesSortEnd = this.onSortAttributesSortEnd.bind(this)
    this.cancelAllTokens = this.cancelAllTokens.bind(this)
    this.refreshData = this.refreshData.bind(this)
    this.handleViewMode = this.handleViewMode.bind(this)
    this.handleClusterSelect = this.handleClusterSelect.bind(this)
  }

  componentDidMount() {
    this.reset()
    this.getUsers()
  }

  componentDidUpdate(prevProps) {
    if (this.state?.initialRender || (prevProps.match?.params?.id !== this.props.match?.params?.id && prevProps?.history?.action === 'PUSH')) {
      this.reset()
    }
  }

  handleViewMode(viewMode) {
    if (!Object.values(listingLayouts).includes(viewMode)) {
      console.error('View Mode can only be of type listing or heatMap')
      return
    }

    this.setState({ viewMode })
  }

  cancelAllTokens() {
    cancelTokens.map((cancelToken) => {
      cancelToken('canceled')
      const index = cancelTokens.indexOf(cancelToken)
      if (index > -1) {
        cancelTokens.splice(index, 1)
      }
    })
  }

  getOrgs() {
    return api.get('entities/?pageSize=100&type=organisation')
      .then(
        (response) => {
          this.setState({ organisations: response.data })
        },
        (error) => {
          console.log(error)
        }
      )
  }

  getUsers() {
    api.get('/users?pageSize=200')
      .then(
        (response) => {
          this.setState({ users: response.data })
        },
        (error) => {
          console.log(error)
        }
      )
  }

  extractColumns(reportSpec) {
    const columns = []
    if (!reportSpec) {
      return columns
    }
    reportSpec.displayAttributes.map((a) => {
      // Check if displayable
      if (
        typeof this.extractConfiguration(a.attributeId) !== 'undefined'
        && this.extractConfiguration(a.attributeId).displayable
      ) {
        columns.push(this.extractConfiguration(a.attributeId).propertyLabel)
      }
    })
    return columns
  }

  reset() {
    this.setState({
      initialRender: false,
      isLoading: false,
      searchMasksOpen: false,
      showAllSnapshots: true,
      reportId: this.props.match.params.id,
      reportSpec: null,
      reportData: null,
      reportDataSpecified: null,
      snapshots: [],
      pagination: {
        pageNumber: 1,
        pageSize: getInitialPageSize('reportPage'),
        totalCount: 0
      },
      selectedColumns: [],
      sort: ''
    }, () => {
      this.getOrgs().then(() => {
        this.getReportSpec()
      })
    })
  }

  refreshData() {
    this.getReportData()
  }

  setSortAttributes(attributes) {
    const spec = { ...this.state.reportSpec }
    spec.sortAttributes = attributes

    this.setState({ reportSpec: spec })
  }

  setShowAllOrders(value) {
    const spec = { ...this.state.reportSpec }
    spec.onlySelfOrders = value
    this.setState({ reportSpec: spec }, () => {
      this.getReportData()
    })
  }

  setSortAttributeValue(attribute, value) {
    const attributes = [].concat(this.state.reportSpec.sortAttributes)
    const idx = findIndex(attributes, (a) => a.attributeId === attribute.attributeId)
    attributes[idx].value = value

    this.setState({ reportSpec: objectAssign(this.state.reportSpec, { sortAttributes: attributes }) })
  }

  onSortAttributesSortEnd({ oldIndex, newIndex }) {
    const attributes = [].concat(this.state.reportSpec.sortAttributes)
    const item1 = find(attributes, (attr) => attr.position === oldIndex)

    if (newIndex > oldIndex) {
      // Update the position of all the previus items
      const prevItems = filter(attributes, (attr) => attr.position >= oldIndex && attr.position <= newIndex)
      prevItems.forEach((item) => {
        item.position -= 1
      })
    } else {
      // Update the position of all the previus items
      const prevItems = filter(attributes, (attr) => attr.position >= newIndex && attr.position <= oldIndex)
      prevItems.forEach((item) => {
        item.position += 1
      })
    }
    item1.position = newIndex
    this.setSortAttributes(attributes)
  }

  handleSubmit() {
    const { pagination, reportSpec, selectedTab } = this.state
    const _values = this.props.formValues
    const values = { ..._values }

    this.cancelAllTokens()
    const { isExport } = this.state
    const { useRequestedOrderGroupId, type, guiUser } = this.props

    let apiurl = (type === 'report') ? '/configurations/reports' : '/configurations/views'
    if (type.toLowerCase() === 'remoteobject') {
      apiurl = '/configurations/remoteObjects'
    }

    if (selectedTab === 'snapshots') {
      let snapshotsEndpoint

      if (reportSpec.onlySelfOrders) {
        snapshotsEndpoint = 'visibleOrders'
      } else {
        snapshotsEndpoint = 'orders'
      }

      apiurl = `snapshots/${snapshotsEndpoint}`
      if (this.state.showAllSnapshots) {
        apiurl = `visibleSnapshots/${snapshotsEndpoint}`
      } else {
        apiurl = `snapshots/${snapshotsEndpoint}`
      }
    }
    const requestBody = objectAssign({}, {
      id: reportSpec.id,
      name: reportSpec.name,
      pageSize: pagination.pageSize, // this.state.reportSpec.pageSize,
      pageNumber: pagination.pageNumber,
      onlySelfOrders: reportSpec.onlySelfOrders,
      // propertyLabel: this.state.reportSpec.propertyLabel,
      displayAttributes: reportSpec.displayAttributes,
      sortAttributes: reportSpec.sortAttributes?.filter(({ enabled }) => enabled == null || enabled),
      settings: reportSpec?.settings
    })

    if (type.toLowerCase() === 'remoteobject') {
      requestBody.objectTypeId = reportSpec.objectTypeId
      requestBody.systemId = reportSpec.systemId
    }

    const callBack = (response) => {
      this.setState({
        reportData: response.data,
        reportDataSpecified: response.data,
        heatMapItems: this.handleHeatMapItems(response.data),
        isLoading: false,
        searchMasksOpen: false,
        pagination: objectAssign(
          this.state.pagination, {
          totalCount: parseInt(response.headers['x-total-count']),
          pageSize: parseInt(response.headers['x-page-size']),
          pageNumber: parseInt(response.headers['x-page'])
        }
        )
      }, () => {
        loadingBar.done()
      })
    }

    const _attrs = [].concat(this.state.reportSpec.searchAttributes)

    const attrs = []
    _attrs.forEach((_r) => {
      const r = { ..._r }
      const attributeInfo = this.extractConfiguration(_r.attributeId)
      // RANGE
      if (r.mechanism === 'RANGE') {
        if (attributeInfo.type === 'DATE') {
          if (values[`${r.attributeId}From`]) {
            // Values From round down to the nearest minute
            const roundDown = values[`${r.attributeId}From`].startOf('minute')
            values[`${r.attributeId}From`] = roundDown || undefined
          }
          if (values[`${r.attributeId}To`]) {
            // Values To round up to the nearest minute
            const toMoment = values[`${r.attributeId}To`]
            const roundUp = toMoment.second() || toMoment.millisecond() ? toMoment.add(1, 'minute').startOf('minute') : toMoment.startOf('minute')
            values[`${r.attributeId}To`] = roundUp || undefined
          }
        }

        if (typeof values[`${r.attributeId}From`] !== 'undefined' || values[`${r.attributeId}From`] !== '') {
          r.from = values[`${r.attributeId}From`]
        } else {
          delete values[`${r.attributeId}From`]
        }

        if (typeof values[`${r.attributeId}To`] !== 'undefined' || values[`${r.attributeId}To`] !== '') {
          r.to = values[`${r.attributeId}To`]
        } else {
          delete values[`${r.attributeId}To`]
        }

        /*
         * Apply Felix's Algorithm on range fields
         * 1)If both *from* and *to* are filled --> normal *range* search.
         * 2) If only *from* is filled --> *specic_value* search. (the from can contain wildcard)
         * 3) if only *to* is filled the webui fills the from value with the same value contained in the *to* --> *range* search (edited)
         */

        // Applies only to RANGE.
        if (r.mechanism === 'RANGE' && (r.to || r.from)) {
          const { to, from } = r
          if (!(attributeInfo.type === 'DATE')) {
            if (from && !to) {
              r.mechanism = 'SPECIFIC_VALUE'
              r.value = from
              delete r.from
              delete r.to
              delete r.multiSelect
            } else if (!from && to) {
              r.from = to
            }
          } else if (from && !to) {
            r.from = from.utc().format()
            r.to = from.add(1, 'minute').utc().format()
          } else if (!from && to) {
            r.to = to.utc().format()
            r.from = to.subtract(1, 'minute').utc().format()
          } else {
            r.from = from.utc().format()
            r.to = to.utc().format()
          }
        }

        // SPECIFIC_VALUE
      } else if (r.mechanism === 'SPECIFIC_VALUE') {
        if (attributeInfo.type === 'DATE' && values[r.attributeId]) {
          values[r.attributeId] = values[r.attributeId].utc().format()
        }

        if (typeof values !== 'undefined' && typeof values[r.attributeId] !== 'undefined' && values[r.attributeId] !== '') {
          if (config.TYPES_NO_ASTERISKS_ALLOWED.includes(attributeInfo?.type?.toUpperCase())) {
            r.value = values[r.attributeId]
          } else if (!r.hidden) {
            r.value = r.exactMatch ? values[r.attributeId] : addAsterisks(values[r.attributeId].toString())
          }

          if (r.value === 'true') {
            r.value = true
          }
          if (r.value === 'false') {
            r.value = false
          }
        } else {
          delete r.value
        }
        // ENUMERATION
      } else if (r.mechanism === 'ENUMERATION') {
        const requiresobfuscation = (r.obfuscationMethod != null && r.obfuscationValue != null)
        const _value = [].concat(values[r.attributeId])
        if (typeof values[r.attributeId] !== 'undefined' && values[r.attributeId].length > 0) {
          // If obfuscationMethod == byTypeEqualsUnionChildren, then select children entities too
          if (requiresobfuscation) {
            switch (r.obfuscationMethod) {
              case 'byTypeEqualsUnionChildren':
                _value.forEach((parent) => {
                  const found = filter(r.values, (v) => v.parent === parent)
                  if (found) {
                    found.forEach((child) => {
                      _value.push(child.id)
                    })
                  }
                })
                break
            }
          }

          r.value = _value

          if (_value.constructor === Array) {
            r.value = _value.join(',')
          }
        } else {
          delete r.value
        }
      }

      if ((values[r.attributeId] == undefined || values[r.attributeId] === '')
        && (values[`${r.attributeId}From`] == undefined || values[`${r.attributeId}From`] === '')
        && (values[`${r.attributeId}To`] == undefined || values[`${r.attributeId}To`] === '')
      ) {
        return
      }
      attrs.push(r)

      // remove if empty value
      if (values[r.attributeId] != undefined && values[r.attributeId]?.length === 0) {
        //  if (typeof values[r.fieldId] !== 'undefined' && values[r.fieldId].length === 0) {
        //    const idx = findIndex(attrs, (f) => f.fieldId === r.fieldId);
        const idx = findIndex(attrs, (f) => f.attributeId === r.attributeId)
        if (idx > -1) {
          attrs.splice(idx, 1)
        }
      }
    })

    requestBody.searchAttributes = attrs

    let requestParams = '?'

    if (useRequestedOrderGroupId) {
      requestParams += `orderGroupIds=${this.state.orderGroupId}&`
    }

    if (isExport) {
      loadingBar.start()
      if (this.state.pagination.totalCount > MAX_REPORT_EXPORT_SIZE) {
        showMessage('warning', `${__('Only exporting first')} ${MAX_REPORT_EXPORT_SIZE} ${__('results')}`)
      }
      requestBody.pageSize = MAX_REPORT_EXPORT_SIZE
      api.post(`${apiurl}/results/export${requestParams}`, requestBody, { responseType: 'arraybuffer' })
        .then((response) => {
          if (response.status === 200) {
            const a = document.createElement('a')
            document.body.appendChild(a)
            a.style = 'display: none'
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const url = window.URL.createObjectURL(blob)
            a.href = url
            const name = `${moment().format(config.appDefaults.dateFormatFiles)}_${guiUser.username}_${reportSpec.name || 'report'}`
            a.download = `${name.replace(/([^a-zA-Z0-9\-_]|\s)+/g, "")}.xlsx`

            a.click()
            window.URL.revokeObjectURL(url)

            this.setState({ isLoading: false })
            loadingBar.done()
          }
        }).catch((ex) => {
          this.setState({ isLoading: false })
          loadingBar.done()
        })
    } else {
      this.setState({
        reportData: [],
        reportDataSpecified: [],
        isLoading: true
      }, () => {
        loadingBar.start()
        api.post(`${apiurl}${this.state.selectedTab === 'snapshots' ? '' : '/results'}${requestParams}`, requestBody).then(callBack)
          .catch(() => {
            this.setState({ isLoading: false })
            loadingBar.done()
          })
      })
    }
    // reset isExport
    this.setState({ isExport: false, submittedFormValues: { ..._values } })
  }

  getReportSpec() {
    const { location, type } = this.props

    let apiurl = (type === 'report') ? '/configurations/reports' : '/configurations/views'
    if (type.toLowerCase() === 'remoteobject') {
      apiurl = '/configurations/remoteObjects'
    }
    this.setState({
      reportId: this.props.match.params.id,
      reportSpec: null,
      selectedColumns: []
    }, () => {
      loadingBar.start()
      api.get(`${apiurl}/${this.state.reportId}`)
        .then((response) => {
          const reportData = response.data
          // Perform request to get available values
          const enumsToGetValues = []

          reportData.searchAttributes.forEach((attribute) => {
            if (attribute.mechanism === 'ENUMERATION') {
              const enumId = this.extractConfiguration(attribute.attributeId).enumerationId
              if (enumId) {
                enumsToGetValues.push(enumId)
              }
            }
          })

          if (enumsToGetValues.length > 0) {
            enumsToGetValues.forEach((enumId) => {
              this.props.dispatch(getEnumValues(enumId))
            })
          }
          this.setState({
            reportSpec: reportData || null,
            pagination: {
              ...this.state.pagination,
              pageSize: reportData?.settings?.pageLayout?.pageSize || this.state.pagination.pageSize
            },
            viewMode: reportData.settings && reportData.settings.pageLayout && reportData.settings.pageLayout.listingLayout && reportData.settings.pageLayout.listingLayout.defaultSelected
          }, () => {
            loadingBar.done()
            this.setState({
              selectedColumns: this.extractColumns(reportData),
              availableColumns: this.extractColumns(reportData)
            }, () => {
              const blockSubmission = reportData.searchAttributes?.some((f) => {
                if (!f.required) return false
                if (f.mechanism === 'RANGE') return f.from == undefined || f.to == undefined
                return f.value == undefined && f.values == undefined
              })

              if (!blockSubmission) this.handleSubmit()
              else this.setState({ searchMasksOpen: true, waitingRequiredFields: true })
            })
          })
        })
        .catch((error) => {
          if (error.request && error.request.status === 404) {
            const queryString = parse(location.search)
            hashHistory.push(queryString.backUrl || config.ordersDefaultUrl)
          }
        })
    })
  }

  getReportData() {
    this.props.dispatch(submit('reportSearchFieldsForm'))
  }

  onPageChange(page) {
    const spec = { ...this.state.reportSpec }
    spec.pageNumber = page

    this.setState({
      reportSpec: spec,
      pagination: objectAssign(
        this.state.pagination, { pageNumber: page }
      )
    }, () => {
      this.getReportData()
    })
  }

  onSortChange(sortName, sortOrder) {
    const sort = getColumnSort(sortName, sortOrder, this.state.reportSpec.sortAttributes)?.[0]

    const spec = {
      ...this.state.reportSpec,
      sortAttributes: [{
        ...sort,
        position: 1,
      }]
    }

    this.setState({ reportSpec: spec }, () => {
      this.getReportData()
    })
  }

  setPageSize(val) {
    this.setState({
      pagination: objectAssign(
        this.state.pagination, {
        totalCount: this.state.reportDataSpecified.length,
        pageSize: val,
        pageNumber: 1
      }
      )
    }, () => {
      this.getReportData()
      setInitialPageSize('reportPage', val)
    })
  }

  getPaginatedData(page_size, page_number) {
    const _data = this.state.reportDataSpecified.slice(
      (page_number - 1) * page_size,
      (page_number) * page_size
    )
    return _data
  }

  extractConfiguration(id) {
    const found = find(this.props.attributesList, (c) => c.id === id)
    return found
  }

  toggleColumnSelection(columnName) {
    const selected = this.state.selectedColumns.concat([])
    const idx = selected.indexOf(columnName)
    if (idx > -1) {
      selected.splice(idx, 1)
    } else {
      selected.push(columnName)
    }

    this.setState({ selectedColumns: selected })
  }

  render() {
    const {
      selectedColumns,
      reportDataSpecified,
      reportSpec,
      isLoading,
      buttonSize,
      pagination,
      selectedTab,
      viewMode,
      waitingRequiredFields,
      submittedFormValues,
      searchMasksOpen,
      resultCounts,
      showAllSnapshots,
      users,
      heatMapCenter,
      heatMapZoom,
      heatMapItems,
    } = this.state

    const {
      enumValues,
      guiUser,
      type,
      formValues,
      attributesList
    } = this.props
    const ordersResultsHint = () => {
      if (typeof resultCounts.orders === 'undefined') {
        return ''
      }

      if (resultCounts.orders > 0 && selectedTab !== 'orders') {
        return `(${resultCounts.orders})`
      }

      return ''
    }

    const snapshotsResultsHint = () => {
      if (typeof resultCounts.snapshots === 'undefined') {
        return ''
      }

      if (resultCounts.snapshots > 0 && selectedTab !== 'snapshots') {
        return `(${resultCounts.snapshots})`
      }

      return ''
    }

    const userRights = guiUser && guiUser.rights || []
    const initialValues = {}
    reportSpec && reportSpec.searchAttributes.forEach((f) => {
      const config = this.extractConfiguration(f.attributeId)
      if (f.multiSelect && typeof f.value !== 'undefined') {
        initialValues[f.attributeId] = f.value.split(',')
      } else if (f.mechanism === 'RANGE' && config.type === 'DATE') {
        initialValues[`${f.attributeId}From`] = f.from && moment(f.from)
        initialValues[`${f.attributeId}To`] = f.to && moment(f.to)
      } else if (f.mechanism === 'RANGE' && config.type !== 'DATE') {
        initialValues[`${f.attributeId}From`] = f.from
        initialValues[`${f.attributeId}To`] = f.to
      } else {
        initialValues[f.attributeId] = f.value
      }
    })

    let remoteObjectSpec = null
    if (type.toLowerCase() === 'remoteobject') {
      const dependencies = reportSpec && reportSpec.displayAttributes && reportSpec.displayAttributes[0] && reportSpec.displayAttributes[0].attributeId && this.extractConfiguration(reportSpec.displayAttributes[0].attributeId).dependencies
      remoteObjectSpec = orderBy(
        filter(
          attributesList,
          (attr) => attr.dependencies === dependencies
        ),
        'position'
      )
    }
    const { rightMappings } = global.constants
    if (!viewMode) {
      this.setState({ viewMode: listingLayouts.TABLE })
    }

    const renderSnapshotTabs = () => {
      if (selectedTab && type.toLowerCase() === 'view' && reportDataSpecified && viewMode === listingLayouts.TABLE) {
        // TODO: Remove this as soon as the configurations are migrated.
        if (reportSpec.settings.pageLayout.showSnapshot || reportSpec.settings.pageLayout.showSnapshot === undefined) {
          return (<Tabs
            onSelect={(activeKey) => {
              this.setState(
                { loading: true, selectedTab: activeKey, reportDataSpecified: null, reportData: null },
                () => {
                  this.handleSubmit()
                }
              )
            }}
            id="orderListingTabs"
            className="simple"
            style={{ marginTop: '10px' }}
            defaultActiveKey={selectedTab}
          >
            <Tab
              eventKey="orders"
              key="orders"
              title={`${__('orders')} ${ordersResultsHint.bind(this)()}`}
            />
            <Tab
              eventKey="snapshots"
              key="snapshots"
              title={`${__('Snapshots')} ${snapshotsResultsHint.bind(this)()}`}
            />
          </Tabs>)
        }
        return null
      }
      return null
    }

    return (
      <ScrollableListView id="main-content">
        <Loading loading={isLoading} />
        <h1 className="sticky">
          <div className="container">
            {reportSpec ? capitalize(__(reportSpec.name)) : __('loading')}
          </div>
        </h1>
        <SettingsContainer>
          <div className={"left"}>
            {reportSpec && viewMode === listingLayouts.TABLE &&
              <Space size="middle" style={{ marginLeft: "15px" }}>
                <BatModal
                  forceRender={true}
                  setForceOpen={(state) => { this.setState({ searchMasksOpen: state }) }}
                  forceOpen={searchMasksOpen}
                  disabled={!reportSpec.searchAttributes?.length}
                  buttonBadgeCount={computeFiltersCount(submittedFormValues || initialValues, initialValues)}
                  buttonProps={{ icon: <ControlOutlined /> }}
                  title={__('Filters')}
                  onSubmitCallback={() => this.handleSubmit()}
                  onCancelCallback={() => {
                    const toReset = getFormEditedFields({
                      submittedFormValues: submittedFormValues,
                      initialValues: initialValues,
                      actualFormValues: formValues
                    })
                    if (this.searchSubmitRef?.current && toReset) this.searchSubmitRef.current.handleFormValuesInject(toReset)
                  }}
                  footer={
                    <>
                      <div className="left">
                        <a onClick={(e) => this.searchSubmitRef.current?.handleReset(e)}>{__('Reset')}</a>
                      </div>
                      <div className="right">
                        <button
                          onClick={(e) => this.searchSubmitRef.current?.handleSubmit(e)}
                          disabled={isLoading}
                          className={`btn btn-${buttonSize} btn-primary ${buttonSize === 'md' ? 'btn-block' : ''}`}
                        >
                          {__('Search')}
                        </button>
                      </div>
                    </>
                  }
                >
                  {reportSpec && viewMode === listingLayouts.TABLE &&
                    <ReportSearchFieldsForm
                      componentRef={this.searchSubmitRef}
                      isLoading={isLoading}
                      guiUser={this.props.guiUser}
                      reportSpec={reportSpec}
                      fields={reportSpec.searchAttributes}
                      onPageChange={this.onPageChange}
                      attributesConfiguration={attributesList}
                      sortAttributes={reportSpec.sortAttributes}
                      extractConfiguration={this.extractConfiguration}
                      initialValues={initialValues}
                      enumValues={enumValues}
                      onSubmit={this.handleSubmit}
                      setShowAllOrders={this.setShowAllOrders}
                    />
                  }
                </BatModal>
                <BatModal
                  title={__('Sorting')}
                  className="medium"
                  disabled={!reportSpec.sortAttributes?.length}
                  buttonProps={{ icon: <SortAscendingOutlined /> }}
                  onSubmitCallback={() => this.sortSubmitRef.current?.handleApplySorting()}
                  footer={
                    <div className="right">
                      <button
                        className={`btn btn-${buttonSize} btn-primary ${buttonSize === 'md' ? 'btn-block' : ''}`}
                        onClick={() => this.sortSubmitRef.current?.handleApplySorting()}
                      >
                        {__("Apply Sorting")}
                      </button>
                    </div>
                  }
                >
                  <SortableSection
                    componentRef={this.sortSubmitRef}
                    items={reportSpec.sortAttributes}
                    activeIds={reportSpec.sortAttributes.map((attr) => attr.attributeId)}
                    applySorting={(items) => {
                      this.setSortAttributes(items)
                      this.getReportData()
                    }}
                    attributesConfiguration={attributesList}
                  />
                </BatModal>
                <BatModal
                  title={__('Columns')}
                  className="medium"
                  buttonProps={{ icon: <TableOutlined /> }}
                  footer={
                    <>
                      <div className="left">
                        <a onClick={() => { this.setState({ selectedColumns: [] }) }} disabled={isLoading || selectedColumns.length === 0} >
                          {__('Clear All')}
                        </a>
                      </div>
                      <div className="right">
                        <button
                          onClick={() => { this.setState({ selectedColumns: this.extractColumns(reportSpec) }) }}
                          disabled={isLoading || selectedColumns.length === this.extractColumns(reportSpec).length}
                          className={`btn btn-${buttonSize} btn-primary ${buttonSize === 'md' ? 'btn-block' : ''}`}
                        >
                          {__('Check All')}
                        </button>
                      </div>
                    </>
                  }
                >
                  {this.extractColumns(reportSpec) && this.extractColumns(reportSpec).map((columnName, idx) => (
                    <div
                      key={`${columnName}-${idx}`}
                      className="pull-left"
                      style={{ margin: '0 30px 10px 0' }}
                    >
                      <label key={columnName} htmlFor={columnName}>
                        <input
                          checked={selectedColumns.indexOf(columnName) > -1}
                          onChange={() => {
                            this.toggleColumnSelection(columnName)
                          }}
                          type="checkbox"
                          id={columnName}
                          name={columnName}
                        /> {__(columnName)}
                      </label>
                    </div>
                  ))}
                </BatModal>
              </Space>
            }
          </div>
          {reportSpec && checkUserRights(userRights, rightMappings.CAN_SEE_ALL_ORDERS)
            && (
              <div className="col-xs-12 col-sm-12 text-right clearfix">
                <div className="right">
                  {selectedTab === 'snapshots'
                    && checkUserRights(userRights, rightMappings.CAN_SEE_ALL_SNAPSHOTS)
                    && (
                      <label
                        className={isLoading ? 'checkbox-container disabled' : 'checkbox-container'}
                        onClick={() => {
                          this.setState({
                            pagination: objectAssign(
                              pagination, {
                              totalCount: 0,
                              pageNumber: 1
                            }
                            ),
                            showAllSnapshots: !showAllSnapshots
                          }, () => {
                            this.handleSubmit()
                          })
                        }}
                      >
                        <Glyphicon
                          glyph={showAllSnapshots ? 'check' : 'unchecked'}
                        />
                        {__('ShowAllSnapshots')}
                      </label>
                    )}
                  {selectedTab === 'orders'
                    && reportSpec
                    && reportSpec.settings
                    && reportSpec.settings.pageLayout
                    && reportSpec.settings.pageLayout.listingLayout
                    && Array.isArray(reportSpec.settings.pageLayout.listingLayout.available)
                    && reportSpec.settings.pageLayout.listingLayout.available.length > 1

                    && (
                      <div className="col-sm-1 mr-5" style={{ minWidth: "100px" }}>
                        <div className="col-sm-12 view-mode-options">
                          {reportSpec.settings.pageLayout.listingLayout.available.includes(listingLayouts.TABLE)
                            && (
                              <Icon
                                type="unordered-list"
                                style={viewMode === listingLayouts.TABLE ? { color: '#1ea5c7' } : {}}
                                onClick={() => this.handleViewMode(listingLayouts.TABLE)}
                              />
                            )}
                          {reportSpec.settings.pageLayout.listingLayout.available.includes(listingLayouts.HEATMAP)
                            && (
                              <Icon
                                type="environment"
                                style={viewMode === listingLayouts.HEATMAP ? { color: '#1ea5c7' } : {}}
                                onClick={() => this.handleViewMode(listingLayouts.HEATMAP)}
                              />
                            )}
                        </div>
                      </div>
                    )}
                  {viewMode !== listingLayouts.HEATMAP && type.toLowerCase() !== 'remoteobject' && (
                    <Tooltip title={__('ShowOnlySelfOrders')}><div className="btn-no-padding btn-std" onClick={() => this.setShowAllOrders(!reportSpec.onlySelfOrders)}><Icon className="icon-std" type={reportSpec.onlySelfOrders ? 'check-square' : 'border'} theme="outlined" /></div></Tooltip>
                  )}
                  {selectedTab === 'orders'
                    && (
                      <Tooltip title={__('refresh data')}><div className="btn-no-padding btn-std" onClick={() => this.handleSubmit()}><Icon className="icon-std" type="redo" theme="outlined" /></div></Tooltip>
                    )}
                  {selectedTab === 'orders'
                    && (
                      <Tooltip title={__('export')}><div className="btn-no-padding btn-std" onClick={() => this.setState({ isExport: true }, () => this.props.dispatch(submit('reportSearchFieldsForm')))}><Icon className="icon-std" type="download" theme="outlined" /></div></Tooltip>
                    )}
                </div>
              </div>
            )}
        </SettingsContainer>

        <div className="container-fluid report-view-page">

          {renderSnapshotTabs()}
          {!reportDataSpecified && !isLoading && !(selectedTab === 'snapshots') && viewMode === listingLayouts.TABLE && waitingRequiredFields
            &&
            <Empty
              style={{ justifySelf: "center", height: "auto", overflow: "unset", margin: "auto" }}
              description={
                <span>{__('Please fill the required fields in') + ' '}
                  <a onClick={(e) => { e.preventDefault(); this.setState({ searchMasksOpen: true }) }}>
                    {__('search parameters')}
                  </a>
                </span>
              }
            />
          }
          {reportDataSpecified && !isLoading && !(selectedTab === 'snapshots') && viewMode === listingLayouts.TABLE
            && (
              <div className={"not-snapshots"}>
                <ReportDataTable
                  {...this.props}
                  remoteObjectSpec={{ displayData: remoteObjectSpec }}
                  location={this.props.location}
                  data={reportDataSpecified}
                  viewConfiguration={reportSpec}
                  users={users}
                  attributesConfiguration={attributesList}
                  reportSpec={reportSpec}
                  pagination={pagination}
                  guiUser={this.props.guiUser}
                  isLoading={isLoading}
                  onPageChange={this.onPageChange}
                  onSortChange={this.onSortChange}
                  selectedColumns={selectedColumns}
                  updateData={() => {
                  }}
                  selectedTab={selectedTab}
                  setPageSize={this.setPageSize}
                  refreshData={this.refreshData}
                  type={type}
                />
              </div>
            )}
          {
            reportDataSpecified && !isLoading && (selectedTab === 'snapshots') && viewMode === listingLayouts.TABLE
            && (
              <div className={"snapshots"}>
                <ReportSnapshotDataTable
                  reportSpec={reportSpec}
                  data={reportDataSpecified}
                  pagination={pagination}
                  isLoading={isLoading}
                  onPageChange={this.onPageChange}
                  onSortChange={this.onSortChange}
                  selectedColumns={selectedColumns}
                  attributesConfiguration={attributesList}
                  setPageSize={this.setPageSize}
                />
              </div>
            )
          }
          {
            viewMode === listingLayouts.HEATMAP && heatMapItems && heatMapItems.features
            && (
              <div className="row ">
                <div className="col-xs-12" style={{ marginTop: '1rem' }}>
                  <Heatmap
                    items={heatMapItems && {
                      ...heatMapItems,
                      features: heatMapItems.features.map(createFeature)
                    }}
                    onClusterSelect={this.handleClusterSelect}
                    center={heatMapCenter}
                    zoom={heatMapZoom}
                    clusterDefaultColor="#ffffff"
                    shouldFitBounds={JSON.stringify(heatMapCenter) === JSON.stringify(defaultHeatMapCenter)}
                    clusterThresholdsPropertyName="deadlinePercentage"
                    clusterThresholds={[
                      {
                        color: '#64D868',
                        limit: 0.2
                      },
                      {
                        color: '#D1DB5B',
                        limit: 0.4
                      },
                      {
                        color: '#FDE52C',
                        limit: 0.6
                      },
                      {
                        color: '#FA9013',
                        limit: 0.8
                      },
                      {
                        color: '#FF0000',
                        limit: 1
                      }
                    ]}
                  />
                </div>
              </div>
            )
          }

        </div>
      </ScrollableListView>
    )
  }

  handleHeatMapItems(orderList) {
    const { users, organisations } = this.state
    // For now I have to hardcode it, I hope in the future this behaviour will be changed
    const responsibleUserId = '100_58be96d00e823f552aa1a070'
    const responsibleOrgId = '100_58be96d00e823f552aa1a071'
    if (!Array.isArray(orderList)) {
      return {}
    }
    const processList = []
    orderList.forEach((order) => {
      if (!Array.isArray(order.processOverviews)) {
        return null
      }

      order.processOverviews.forEach((process) => {
        if (!process || !process.location || !Array.isArray(process.location.coordinates)) {
          return null
        }
        const mapCoordinates = [...process.location.coordinates].reverse()
        const geometry = { ...process.location, coordinates: [...mapCoordinates, 0.0] }

        const responsibleUsers = Array.isArray(order.displayData[responsibleUserId]) ? order.displayData[responsibleUserId].filter((user) => user.parentObjectId === process.id).map((e) => ({ ...e, ...(users.find((u) => u.id === e.id) || {}) })) : []

        const responsibleEntities = Array.isArray(order.displayData[responsibleOrgId]) ? order.displayData[responsibleOrgId].filter((org) => org.parentObjectId === process.id).map((e) => ({ ...e, ...(organisations.find((o) => o.id === e.id) || {}) })) : []

        processList.push({
          type: 'Feature',
          properties: {
            id: order.id,
            priority: order.orderOverview.priority,
            deadline: order.orderOverview.deadline,
            orderType: order.orderOverview.orderType,
            processData: {
              ...process,
              responsibleUsers,
              responsibleEntities
            }
          },
          geometry: { ...geometry }
        })
      })
    })

    return {
      type: 'ProcessNearDeadline',
      features: processList
    }
  }

  handleClusterSelect = ({ center, items, zoom }) => {
    const ordersInCluster = this.state.reportData.filter((d) => items.includes(d.id))
    this.setState({
      reportDataSpecified: ordersInCluster,
      viewMode: listingLayouts.TABLE,
      heatMapCenter: center,
      heatMapZoom: zoom
    })
  }
}

ReportPage.propTypes = {
  dispatch: PropTypes.func,
  getEnumValues: PropTypes.func,
  enumValues: PropTypes.object,
  formValues: PropTypes.object,
  match: PropTypes.object,
  guiUser: PropTypes.object,
  useRequestedOrderGroupId: PropTypes.bool,
  location: PropTypes.object,
  type: PropTypes.string
}

ReportPage.defaultProps = { useRequestedOrderGroupId: false }

const ReportPageConnected = connect(
  (state) => ({
    enumValues: state.enumValues,
    guiUser: state.user.data,
    reduxState: state,
    formValues: getFormValues('reportSearchFieldsForm')(state),
    attributesList: state.ui.attributesList
  }),
  (dispatch) => ({
    dispatch,
    getEnumValues,
    submit
  })
)(ReportPage)

export default ReportPageConnected
