import React, {useEffect, useState} from 'react'
import {TreeSelect as AntdTreeSelect} from "antd";
import * as S from "../Calendar/CalendarsDashboard/styles";
import {Icon} from "@ant-design/compatible";

const { TreeNode, SHOW_ALL, SHOW_CHILD, SHOW_PARENT } = AntdTreeSelect

const TreeSelect = (props) => {
  const { value } = props

  const [treeData, setTreeData] = useState(props.treeData)

  const countSelectedGrandchildren = (children) => {
    let count = 0;
    children.forEach(child => {

      if (child.children){
        if (value?.includes?.(child.key) || value === child.key) {
          count+=child.children.length
        }
        else {
          child.children.forEach(grandchild => {
            if (value?.includes?.(grandchild.key) || value === grandchild.key) {
              count++;
            }
          });
        }
      }
    });
    return count;
  };

  useEffect(() => {
    const updateTreeDataTitles = () => {
      const newTreeData = treeData.map((parentNode) => {
        const selectedCount = countSelectedGrandchildren(parentNode.children || []);

        return {
          ...parentNode,
          title: (
            <S.TreeNode>
              <Icon type="cluster"/> {parentNode.name} - {parentNode.shortName} ({selectedCount}/{parentNode.totUsers})
            </S.TreeNode>
          )
        };
      });

      setTreeData(newTreeData);
    };

    updateTreeDataTitles();
  }, [value]);

  return (
    <AntdTreeSelect
      { ...props }
      treeData={treeData}
    />
  )
}

TreeSelect.SHOW_PARENT = SHOW_PARENT
TreeSelect.SHOW_CHILD = SHOW_CHILD
TreeSelect.SHOW_ALL = SHOW_ALL
TreeSelect.TreeNode = TreeNode
export default TreeSelect
