import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import _ from "lodash";
import { __ } from '../utils/translationUtils'

const DragHandle = SortableHandle(() => <span className="drag-handle">::</span>);

const SortableItem = SortableElement( props => {

  const { attr, attributesConfiguration, activeIds } = props
  const  itemFound  = _.find(attributesConfiguration, config => config.id ===  attr.attributeId);
  if (!itemFound){return null}

  const propertyLabel = attr.propertyLabel || itemFound.propertyLabel
  const onChangeCheckBox = () => {
    props.handleCheckBox(attr)
  }

  const onChangeSelectSorting = (e) => {
    props.handleSelectSorting(attr, e.target.value)
  }

  return(
    <div className="sorting-list-item">
    <div className="header">
      <DragHandle />

      <input
        type="checkbox"
        checked={activeIds.includes(attr.attributeId)}
        onChange={onChangeCheckBox}
      />
      <strong>{__(propertyLabel)}</strong>
      {' '}
      <select
        value={attr.value}
        onChange={onChangeSelectSorting}
      >
        <option value="ASC">{__('ASC')}</option>
        <option value="DESC">{__('DESC')}</option>
      </select>

      {' '}<span className="sorting-list-item_position">{__('Position')}: {attr.position}</span>
    </div>
    </div>
  )

});

const SortableList = SortableContainer(props => {

  const { items, attributesConfiguration, activeIds, handleCheckBox, handleSelectSorting } = props

  const sorted = _.sortBy(items, 'position')
  return(
    <div className="sorting-list clearfix">
      {sorted.map((attr, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          attr={attr}
          attributesConfiguration={attributesConfiguration}
          activeIds={activeIds}
          handleCheckBox={handleCheckBox}
          handleSelectSorting={handleSelectSorting}
        />
        ))}
    </div>
  )
})

class SortableSection extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeIds: props.activeIds,
      items: props.items?.sort((a, b) => a.position - b.position).map((item, index) => ({ ...item, position: index })),
      openPanel: false
    }

    this.handleApplySorting = this.handleApplySorting.bind(this)
    this.handleOpenPanel = this.handleOpenPanel.bind(this)
    this.handleCheckBox = this.handleCheckBox.bind(this)
    this.onSortAttributesSortEnd = this.onSortAttributesSortEnd.bind(this)
    this.handleSelectSorting = this.handleSelectSorting.bind(this)
  }

  componentDidMount() {
    if (this.props.componentRef) this.props.componentRef.current = this
  }

  handleOpenPanel(){
    this.setState({openPanel: !this.state.openPanel})
  }

  handleCheckBox(attr){
    const {activeIds} = this.state
    const activeIdsCopy = activeIds.slice()
    const activeIdx = _.findIndex(activeIdsCopy, (iD) => iD === attr.attributeId)

    if (activeIdx > -1){
      activeIdsCopy.splice(activeIdx, 1)
    } else {
      activeIdsCopy.push(attr.attributeId);
    }

    this.setState({activeIds: activeIdsCopy})
  }

  onSortAttributesSortEnd({oldIndex, newIndex}){
    const {items} = this.state
    const itemsCopy = items.slice()
    const itemOld = _.find(itemsCopy, (attr) => attr.position === oldIndex);

    if (newIndex > oldIndex) {
      // Update the position of all the previus items
      const prevItems = _.filter(itemsCopy, (attr) => attr.position >= oldIndex && attr.position <= newIndex)
      prevItems.forEach((item) => {
        item.position = item.position - 1
      });

    } else{
      // Update the position of all the previus items
      const prevItems = _.filter(itemsCopy, (attr) => attr.position >= newIndex && attr.position <= oldIndex)
      prevItems.forEach((item) => {
        item.position = item.position + 1
      });

    }
    itemOld.position = newIndex;
    this.setState({items: itemsCopy})
  }

  handleSelectSorting(attr, value){
    const {items} = this.state
    const itemsCopy = items.slice()

    const item = _.find(itemsCopy, (i) => i.attributeId === attr.attributeId);
    item.value = value

    this.setState({items: itemsCopy})

  }

  handleApplySorting(){
    const { items, activeIds } = this.state
    const { applySorting } = this.props
    
    applySorting(items.map((item) => ({
      ...item,
      enabled: activeIds.includes(item.attributeId)
    })))
  }

  render() {
    const { items, activeIds } = this.state
    const { attributesConfiguration, componentRef } = this.props
    return(
      <>
        <SortableList
          items={items}
          attributesConfiguration={attributesConfiguration}
          activeIds={activeIds}
          handleCheckBox={this.handleCheckBox}
          handleSelectSorting={this.handleSelectSorting}
          lockAxis="y"
          lockToContainerEdges
          useDragHandle={true}
          onSortEnd={this.onSortAttributesSortEnd}
        />
        { !componentRef &&
          <button  className="btn btn-primary pull-right gutter-top sticky btn-submit" onClick={this.handleApplySorting}>{__("Apply Sorting")}</button>
        }
      </>
    )

  }
}

SortableSection.propTypes = {
  activeIds: PropTypes.array,
  items: PropTypes.array,
  applySorting: PropTypes.func,
  attributesConfiguration: PropTypes.array
}

export default SortableSection
