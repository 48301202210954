/* eslint-disable max-len */
import React, {useCallback, useMemo, useState} from 'react'
import {Popover, Space, Tag} from 'antd'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'

import {useSelector} from "react-redux";
import UserAvatar from '../../UI/UserAvatar'
import CalendarMacbookStyleHandler from '../CalendarMacbookStyleHandler'
import CalendarEvent from '../CalendarEvent'
import * as S from './styles'
import theme from '../../../providers/StyleProvider/theme'
import getGroupedEvents from './utils/getGroupedEvents'
import getSlotProps from './utils/getSlotProps'
import getDayProp from './utils/getDayProp'
import {__} from "../../../utils/translationUtils";

const localizer = momentLocalizer(moment)

const CalendarStandardVersion = React.memo(({ userEntities, viewMode, user, events, stepEvents, onShowMore, onNavigate, date, toolbar, attributes, view, onRangeChange, onView, selectable, editable, onSelectSlot, onDelete, onEdit, handleExpandCalendar, entities }) => {

  const [selectedOrganisations, selectOrganisation] = useState(
    user?.belongsTo?.map((organisation) => ({id: organisation.id, entities: entities ? entities.filter(e => e.belongsTo.id === organisation.id ) : []}) || []
    ))
  const { workingEvents, nonWorkingEvents, godooEvents } = useMemo(() => getGroupedEvents({ events, selectedOrganisations, userEntities }), [events, selectedOrganisations, userEntities])
  const languageCode = useSelector((state) => state?.ui?.language)

  const slotPropGetter = useCallback((timeSlot) => {
    const { backgroundColor } = getSlotProps({ viewMode, events, timeSlot, workingEvents })

    return {
      style: {
        borderTop: 'none',
        backgroundSize: '5.66px 5.66px',
        backgroundColor
      }
    }
  }, [events, viewMode, workingEvents])

  const dayPropGetter = useCallback((timeSlot) => {
    const backgroundColor = getDayProp({ view, workingEvents, timeSlot })

    return {
      style: {
        borderTop: 'none',
        backgroundColor
      }
    }
  }, [view, workingEvents])

  function eventPropGetter() {
    return {
      style: {
        border: 'none',
        height: '100%',
        margin: '0',
        padding: '0'
      }
    }
  }

  function handleSelectSlot(e) {
    onSelectSlot({
      startDate: moment(e.slots[0]),
      endDate: moment(e.slots[e.slots.length - 1]),
      user
    })
  }

  function handleToggleSelectedEntities(id) {
    const selectedEntitiesIndex = selectedOrganisations?.map(o => o.id).indexOf(id)

    if (selectedEntitiesIndex > -1) {
      const result = [...selectedOrganisations.slice(0, selectedEntitiesIndex), ...selectedOrganisations.slice(selectedEntitiesIndex + 1)]
      selectOrganisation(result)
      return
    }
    selectOrganisation([...selectedOrganisations, {
      id: id,
      entities: entities ? entities.filter(e => e.belongsTo.id === id ) : []
    }])
  }

  return (
    <S.CalendarStandardVersion>
      <Space size="middle" direction="vertical" style={{ width: '100%' }}>
        {user && (
          <S.Header>
            <S.UserWrapper>
              <UserAvatar userId={user.id} alt={user.email} email={user.email} title={user.username} avatarName={`${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`}
                          avatarType={user.avatar?.avatarType}/>
              {
                user.status === 'deleted' ? <span style={{ paddingLeft: '8px' }}>{__('deleted_user')}</span> : <S.User>{user.firstName} {user.lastName}</S.User>
              }
            </S.UserWrapper>
            {handleExpandCalendar && <CalendarMacbookStyleHandler onExpand={() => handleExpandCalendar({ item: user, events })} />}
          </S.Header>
        )}
        {userEntities && (
          <Space>
            {userEntities.filter(e => e.type !== "user_group").map((entity) => {
              const color = selectedOrganisations?.find((o) => o.id === entity.id) ? theme.colors.thirdary : theme.colors.grey.primaray
              return (
                <Popover key={entity.name} content={entity.name} title={false}>
                  <Tag style={{ cursor: 'pointer', borderRadius: '4px', margin: 0 }} color={color} size="small" onClick={() => handleToggleSelectedEntities(entity.id)}>
                    {entity.shortName}
                  </Tag>
                </Popover>
              )
            })}
          </Space>
        )}
        <Calendar
          timeslots={4}
          step={15}
          min={moment().set({ hour: 7, minute: 0, second: 0 }).toDate()}
          max={moment().set({ hour: 20, minute: 0, second: 0 }).toDate()}
          localizer={localizer}
          events={[...nonWorkingEvents, ...godooEvents, ...(stepEvents || [])]}
          views={['month', 'week', 'day']}
          // this block of props are required because this component can be controlled or uncontrolled
          // A. controlled (es:CalendarPage)
          {...(date ? { date: new Date(date) } : {})}
          {...(view ? { view } : {})}
          {...(onShowMore ? { onShowMore } : {})}
          {...(onNavigate ? { onNavigate } : {})}
          // B. uncontrolled (es:UserForm)
          toolbar={toolbar}
          defaultDate={new Date()}
          defaultView="week"
          {...(onRangeChange ? { onRangeChange } : {})}
          {...(onView ? { onView } : {})}
          culture={languageCode ? languageCode.replaceAll('_', '-') : 'en-GB'}
          formats={{ dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd DD', culture) }}
          slotPropGetter={slotPropGetter}
          dayPropGetter={dayPropGetter}
          eventPropGetter={eventPropGetter}
          selectable={selectable}
          onSelectSlot={handleSelectSlot}
          components={{ event: ({ event }) => <CalendarEvent {...event} attributes={attributes} onDelete={onDelete} onEdit={onEdit} editable={editable} /> }}
          style={{ height: 'auto', maxHeight: 'auto' }}
        />
      </Space>
    </S.CalendarStandardVersion>
  )
})

export default CalendarStandardVersion
