import React from "react";
import { Badge, Tooltip as AntdTooltip } from "antd";
import { Button } from "components";

export default (props) => {
  const {
    children,
    trigger = "click",
    buttonLabel,
    buttonProps,
    buttonBadgeCount,
    useButtonStyle = true,
    overlayInnerStyle
  } = props

  const customStyle = {
    padding: 0,
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    ...overlayInnerStyle,
  }

  return (
    <AntdTooltip
      content={children}
      title={children}
      trigger={trigger}
      placement={'bottom'}
      color={'white'}
      overlayInnerStyle={customStyle}
    >
      <Badge count={buttonBadgeCount} offset={[-10, 0]} size="small">
        {useButtonStyle ? (
          <Button
            type={'default'}
            backgroundColor={'#FFFFFF'}
            borderColor={'#72777F'}
            color={'#00629D'}
            title={buttonLabel}
            {...buttonProps}
          />
        ) : (
          <div>
            {buttonLabel}
          </div>
        )}
      </Badge>
    </AntdTooltip>
  )
}
