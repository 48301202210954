import React from "react";
import { Button } from "components";

import { FlexInlineGroup } from "components/dist/styles";
import { __ } from '../utils/translationUtils'

export const SmartFooter = (props) => {
  const { onReset, resetLabel, onSubmit, submitLabel } = props

  return (
    <FlexInlineGroup
      fitParent
      leftChildren={onReset &&
        <Button
          type='text'
          onClick={(e) => onReset?.(e)}
          title={resetLabel || __('Reset')}
        />
      }
      rightChildren={onSubmit &&
        <Button
          type='filled'
          onClick={(e) => onSubmit?.(e)}
          title={submitLabel || __('Apply')}
        />}
    />
  )
}
