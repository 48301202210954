import React from 'react'

import { useResizeDetector } from 'react-resize-detector'
import { CellContent } from "./styles";

export default (props) => {

  const {
    cellProperties,
    children,
    updateHeight,
    itemsRef,
    ...cellProps
  } = props

  const { ref: resizeRef } = useResizeDetector({
    onResize: (...props) => updateHeight?.(cellProperties.i, ...props),
  })

  return (
    <CellContent {...cellProps} >
      <div
        ref={(ref) => {
          itemsRef.current[cellProperties.i] = ref
          resizeRef.current = ref
        }}
      >
        {children}
      </div>
    </CellContent>
  )
}
