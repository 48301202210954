import {useQuery} from "react-query";
import config from "../config";
import {fetchEntities} from "../../ApiProvider";

type UseEntitiesQueryProps = {
  type: EntityTypes
}

export const UseEntitiesQuery = ({ type }: UseEntitiesQueryProps) => useQuery<Entity[]>({
  queryKey: ["fetchEntities", type],
  queryFn: async () => fetchEntities(type),
  ...config
})

