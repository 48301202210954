import React from 'react';
import { connect } from 'react-redux';
import { forgotPasswordRequest } from '../../providers/ReduxProvider/actions/userActions';
import { Authentication as Auth } from 'components'
import { Gutter } from "components/dist/styles";
import { TextCenter } from "../../components/UI/styles/text";
import { __, T } from '../../utils/translationUtils'
import getErrorText from "../../utils/getErrorText";
import {hashHistory} from "../../providers/HistoryProvider";

const textCommons = { T, __, getErrorText }

const ForgotPasswordPage = (props) => {
  const { forgotPasswordRequest, location } = props

  const handleSubmit = (values) => {
    let payload = { values }
    forgotPasswordRequest(payload).then((a) => hashHistory.push(`/login${location.search}`))
  }

  return (
    <>
      <TextCenter>
        <Gutter bottom>
          <h4>
            {`${__(T.forgotPassword)}?`}
          </h4>
        </Gutter>
      </TextCenter>
      <Auth.ForgotPasswordForm handleSubmit={handleSubmit} textCommons={textCommons} queryString={location.search}/>
    </>

  )
}

export default connect(
  (state) => {
    return {
      isLoading: state.user.isLoading
    };
  },
  { forgotPasswordRequest }
)(ForgotPasswordPage);
