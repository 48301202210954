import React from 'react'
import PropTypes from 'prop-types'
import Glyphicon from '@strongdm/glyphicon'
import DateTime from 'react-datetime'
import _ from 'lodash'
import moment from 'moment'

import { Icon } from '@ant-design/compatible'
import { appDefaults } from '../constants/globalConfiguration'
import SelectField from '../components/SelectField'
import BillingController from '../components/controls/BillingController'
import ObjectListingControl from '../components/controls/ObjectListingControl'
import GroupedTreeSelect from '../components/formFields/GroupedTreeSelect'
import ColorPicker from '../components/formFields/ColorPicker'
import FileUploader from '../components/fileUploader'
import { api } from "../providers/ApiProvider"
import Label from "../components/Label";
import { ERROR } from "components/dist/Utils/LoggerUtils";
import { REGEX_EMAIL } from "../constants/regexes";
import { __ } from './translationUtils'
import {Tag} from "antd";

export const getFieldValidation = (type, required) => {
  return [
    ...(required ? [stepFieldRequired] : []),
    ...(['datetime', 'DATE', 'DateTimeInput', 'date', 'DateInput'].includes(type) ? [dateTimeFieldValidator] : [])
  ]
}

export function isRequired(value) {
  if (typeof value === 'undefined' || value === null || value.length === 0) return __('This is a mandatory field')
}

export function filesValidator(value) {
  if (typeof value === 'undefined' || value === null || parseInt(value) === 0) return __('This is a mandatory field')
}

export const stepFieldRequired = (value) => ((value && value !== '') ? undefined : __('This is a mandatory field'))

export const dateTimeFieldValidator = (value) => ((!value || moment(value).isValid()) ? undefined : __('Invalid date'))

export const stepListBoxFieldRequired = (value) => ((value && value !== '' && value !== __('Select one'))
  ? undefined
  : __('This is a mandatory field'))

export function isEmail(value) {
  if (!value) {
    return
  }

  if (!REGEX_EMAIL.test(value)) return 'Not valid email format'
}

export const asyncValidateUsername = (value) => {
  return api.get(`/users/usernameAvailable?username=${value?.username}`)
    .then((response) => {
      if ((response?.data?.valid)) {
        return undefined;
      } else {
        throw { username: __(response?.data?.description) };
      }
    })
};

export function renderField(props) {
  const {
    placeholder,
    disabled,
    input,
    id,
    description,
    items,
    label,
    required,
    className,
    labelCol,
    inputCol,
    type,
    multiple,
    dateFormat,
    withFilter,
    attribute,
    minValue,
    maxValue,
    formName,
    color,
    variables,
    attributesConfiguration,
    enumId,
    overviewTabEditField,
    objectConfiguration,
    containerClassName,
    options,
    meta: { touched, error },
  } = props

  const req = (required && !disabled) ? '*' : ''
  const labelClass = labelCol || 'col-sm-3 col-md-3 col-lg-2'
  const inputClass = inputCol || 'col-sm-9 col-md-6 col-lg-6'
  return (
    <div
      className={'form-group '.concat(touched && error ? 'has-error ' : '').concat(containerClassName || 'clearfix')}>
      {!!label
        &&
        typeof label === 'string'
        ?
        <Label
          htmlFor="inputName"
          className={`${labelClass} control-label`}
          label={label + req}
          description={description && __(description)}
        />
        :
        <div className={`${labelClass} control-label`}>
          {label}
        </div>
      }
      <div className={`${inputClass} text-danger-wrapper`}>
        {['STRING', 'INTEGER', 'DOUBLE', 'LONG', 'FLOAT', 'TextEntry', 'text', 'number', 'hidden', 'password', 'new-password']
          .includes(type) && renderTextField(disabled, input, type, label, className, placeholder, labelCol, inputCol)}
        {['email', 'phone', 'url']
          .includes(type?.toLowerCase()) && renderUrlField(disabled, input, type.toLowerCase(), label, className, placeholder, labelCol, inputCol)}
        {['TranslatedLabel'].includes(type) && renderTranslatedTextField(disabled, input, type, label, className, placeholder, labelCol, inputCol)}
        {['ENTITY', 'IDS', 'SimpleLabel', 'label'].includes(type) && renderTextField(true, input, type, label, className, placeholder, labelCol, inputCol)}
        {['priceEntry'].includes(type) && renderPriceField(disabled, input, type, label, className)}
        {['percentageEntry'].includes(type) && renderPercentageField(disabled, input, type, label, className)}
        {['discountTypeEntry'].includes(type) && renderDiscountTypeField(disabled, input, type, label, items, className)}
        {['OLD_BILLING', 'Billing', 'BillingController'].includes(type) && renderBillingField(disabled, input, type, label, className)}
        {['BINARY', 'FileUpload', 'CustomPhotoCollection', 'PhotoCollection', 'SimpleSignature'].includes(type) && renderBinaryField(disabled, input, attribute, formName, overviewTabEditField)}
        {['textarea', 'MultiLineTextEntry'].includes(type) && renderTextAreaField(disabled, input, label, variables, className, placeholder)}
        {['select', 'SimpleCheckBox', 'PopupListBox'].includes(type) && renderSelect(disabled, input, type, label, className, multiple, withFilter, items, inputCol, labelCol)}
        {['RadioButton', 'YESNO', 'BOOLEAN'].includes(type) && renderRadioButton({ input, disabled, inputCol, items, type })}
        {['checkbox'].includes(type) && renderCheckBox({ disabled, input, inputCol, attribute })}
        {['OBJECT_LIST_IDS', 'ObjectListing', 'ObjectPicker'].includes(type) && renderObjectList(disabled, input, attributesConfiguration, overviewTabEditField, objectConfiguration, options)}
        {['datetime', 'DATE', 'DateTimeInput'].includes(type) && renderDatetime(disabled, input, label, className, dateFormat, false, placeholder, minValue, maxValue, id)}
        {['date', 'DateInput'].includes(type) && renderDatetime(disabled, input, label, className, dateFormat, true, placeholder, minValue, maxValue, id)}
        {['dropdownType'].includes(type) && renderDropDownType(disabled, input, enumId, label, className, attributesConfiguration)}
        {['colorPicker'].includes(type) && renderColorPicker(disabled, input, type, label, className, placeholder, items)}
        {['status'].includes(type) && renderStatus(input, color, multiple)}
        {touched && error && <span style={{ "margin": 1 }} className="text-danger">{error}</span>}
      </div>
    </div>
  )
}

renderField.propTypes = {
  disabled: PropTypes.bool,
  withFilter: PropTypes.bool,
  input: PropTypes.object,
  attribute: PropTypes.object,
  meta: PropTypes.object,
  items: PropTypes.array,
  label: PropTypes.string,
  labelCol: PropTypes.string,
  value: PropTypes.string,
  inputCol: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  formName: PropTypes.string,
  updateObject: PropTypes.func
}
renderField.defaultProps = {
  placeholder: '',
  required: false,
  withFilter: false,
  labelCol: null,
  inputCol: null
}

function renderSelect(disabled, input, type, label, className, multiple, withFilter, items, inputCol, labelCol) {
  if (!multiple && disabled) {
    const value = _.isObject(input?.value) ? input.value.value : input?.value
    const label = items?.find?.((item) => item?.props?.value === value)?.props?.children
    return (
      <div className="form-control-static">
        {label || value || '-'}
      </div>
    )
  }
  return (
    <SelectField
      input={input}
      className={`form-control ${className}`}
      disabled={disabled}
      multiple={multiple}
      withFilter={withFilter}
      items={items}
    />
  )
}

function renderTextField(disabled, input, type, label, className, placeholder) {
  const data = input
  if (data.value instanceof Object) {
    data.value = data.value.value
  }

  if (disabled) {
    return <div className="form-control-static">{data.value != null ? data.value : "-"}</div>
  }
  return (
    <input
      {...data}
      disabled={disabled}
      type={type}
      placeholder={placeholder}
      className={`form-control ${className}`}
      autoComplete="off"
      onClick={(event) => {
        if (event?.target?.value === '0') { event?.target?.select?.() }
      }}
    />
  )
}

function renderUrlField(disabled, input, type, label, className, placeholder) {
  let initial = ''
  let icon = ''
  switch (type) {
    case 'email':
      initial = 'mailto:'
      icon = 'mail'
      break
    case 'url':
      icon = 'global'
      break
    case 'phone':
      initial = 'tel:'
      icon = 'phone'
      break
  }

  if (disabled) {
    if (input.value) return <div className="form-control-static"><a href={initial + input.value}><Icon type={icon}/> {input.value}</a></div>
    return <div className="form-control-static">--</div>
  }
  return (
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
      autoComplete="off"
    />
  )
}

function renderColorPicker(disabled, input, type, label, className, placeholder, items) {
  if (disabled) return <div className="form-control-static">{input.value}</div>

  return (
    <div className="form__color_container">
      <ColorPicker input={input} onColorChange={items.onColorChange} />
    </div>
  )
}

function renderStatus(input, color, multiple) {
  if (multiple){
      return (
        <div className="form-control-static">
          {
            input.value?.map?.((status) => (
            <Tag color={color}>{status?.toUpperCase?.()}</Tag>
          ))
          }
        </div>
      )
  }

  return (
    <div className="form-control-static">
      <Tag color={color}>{input.value?.toUpperCase?.()}</Tag>
    </div>
  )
}

function renderTranslatedTextField(disabled, input, type, label, className, placeholder) {
  let value = __(input.value)
  if (input.value instanceof Object) {
    value = __(input.value.value)
  }
  if (disabled) return value
  return (
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
      autoComplete="off"
      value={value}
    />
  )
}

function renderCheckBox({ disabled, input, inputCol, attribute }) {
  const data = input

  if (attribute) {
    if (attribute.type === 'YESNO') {
      data.checked = (data.value.toLowerCase() === 'yes')
    }
    if (attribute.type === 'BOOLEAN') {
      data.checked = (data.value)
    }
  }
  const inputClass = inputCol || 'col-sm-7'
  return (
    <div className={inputClass}>
      <input defaultChecked={data.checked} type="checkbox" disabled={disabled} {...data} />
    </div>
  )
}

renderCheckBox.propTypes = {
  attribute: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  labelCol: PropTypes.string,
  inputCol: PropTypes.string,
  placeholder: PropTypes.string
}
renderCheckBox.defaultProps = {
  placeholder: '',
  required: false,
  labelCol: null,
  inputCol: null
}

function renderRadioButton({ input, disabled, items, type }) {
  const data = input
  let dataItems = items

  if (disabled) {
    if (data.value == undefined || data.value === '')
      return (<div className="empty-radio-button-disabled"></div>)
    return (
      <div className="form-control-static">
        {
          [true, 'yes', 'YES', 'true', 'True'].includes(data.value) ?
            __('val_yes')
            : __('val_no')
        }
      </div>
    )
  }
  const buttons = []
  if (type === 'YESNO' || type === 'BOOLEAN' || (type === 'RadioButton' && !items.length)) {
    if ([true, 'yes', 'YES', 'true', 'True'].includes(data.value)) {
      data.value = 'true'
    }
    if ( [false, 'false', 'False', 'no', 'No'].includes(data.value)) {
      data.value = 'false'
    }
    if (typeof items === 'undefined' || !items.length) {
      dataItems = []
      dataItems.push('true')
      dataItems.push('false')
    }
  }
  dataItems.forEach((item, index) => {
    let trans = item.translationKey || item.value || item
    if (type === 'YESNO') {
      if (trans.toLowerCase() === 'true' || trans.toLowerCase() === 'yes') trans = 'val_yes'
      if (trans.toLowerCase() === 'false' || trans.toLowerCase() === 'no') trans = 'val_no'
    }

    const value = item.value || item

    buttons.push(<div key={`${data.name}-${trans}-${index}`}><label><input
      {...data}
      name={data.name}
      checked={value === data.value}
      type="radio"
      disabled={disabled}
      value={value}
    />&nbsp;{__(trans)}
    </label>
    </div>)
  })
  return (<div className="radioButtonWrapper">{buttons}</div>)
}

renderRadioButton.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.object,
  inputCol: PropTypes.string
}

function renderObjectList(disabled, input, attributesConfiguration, overviewTabEditField, objectConfiguration, options) {
  if (!attributesConfiguration) {
    return <div>{__('Object Listing is not getting attribute list')}</div>
  }
  let attribute = attributesConfiguration[input.name]
  if (!attribute) {
    attribute = _.find(attributesConfiguration, (a) => a.id === input.name)
  }

  if (!attribute) {
    ERROR("Object Listing is using an unknown attribute", input.name)
    return <div>{__('Object Listing is using an unknown attribute')}</div>
  }
  const controlData = {}
  controlData.objectConfiguration = objectConfiguration
  controlData.controllerOptions = options.controllerOptions
  controlData.source = input.value
  controlData.input = input
  controlData.readOnly = disabled
  controlData.controllerType = 'ObjectListing'
  controlData.attributes = {}
  controlData.id = attribute.id
  if (!attribute.relatedObjects) {
    return <div>{__('Object_Listing_Error_Missing_Related_Object_In_Attribute')}</div>
  }
  return (
    <ObjectListingControl
      readOnly={disabled}
      controlData={controlData}
      objectTypeId={attribute.relatedObjects}
      additionalStyle={options?.additionalStyle || 'overviewTab'}
      views={options?.views}
      overviewTabEditField={overviewTabEditField}
      type={options && options.type !== 'ROE' ? 'ObjectListing' : 'ROE'}
    />
  )
}

function renderBinaryField(disabled, input, attribute, formName, overviewTabEditField) {
  const controlData = input
  controlData.id = controlData.id || input.name
  return (
    <FileUploader
      reduced={!overviewTabEditField && disabled}
      readOnly={disabled}
      formName={formName}
      controlData={controlData}
      overviewTabEditField={overviewTabEditField}
      attribute={attribute}
    />
  )
}

function renderBillingField(disabled, input, type, label) {
  const controlData = {}
  controlData.source = input.value
  controlData.readOnly = disabled
  controlData.title = label
  return (<BillingController form={label} key={label} controlData={controlData} />)
}

function renderTextAreaField(disabled, input, label, variables, className, placeholder) {
  const data = input

  if (data.value instanceof Object) {
    data.value = data.value.value
  }

  const searchPattern = new RegExp(`^1[0-9]{2}_`);
  if (searchPattern.test(data.value)) { }

  let translated = (variables && searchPattern.test(data.value)) ? __(data.value) : data.value

  variables && Object.entries(variables).forEach(([text, { value }]) => {
    const strTag = `${__(value)}`

    translated = translated?.replaceAll(`\${${text}}`, strTag)
  })

  if (disabled) {
    return (<div style={{ whiteSpace: "pre-line" }}>
      {translated}
    </div>)
  }

  return (
    <div style={{ whiteSpace: "pre-line" }}>
      <textarea
        {...data}
        value={translated}
        className={`${className} form-control multiline`}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  )
}

function renderPriceField(disabled, input, type, label, className) {
  return (
    <div className="input-group">
      <span className="input-group-addon">CHF</span>
      <input
        {...input}
        type={type}
        className={className}
        onClick={(event) => {
          if (parseInt(event?.target?.value) === 0) { event?.target?.select?.() }
        }}
        onBlur={(e) => {
          input.onChange(parseFloat(e.target.value).toFixed(2))
          input.onBlur()
        }}
        disabled={disabled}
        autoComplete="off"
        style={{ minWidth: '80px' }}
      />
    </div>
  )
}
function renderPercentageField(disabled, input, type, label, className) {
  return (
    <div className="input-group">
      <input
        {...input}
        type={type}
        className={className}
        onBlur={(e) => {
          input.onChange(parseFloat(e.target.value).toFixed(2))
          input.onBlur()
        }}
        disabled={disabled}
        autoComplete="off"
        style={{ minWidth: '40px' }}
        onClick={(event) => {
          if (event?.target?.value === '0') { event?.target?.select?.() }
        }}
      />
      <span className="input-group-addon">%</span>
    </div>
  )
}
function renderDiscountTypeField(disabled, input, type, label, items, className) {
  return (
    <SelectField
      input={input}
      disabled={disabled}
      className={className}
      multiple={false}
      items={items}
    />
  )
}

function renderDatetime(disabled, input, label, className, dateFormat, dateOnly = false, placeholder, minValue, maxValue, id) {
  // Parse the input to a timestamp.
  let value = input.value ? new Date(input.value) : input.value
  //let value = input.value
  value = moment(value).isValid() ? moment(value) : value

  if (disabled) {
    if (value && moment(value).isValid()) {
      return <div
        className={'form-control-static'}>{(dateOnly) ? value.format(appDefaults.dateFormat) : value.format(appDefaults.dateTimeFormat)}</div>
    } else if (value && !moment(value).isValid()) {
      return value
    }
    return '--'
  }

  if (minValue && !(minValue instanceof Date) && !(minValue instanceof moment)) {
    if (['NOW', 'now', 'Now'].includes(minValue)) minValue = moment()
  }

  if (maxValue && !(maxValue instanceof Date) && !(maxValue instanceof moment)) {
    if (['NOW', 'now', 'Now'].includes(maxValue)) maxValue = moment()
  }

  return (
    <div className="datetime-picker-wrapper">
      <DateTime
        isValidDate={(date) => {
          if (minValue && date < minValue) {
            // console.log("error min: " + date.toString() + " is less than " + minValue.toString());
            return false
          }
          if (maxValue && date < maxValue) {
            // console.log("error max: " + date.toString() + " is greater than " + maxValue.toString());
            return false
          }
          return true
        }}
        onChange={(newValue) => {
          input.onChange(newValue)
        }}
        onOpen={() => {
          const container = document.querySelector(`#cell-${id}`)
          if (container) container.style.zIndex = "1"
        }}
        onClose={() => {
          const container = document.querySelector(`#cell-${id}`)
          if (container) container.style.zIndex = ""
        }}
        strictParsing={true}
        dateFormat={appDefaults.dateFormat}
        timeFormat={(dateOnly) ? false : appDefaults.timeFormat}
        value={value}
        className={className}
        disabled={disabled}
        closeOnSelect
        inputProps={{ placeholder }}
        locale={localStorage.getItem('lang') ? localStorage.getItem('lang').replaceAll('_', '-') : 'en-GB'}
        min={minValue || ''}
        max={maxValue || ''}
        enableReinitialize
        renderInput={(props) => {
          return <input {...props} value={value ? props.value : ''} />
        }}
      />
      <Glyphicon glyph="calendar" />
    </div>
  )
}

function renderDropDownType(disabled, input, enumId, label, className, attributesConfiguration) {
  if (!attributesConfiguration) {
    return null
  }
  let attributes
  attributes = [...attributesConfiguration]
  const attribute = _.find(attributes, (a) => a.id === input.name)
  const controlData = {}
  controlData.source = input.value
  controlData.readOnly = disabled
  controlData.attributes = {}
  controlData.id = attribute.id
  return <GroupedTreeSelect
    disabled={disabled}
    input={input}
    enumId={enumId}
    label={label}
    className={className}
    controlData={controlData}
    {...input} />
}
