import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { Popover, Tooltip, Button } from 'antd'
import { Link } from 'react-router-dom'

import { Icon } from '@ant-design/compatible'
import Card from '../../Card'
import { capitalize, renderCAlgorithm } from '../../../utils/appHelper'
import * as S from './styles'
import { appDefaults } from '../../../constants/globalConfiguration'

import { api } from '../../../providers/ApiProvider'
import { __ } from '../../../utils/translationUtils'

const { WORKING_EVENT, NON_WORKING_EVENT, GODOO_EVENT } = global.constants.eventTypes

const ORDER_TYPE = '100_58be96d00e823f552aa1a009'

function getJsonParsedValue(value) {
  if (typeof value === 'string') return JSON.parse(`${value}`)
  return value
}

// ✅ TBD: strong refactor required

const EventPopover = ({ attributes, start, startHour, endHour, startMinute, endMinute, end, description, eventType, id, entity, orderData, nonWorkingTypeCode, inherited, nonWorkingPeriod }) => {
  const [responsibleUsers, setResponsibleUsers] = useState([])
  const [responsibleEntities, setResponsibleEntities] = useState([])

  const getUsersFromList = useCallback(async (value) => {
    const result = []
    // eslint-disable-next-line no-restricted-syntax
    for (const u of value) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const resp = await api.get(`users/${u.id}`)
        result.push(resp.data)
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex)
      }
    }
    setResponsibleUsers(result)
  }, [])
  const getCompaniesFromList = useCallback(async (value) => {
    const result = []
    // eslint-disable-next-line no-restricted-syntax
    for (const e of value) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const resp = await api.get(`entities/${e.id}`)
        result.push(resp.data)
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex)
      }
    }
    setResponsibleEntities(result)
  }, [])

  const { processOverview, orderOverview, additionalDisplayData } = orderData || {}

  useEffect(() => {
    if (additionalDisplayData) {
      getUsersFromList(getJsonParsedValue(additionalDisplayData['100_58be96d00e823f552aa1a070'].filter((user) => user.parentObjectId === processOverview.id)))
      getCompaniesFromList(getJsonParsedValue(additionalDisplayData['100_58be96d00e823f552aa1a071'].filter((e) => e.parentObjectId === processOverview.id)))
    }
  }, [getUsersFromList, getCompaniesFromList, additionalDisplayData, processOverview])

  return (
    <S.Popover>
      <S.Section>
        <Icon type="number" />
        <S.Content center>
          {eventType === GODOO_EVENT && `${additionalDisplayData?.[ORDER_TYPE]?.value || ''} ${__('order')}`}
          {eventType === NON_WORKING_EVENT && nonWorkingPeriod.nonWorkingType.reservedFor === 'user' ? __('personal event') : __('event')}
          {eventType === WORKING_EVENT && __('working event')}
        </S.Content>
      </S.Section>

      <S.Section>
        <Icon type="clock-circle" />
        <S.Content>
          <S.Row>
            <S.Label>{__('From')}</S.Label>
            <S.Value>
              {(eventType === GODOO_EVENT || eventType === NON_WORKING_EVENT) && moment(start).format(appDefaults.dateTimeFormat)}
              {(eventType === WORKING_EVENT) && `${startHour}:${startMinute}`}
            </S.Value>
          </S.Row>
          <S.Row>
            <S.Label>{__('To')}</S.Label>
            <S.Value>
              {(eventType === GODOO_EVENT || eventType === NON_WORKING_EVENT) && moment(end).format(appDefaults.dateTimeFormat)}
              {(eventType === WORKING_EVENT) && `${endHour}:${endMinute}`}
            </S.Value>
          </S.Row>
        </S.Content>
      </S.Section>

      <S.Section>
        <Icon type="snippets" />
        <S.Content>
          {(eventType === WORKING_EVENT) && (
            <S.Row>
              <S.Label>{__('Working for')}:</S.Label>
              <S.Value>{entity.name}</S.Value>
            </S.Row>
          )}
          {(eventType === NON_WORKING_EVENT) && (
            <>
              <S.Row>
                <S.Label>{__('Entity')}:</S.Label>
                <S.Value>{entity.name}</S.Value>
              </S.Row>
              <S.Row>
                <S.Label>{__('Event type')}:</S.Label>
                <S.Value>{global.constants.eventTypeLabels[nonWorkingTypeCode]}</S.Value>
              </S.Row>
              <S.Row>
                <S.Label>{__('Description')}:</S.Label>
                <S.Value>{description}</S.Value>
              </S.Row>
            </>
          )}
          {eventType !== GODOO_EVENT && (
            <S.Row>
              <S.Label>{__('Inherited')}:</S.Label>
              <S.Value>{inherited ? 'Yes' : 'No'}</S.Value>
            </S.Row>
          )}

          {eventType === GODOO_EVENT && orderData?.displayData && Object.entries(orderData.displayData).map(([key, resultsDisplayData]) => {
            const attribute = attributes[key]
            const title = capitalize(__(attribute.propertyLabel))
            return (
              <S.Row>
                <S.Label>
                  <Tooltip placement="topRight" title={title} style={{ marginTop: '-55px' }} mouseEnterDelay="0.4" mouseLeaveDelay="0.00001">
                    <Button type="text"> {title}</Button>
                  </Tooltip>
                </S.Label>
                <S.Value>{renderCAlgorithm(resultsDisplayData, orderData, attribute, id)}</S.Value>
              </S.Row>
            )
          })}
        </S.Content>
      </S.Section>
      {
        eventType === GODOO_EVENT && orderOverview && (
          <>
            <S.Section>
              <Icon type="idcard" />
              <Card
                id={orderOverview.id}
                users={responsibleUsers}
                companies={responsibleEntities}
                priorityLabel={orderOverview.priority}
                priorityIconProps={global.constants.priorityValues[orderOverview.priority]}
                deadlineColor={orderOverview?.deadline?.color}
                deadline={orderOverview?.deadline?.deadline && moment(orderOverview?.deadline?.deadline).format(appDefaults.dateTimeFormat)}
              />
            </S.Section>
            <S.Section>
              <Link
                to={`/orders/${orderOverview.id}/overview`}
                className="btn btn-default btn-sm btn-no-padding"
                style={{ marginLeft: 'auto' }}
              >
                <Icon type="eye" size="large" style={{ fontSize: '1.3rem' }} />
              </Link>
            </S.Section>
          </>
        )
      }
    </S.Popover>
  )
}

export default (props) => {
  const { title, eventType, onEdit, onDelete, inherited, editable, entity, nonWorkingTypeCode, backgroundColor, orderData } = props

  function handleEdit() {
    onEdit(props)
  }
  function handleDelete() {
    onDelete(props)
  }

  const content = <EventPopover {...props} />
  const Title = () => (
    <S.PopoverTitle justifyContent={!inherited && editable}>
      {backgroundColor && <S.Square backgroundColor={backgroundColor} />}{title}
      { eventType !== GODOO_EVENT && onEdit && onDelete && editable && !inherited && <S.IconWrapper><Icon className="edit" type="edit" onClick={handleEdit} /> <Icon type="delete" className="delete" onClick={handleDelete} />  </S.IconWrapper>}
      { eventType === WORKING_EVENT && entity?.name}
    </S.PopoverTitle>
  )

  if (eventType === NON_WORKING_EVENT) {
    return (
      <Popover content={content} title={Title} trigger="click">
        <S.EventWrapper {...props} isNonWorkingEvent title="">
          {global.constants.eventTypeEmoji[nonWorkingTypeCode]} {title}
        </S.EventWrapper>
      </Popover>
    )
  }

  if (eventType === WORKING_EVENT) {
    return (
      <S.EventWrapper {...props} isWorkingEvent title="" />
    )
  }

  if (eventType === GODOO_EVENT) {
    return (
      <Popover content={content} title={Title} trigger="click">
        <S.EventWrapper triangleColor={orderData?.orderOverview?.deadline?.color} {...props} isGodooEvent title="">
          {title}
        </S.EventWrapper>
      </Popover>
    )
  }
}
