import React, { useCallback, useState } from "react"
import { Flex } from "components/dist/styles"
import { Button } from "components"
import * as S from "../Progress/styles.js"
import { getProgressColor } from "../Progress/index"
import { downloadFile } from "../../DooOrderPage/Step/customRender/FileUploader/utils/IO.js"
import downloadjs from "downloadjs"
import { extensionToMimeType } from "../../RemoteObjectInstances/ExportModal/utils.js"

export default (props) => {
  const { fileInfo, downloadButtonProps } = props
  const { path, name } = fileInfo || {}
  const [showProgress, setShowProgress] = useState(false)

  const [progress, setProgress] = useState(0)

  const onClick = useCallback((extension) => {
    const mimeType = extensionToMimeType[extension]

    downloadFile(`${path}.${extension}`, null, (state) => setProgress(state.percent))
      .then((blob) => {
        if (!blob) return
        const file = new File([blob], `${name}.${extension}`, { type: mimeType })
        downloadjs(file, `${name}.${extension}`, mimeType)
        setShowProgress(false)
      })
  }, [path, name])

  const { items } = downloadButtonProps || {}

  return (
    <Flex justifyContent='center'>
      <div style={{ position: 'relative' }}>
        <Button
          shape='circle'
          icon='download'
          direction="down"
          {...downloadButtonProps}
          {...(items ? { items: items.map((item) => ({ ...item, onClick: () => onClick(item.option) })) } : {})}
        />
        {showProgress && (
          <S.CustomProgress
            type="circle"
            isButton={true}
            status="normal"
            width="100%"
            strokeWidth={10}
            percent={progress}
            showInfo={false}
            strokeColor={getProgressColor({ completed: progress >= 100 })} />
        )}
      </div>
    </Flex>
  )
}
