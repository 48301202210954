import styled from "styled-components";
import { Flex, FlexRowSpaceBetween } from "components/dist/styles";
import { Cascader, Input } from "antd";
import { SendOutlined } from "@ant-design/icons";
import React from "react";


export const UserInfoContainer = styled(Flex)`
  padding-bottom: 10px;
`

export const Info = styled.div`
  font-size: 12px;
  color: #999;
  margin-left: 10px;
  
  a {
    font-size: 16px;
    color: #5D5E61 !important;
  }
`

export const TextContent = styled(Flex)`
  margin-left: 45px;
`

export const UserInputWrapper = styled(FlexRowSpaceBetween)`
  font-size: large;
  padding: 10px 15px;
  
  .glyphicon {
    font-size: larger;
    transform: rotate(47deg) scaleY(-1);
  }
  
  a > div {
    width: 4rem;
    height: 4rem;
  }
`

export const UserInput = styled(Input.Group)`
  display: flex !important;
  padding: 10px;
  .ant-select .ant-select-selector {
    height: unset !important;
    border-radius: 8px 0 0 8px !important;
    padding: 6px 12px;
    background-color: #E2E2E6;
  }
  .ant-input {
    border-radius: 0 8px 8px 0 !important;
    background-color: #E2E2E6;
  }
`

export const IconClickable = styled.div`
  cursor: pointer;
  margin-left: 5px;
  padding: 5px;
  border-radius: 14px;

  :hover {
    background-color: ${(props) => props.disabled ? `unset` : '#d0d0d0'};
  }

  :active {
    background-color: ${(props) => props.disabled ? `unset` : '#b9b9b9'};
  }
`

export const VisibilitySelector = (props) => (
  <Cascader
    placement={'topRight'}
    allowClear={false}
    {...props}/>
)

export const SendIcon = styled(SendOutlined)`
  cursor: ${(props) => props.disabled ? `not-allowed !important` : 'pointer !important'};
  color: ${(props) => props.disabled ? `#d0d0d0` : 'unset'};
`
