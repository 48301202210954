import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { find, isEmpty } from 'lodash'
import { Space, Form, Tooltip as AntdTooltip } from "antd";

import {
  getInitialPageSize,
  fetchRemoteObjectEntries,
  fetchEnumById,
  checkUserRights,
  capitalize
} from '../../utils/appHelper'

import { useParams } from "react-router-dom";

import CreateInstance from "../../components/RemoteObjectInstances/CreateInstance";
import SearchParameterForm from "../../components/RemoteObjectInstances/Filter/SearchParameterForm";
import { SortForm } from '../../components/UI/SmartTable/Header';
import { RemoteObjectTable, ExportModal } from "../../components/RemoteObjectInstances";
import { ActionOptions, BatModal, CheckboxList, Progress, Tooltip } from "../../components/UI";
import { SmartFooter } from "../../components/SmartFooter";
import { ControlOutlined, DownloadOutlined, RedoOutlined, SortAscendingOutlined, TableOutlined } from "@ant-design/icons";

import useProgress from "../../hooks/useProgress";
import { getDisplayAttributes, getDisplayAttributesItems } from "../../utils/displayAttributeHelper";
import { getColumnSort } from "../../utils/tableUtils";
import { ERROR } from "components/dist/Utils/LoggerUtils";
import { getSearchAttributes, getSearchFields } from "./utils";
import { IconButton } from "../../providers/StyleProvider/styles";
import * as S from "../../providers/StyleProvider/styles";
import * as config from "../../constants/globalConfiguration";
import { api } from '../../providers/ApiProvider'
import { hashHistory } from "../../providers/HistoryProvider";
import { parse } from "query-string";
import { useSelector } from "react-redux";
import { availableExportOptions } from '../../components/RemoteObjectInstances/ExportModal/contants';
import { __ } from '../../utils/translationUtils'
import {UseAttributesQuery} from "../../providers/QueryClientProvider/queries";

const { rightMappings } = global.constants

export default (props) => {
  const { data: attributeList } = UseAttributesQuery()
  const progress = useProgress(21)
  const [isLoading, setIsLoading] = useState(true);
  const guiUser = useSelector((state) => state?.user.data)
  const [remoteObjectConfiguration, setRemoteObjectConfiguration] = useState(null);
  const [remoteObjectData, setRemoteObjectData] = useState(null);
  const [currentSearch, setCurrentSearch] = useState({});
  const [currentSort, setCurrentSort] = useState([]);
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [currentSortCol, setCurrentSortCol] = useState(null);
  const [missingRequiredSearchAttributes, setMissingRequiredSearchAttributes] = useState(null);
  const [currentPagination, setCurrentPagination] = useState({
    pageNumber: 1,
    pageSize: getInitialPageSize('reportPage'),
    totalCount: 0
  });
  const [searchModalOpen, setSearchModalOpen] = useState(true)
  const displayAttributes = useMemo(() => getDisplayAttributes(remoteObjectConfiguration?.displayAttributes, attributeList, __), [attributeList, remoteObjectConfiguration])

  const displayableAttributesFilter = useMemo(() => getDisplayAttributesItems(remoteObjectConfiguration?.displayAttributes, attributeList, __), [attributeList, remoteObjectConfiguration])
  const [selectedAttributesId, setSelectedAttributesId] = useState(displayableAttributesFilter.map((a) => (a.value)))

  const [enumValues, setEnumValues] = useState([]);

  const [searchForm] = Form.useForm()
  const params = useParams()

  useEffect(() => {
    api.get(`/configurations/remoteObjects/${params.id}`)
      .then((response) => {
        const remoteObjConfiguration = response.data
        setRemoteObjectConfiguration(remoteObjConfiguration)
        const requiredSearchAttributes = getMissingRequiredSearchAttributes(getSearchFields(remoteObjConfiguration.searchAttributes), remoteObjConfiguration)
        setMissingRequiredSearchAttributes(requiredSearchAttributes)
        setSearchModalOpen(!!requiredSearchAttributes)
      })
      .catch(error => {
        if (error.request && error.request.status === 404) {
          const queryString = parse(props.location.search)
          hashHistory.push(queryString.backUrl || config.ordersDefaultUrl)
        }
      })
  }, [params.id])

  useEffect(() => {
    const enumsToGetValues = []
    if (!isEmpty(attributeList) && !isEmpty(remoteObjectConfiguration)) {
      setSelectedAttributesId(getDisplayAttributesItems(remoteObjectConfiguration.displayAttributes, attributeList, __).map((a) => (a.value)))
      remoteObjectConfiguration.searchAttributes.forEach((attribute) => {
        const searchAttribute_attribute = find(attributeList, (c) => c.id === attribute.attributeId)
        if (attribute.mechanism === 'ENUMERATION' && searchAttribute_attribute.enumerationId) {
          const enumId = searchAttribute_attribute.enumerationId
          enumsToGetValues.push(enumId)
        }
      })

      if (enumsToGetValues.length > 0) {
        enumsToGetValues.forEach((enumId) => {
          fetchEnumById(enumId).then((enumerator) => {
            setEnumValues((prevValue) => [...prevValue, enumerator])
          })
        })
      }

      const search = getSearchFields(remoteObjectConfiguration.searchAttributes)
      //setInitialValues(search)
      setCurrentSearch(search)
      setCurrentSort(remoteObjectConfiguration.sortAttributes)
      submitSearch({
        search,
        sort: remoteObjectConfiguration.sortAttributes,
        pagination: {
          ...currentPagination,
          pageSize: remoteObjectConfiguration?.pageSize || currentPagination.pageSize
        }
      })
    }
  }, [remoteObjectConfiguration, attributeList])


  const submitSearch = ({ search, sort, pagination, updateSort = true }) => {
    if (!search || !sort || !pagination) {
      ERROR("One of the following is null. Search: ", search, " sort: ", sort, " pagination: ", pagination)
      return
    }

    if (missingRequiredSearchAttributes > 0) {
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    const searchAttributes = getSearchAttributes(search, remoteObjectConfiguration, attributeList)
    const payload = {
      id: remoteObjectConfiguration.id,
      name: remoteObjectConfiguration.name,
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber,
      onlySelfOrders: remoteObjectConfiguration.onlySelfOrders,
      displayAttributes: remoteObjectConfiguration.displayAttributes,
      sortAttributes: sort.filter(({ enabled }) => enabled == null || enabled),
      settings: remoteObjectConfiguration?.settings,
      objectTypeId: remoteObjectConfiguration.objectTypeId,
      systemId: remoteObjectConfiguration.systemId,
      searchAttributes
    }
    if (!missingRequiredSearchAttributes){
      fetchRemoteObjectEntries(payload).then((response) => {
        setRemoteObjectData(response.data)
        setCurrentSearch(search)
        setCurrentPagination({
          ...pagination,
          totalCount: parseInt(response.headers['x-total-count']),
          pageSize: parseInt(response.headers['x-page-size']),
          pageNumber: parseInt(response.headers['x-page'])
        })
        if (updateSort) {
          setCurrentSort(sort)
        }
        setIsLoading(false)
      })
    } else
      setIsLoading(false)
  }

  const getMissingRequiredSearchAttributes = useCallback((search, remoteObjectConfig) => {
    const requiredAttributes = remoteObjectConfig?.searchAttributes.filter((f) => f.required)
    if (!requiredAttributes) return 0
    return requiredAttributes.reduce((prev, attr) => {
      if (!search[attr.attributeId]) {
        return prev + 1
      }
      return prev
    }, 0)
  }, [])

  const handleSubmitSearch = () => {
    submitSearch({
      search: currentSearch,
      sort: currentSort,
      pagination: currentPagination
    })
  }

  const handlePaginationChange = ({ pagination, sortAttribute }) => {

    let sort = undefined
    if (sortAttribute) {
      sort = [{
        attributeId: sortAttribute.attributeId,
        value: sortAttribute.direction,
        position: 0
      }]
    }

    submitSearch(
      {
        search: currentSearch,
        sort: sort ?? remoteObjectConfiguration.sortAttributes,
        pagination: { ...currentPagination, ...pagination },
        updateSort: false
      })
  }

  const onSortColumnChange = (sortName, sortOrder) => {
    const sort = getColumnSort(sortName, sortOrder, currentSortCol)

    submitSearch(
      {
        search: currentSearch,
        sort,
        pagination: { ...currentPagination },
        updateSort: false
      })
    setCurrentSortCol(sort)
  }

  return (
    <div id="main-content">
      <S.Container>
        {isLoading ? <S.OverlayFullSpaceSpin /> :

          <S.HeaderContainer>
            <S.HeaderTitle>
              {remoteObjectConfiguration ? capitalize(__(remoteObjectConfiguration.name)) : __('loading')}

              {remoteObjectConfiguration && checkUserRights(guiUser.rights, rightMappings.CAN_SEE_ALL_ORDERS) && (
                <div style={{ float: "right", display: "inline-flex" }}>
                  {
                    exportModalOpen && (
                      <ExportModal
                        objectName={remoteObjectConfiguration?.name}
                        open={exportModalOpen}
                        onClose={() => setExportModalOpen(false)}
                        payloadData={{
                          remoteObjectConfiguration,
                          currentPagination,
                          currentSearch,
                          currentSort
                        }}
                        commonProps={{ attributeList }}
                      />
                    )}
                  <Space>
                    <AntdTooltip title={__('refresh data')}>
                      <IconButton shape="circle" onClick={handleSubmitSearch} icon={<RedoOutlined />} />
                    </AntdTooltip>
                    <ActionOptions
                      disabled={progress?.state?.active}
                      options={[
                        {
                          value: 'OnlyVisible',
                          label: __('Only visible results'),
                          onClick: () => setExportModalOpen(availableExportOptions.partial),
                        },
                        {
                          value: 'All',
                          label: __('All results'),
                          onClick: () => setExportModalOpen(availableExportOptions.total)
                        },
                        {
                          value: 'Exports',
                          label: __('last_exports'),
                          onClick: () => setExportModalOpen(availableExportOptions.onlyExports)
                        },
                      ]}
                    >
                      <AntdTooltip title={__('export')}>
                        <div style={{ position: 'relative' }}>
                          <IconButton shape="circle" icon={<DownloadOutlined />} />
                          <Progress {...progress?.state} />
                        </div>
                      </AntdTooltip>
                    </ActionOptions>
                  </Space>
                </div>
              )}
            </S.HeaderTitle>
            <S.HeaderSection>
              <Space style={{ float: "right" }}>
                <BatModal
                  buttonBadgeCount={missingRequiredSearchAttributes}
                  disabled={!remoteObjectConfiguration?.searchAttributes?.length}
                  buttonProps={{
                    icon: <ControlOutlined />,
                    title: `${__('Filters')} (${Object.values(currentSearch).filter(x => x).length})`
                  }}
                  title={__('Filters')}
                  width={700}
                  forceOpen={searchModalOpen}
                  setForceOpen={setSearchModalOpen}
                  onSubmitCallback={() => searchForm.submit()}
                  onCancelCallback={() => {
                    searchForm.setFieldsValue(currentSearch)
                    setMissingRequiredSearchAttributes(getMissingRequiredSearchAttributes(searchForm.getFieldsValue(), remoteObjectConfiguration))
                  }}
                  footer={
                    <SmartFooter
                      onSubmit={() => { searchForm.submit() }}
                      onReset={() => searchForm.setFieldsValue(getSearchFields(remoteObjectConfiguration.searchAttributes))}
                    />
                  }
                >
                  <SearchParameterForm
                    fields={remoteObjectConfiguration.searchAttributes}
                    attributeList={attributeList}
                    form={searchForm}
                    enumerations={enumValues}
                    initialValues={currentSearch}
                    onValuesChange={() => setMissingRequiredSearchAttributes(getMissingRequiredSearchAttributes(searchForm.getFieldsValue(), remoteObjectConfiguration))}
                    onFinish={(values) => {
                      submitSearch({
                        search: values,
                        sort: currentSort,
                        pagination: currentPagination
                      })
                      setSearchModalOpen(false)
                    }}
                  />
                </BatModal>
                <BatModal
                  title={__('Sorting')}
                  className="medium"
                  disabled={!remoteObjectConfiguration.sortAttributes?.length}
                  buttonProps={{ icon: <SortAscendingOutlined /> }}
                  onSubmitCallback={() => handleSubmitSearch()}
                  footer={
                    <div className='right'>
                      <button
                        className='btn btn-primary'
                        onClick={() => handleSubmitSearch()}
                      >
                        {__("Apply Sorting")}
                      </button>
                    </div>
                  }
                >
                  <SortForm
                    value={currentSort}
                    onChange={setCurrentSort}
                    attributesList={attributeList}
                  />
                </BatModal>
                <Tooltip
                  buttonProps={{
                    icon: <TableOutlined />,
                    title: __('Columns')
                  }}
                >
                  <CheckboxList
                    items={displayableAttributesFilter}
                    value={selectedAttributesId}
                    onChange={setSelectedAttributesId}
                  />
                </Tooltip>
              </Space>
              <Space>
                {!!remoteObjectConfiguration && checkUserRights(guiUser.rights, rightMappings.CAN_CREATE_REMOTEOBJECT_ITEM)
                  && (
                    <CreateInstance
                      configuration={remoteObjectConfiguration}
                      displayDataIds={Object.keys(remoteObjectData?.[0]?.displayData || {})}
                      onSubmit={handleSubmitSearch}
                    />
                  )}
              </Space>
            </S.HeaderSection>
          </S.HeaderContainer>
        }

        {remoteObjectData && !isLoading && (
          <div style={{ marginTop: '20px' }}>
            <RemoteObjectTable
              {...props}
              remoteObjectRecordsSpec={{ displayData: displayAttributes }}
              data={remoteObjectData}
              viewConfiguration={remoteObjectConfiguration}
              attributesConfiguration={attributeList}
              remoteObjectSpec={remoteObjectConfiguration}
              pagination={currentPagination}
              isLoading={isLoading}
              onPageChange={(pageNumber) => handlePaginationChange({ pagination: { pageNumber } })}
              onSortChange={onSortColumnChange}
              selectedColumns={selectedAttributesId}
              setPageSize={(pageSize) => handlePaginationChange({ pagination: { pageSize } })}
              refreshData={handleSubmitSearch}
            />
          </div>
        )}
        {
          !remoteObjectData && !isLoading &&
          <div style={{ textAlign: "center", color: '#76777A', fontSize: '1.25em' }}><i>Missing required filters.</i>
          </div>
        }
      </S.Container>
    </div>
  )
}


