import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {__} from "../../../utils/translationUtils";
import {cases} from "../../../utils/stringUtils";
import Password from "components/dist/Form/Inputs/Password"
import {authenticate} from "../../../providers/ApiProvider/authentication";
import { Gutter } from 'components/dist/styles'
import {Alert} from "antd";

type PasswordConfirmationProps = {
  user: any
  submit: () => void
  close: () => void
}

const PasswordConfirmation = ({user, submit, close}: PasswordConfirmationProps) => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  return (
    <Modal
      backdrop="static"
      show
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title className="capitalize">
          {__('enter_password', cases.CAPITALIZE_SENTENCE)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Password
          placeholder={ __('password')}
          size={'large'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setError(null)
            setPassword(event.target.value)
          }}
          style={{ borderRadius: '4px' }}
        />
        {
          error && (
            <Gutter top>
              <Alert message={__(error)} type="error" showIcon />
            </Gutter>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <div className="group">
          <Button onClick={close} className="pull-left">
            {__('Back')}
          </Button>
          <button
            onClick={
              () => authenticate({username: user.username, password})
                .then((res) => submit())
                .catch((error) => {
                  setError(error?.response?.data?.message)
                })
            }
            disabled={!password}
            className={'capitalize btn pull-right btn-primary'}
          >
            {__('continue')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default PasswordConfirmation
