import _ from 'lodash'
import { defaultAvailableControllers as controllers } from "components/dist/FormBuilder/Config/defaults";
import { selectionMode } from "components/dist/FormBuilder/Components/ControllerTypes/Selectors/Dropdown";
import { __ } from '../../../../../../utils/translationUtils'
import {cases} from "../../../../../../utils/stringUtils";


/* FormBuilder initial values */
export const getInitialValues = (step) => {

  const init = step.fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.id]: getValue(field)
    }
  }, {})

  if (["1", "2", "3"].includes(step.discriminator)) {
    init.assignees = []
  }

  return init
}

const getValue = ({value, controllerType, readOnly, controllerOptions, attributes}) => {
  const {valueToBeTranslated} = attributes?.extendedProperties || {}

  try {
    if (controllerType === controllers.Phone.controllerType) {
      if (controllerOptions.usePrefix && !readOnly) {
        const regex = /((?:00|\+)([0-9]{0,4}))?[ .-]?([0-9 ]{1,12})/
        const re = new RegExp(regex, 'gm')
        const matches = re.exec(value)
        return {prefix: matches?.[1], number: matches?.[3]}
      }
      return {prefix: '', number: value}
    }

    if (controllerType === controllers.Email.controllerType) {
      if (controllerOptions?.useDomains && !readOnly) {
        const regex = /^([^\s@]+)(@)([^\s@]+\.[^\s@]+)$/
        const re = new RegExp(regex, 'gm');
        const matches = re.exec(value);
        return {address: matches?.[1], domain: matches?.[3]}
      }
      return {address: value}
    }

    if (controllerOptions?.objectConfiguration) {
      return {
        selectedItems: value ? value.split(',') : [],
        objectConfiguration: controllerOptions?.objectConfiguration,
      }
    }

    const {
      MultipleChoice: {controllerType: multipleChoiceCT},
      SingleChoice: {controllerType: singleChoiceCT},
      Dropdown: {controllerType: dropdownCT},
    } = controllers

    if ([multipleChoiceCT, singleChoiceCT, dropdownCT].includes(controllerType)) {
      return getSimpleSelectorsInitialValues(value, {controllerType, controllerOptions})
    }

    if (controllerType === controllers.Toggle.controllerType) {
      return value === 'true'
    }

    if (valueToBeTranslated) {
      return __(value)
    }

    const {
      Paragraph: {controllerType: paragraph},
      LongText: {controllerType: longText},
      ShortText: {controllerType: shortText}
    } = controllers

    if ([paragraph, longText, shortText].includes(controllerType))
      return value

    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

const getSimpleSelectorsInitialValues = (value, {controllerType, controllerOptions}) => {
  const {
    MultipleChoice: {controllerType: multipleChoiceCT},
    SingleChoice: {controllerType: singleChoiceCT},
    Dropdown: {controllerType: dropdownCT},
  } = controllers

  // TODO: value = '' should be allowed
  // TODO: value multipleChoice and dropdown must be handled similarly to singleChoice
  if (!controllerOptions.items || !value) return null

  if (multipleChoiceCT === controllerType
    || (dropdownCT === controllerType && controllerOptions.mode === selectionMode.multi)) {

    if (controllerOptions.enumerationId) {
      return value?.split(',').map((v) => {
        const item = findItem(v, controllerOptions.items)

        return {
          type: item ? "option" : "other",
          label: getItemLabel(item),
          value: v
        }
      })
    }

    return value?.split(',').map((v) => {
      const item = findItem(v, controllerOptions.items)
      return {
        type: item ? "option" : "other",
        label: getItemLabel(item),
        value: JSON.stringify({value: v, customised: true})
      }
    })
  }

  if (singleChoiceCT === controllerType
    || (dropdownCT === controllerType && controllerOptions.mode === selectionMode.single)) {

    const item = findItem(value, controllerOptions.items)

    if (controllerOptions.enumerationId) {
      return {
        type: item ? "option" : "other",
        label: getItemLabel(item),
        value
      }
    }
    return {
      type: item ? "option" : "other",
      label: getItemLabel(item),
      value: JSON.stringify(controllerOptions.items?.find((item) => value === item.value))
    }
  }
}

const findItem = (value, items) => {
  return items?.find((item) => value === item.value)
}

const getItemLabel = (item) => {
  return (item.translationKey && __(item.translationKey)) || item.value
}


/* FormBuilder Controller Options */
export const initControllerOptions = (controllerType, controllerOptions) => ({
  ...controllerOptions,
  ...(controllerType === controllers.Email.controllerType && getEmailOptions(controllerOptions)),
  ...(controllerType === controllers.Toggle.controllerType && getToggleOptions()),
  ...getEnumOptions(controllerOptions)
})

const getEmailOptions = (controllerOptions) => ({
  ...controllerOptions?.useDomains && controllerOptions?.domainList ?
    {domainList: controllerOptions.domainList?.map((domain) => ({value: domain, label: domain})),} : {},
})

const getToggleOptions = () => ({
  valueToLabel: (value) => __(value ? 'yes' : 'no', cases.CAPITALIZE_SENTENCE),
})

//TODO apply sort options!!
// sortOptions: controllerOptions?.sortOptions?.ordering || availableSortOptions.ASC
const getEnumOptions = (controllerOptions) => {
  return ({
    ...controllerOptions?.enumerationId &&
    {
      itemList: controllerOptions?.items?.map((i) => ({
        value: i?.value,
        label: i?.translationKey ? __(i?.translationKey) : i?.value,
        translationKey: i?.translationKey || null
      }))
    },
    ...!controllerOptions?.enumerationId && controllerOptions?.items &&
    {
      itemList: controllerOptions?.items?.map((item) => ({
        value: JSON.stringify(item),
        label: item?.translationKey ? __(item?.translationKey) : item?.value,
        translatable: !!item.translationKey
      }))
    },
    ...controllerOptions?.mode && {
      mode: _.toLower(controllerOptions.mode),
    }
  })

}
