/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import Moment from 'moment'
import { Alert, message, TreeSelect } from 'antd'
import { renderField } from '../../../utils/fieldValidator'
import * as S from './styles'
import * as c from '../../../utils/calendarEvents'
import getDefaultAssignees from './utility/getDefaultAssignees'
import getAllUserAssignees from './utility/getAllUserAssignees'
import { __ } from '../../../utils/translationUtils'

const { SHOW_PARENT } = TreeSelect

function hasDuplicates(a) {
  const noDups = new Set(a)

  if (a.length !== noDups.size) {
    return true
  }
  return false
}

const AssignmentField = ({ companiesTree, defaultValue, ...props }) => {
  const { input: { value, onChange } } = props
  
  function handleChange(values) {
    const deparsed = values.map((e) => JSON.parse(e))
    const result = JSON.stringify(deparsed)

    if (hasDuplicates(deparsed.map(((user) => user.id)))) {
      message.error(__('The user can be selected only once'))
      return
    }
    onChange(result)
  }

  if (!value?.length && defaultValue) handleChange(defaultValue)

  const parsedValue = value?.length ? JSON.parse(value).map((e) => JSON.stringify(e)) : []

  return (
    <TreeSelect
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 500, overflowY: 'scroll' }}
      treeData={companiesTree}
      fieldNames={{ label: 'title', value: 'key', children: 'children' }}
      treeCheckable
      multiple
      {...props}
      value={parsedValue}
      onChange={handleChange}
      treeNodeFilterProp="searchValue"
      showCheckedStrategy={SHOW_PARENT}
      placeholder={__('Select an user')}
    />
  )
}

const Appointment = ({ onSubmit, closeModal, modelValues, user, setAppointmentDetails, selectedCompanies, controlData, companiesTree, attachedUsersMap }) => {
  const [busyAssignees, setBusyAssignees] = useState(null)
  const defaultAssignees = getDefaultAssignees({ companiesTree, userId: user?.id, selectedCompanies })
  
  const { start, end, assignees } = modelValues || {}

  async function handleSave() {
    closeModal()
    if (!assignees) {
      message.error(__('At least one assignees has to be selected'))
      return
    }
    setAppointmentDetails() // (defined in StepLegacy)

    getAllUserAssignees(assignees, attachedUsersMap).forEach(({ entityId, userId, user }, index, array) => {
      onSubmit({ dateRange: [start, end], entityId, userId, user, eventType: global.constants.eventTypes.GODOO_EVENT })

      if (array.length > 0 && index === array.length - 1)
        message.success(`${global.constants.eventTypes.GODOO_EVENT} ${__('Created')}!`)
    })
  }

  useEffect(() => {
    if (!assignees || !JSON.parse(assignees)) return
    const startDate = Moment(start).toISOString()
    const endDate = Moment(end).toISOString()

    const listOfApi = JSON.parse(assignees)
      .map(({ user }) => c.getCalendarEvents({ user, startDate, endDate, selectedEventTypes: [global.constants.eventTypes.GODOO_EVENT] }))

    async function fetchBusySlots() {
      const busySlotEvents = await Promise.all(listOfApi).then((response) => response.flat())
        .catch((err) => { console.log(err) })

      setBusyAssignees(
        JSON.parse(assignees).map((item) => ({
          ...item,
          busy: busySlotEvents.find((event) => event.orderData.additionalDisplayData['100_58be96d00e823f552aa1a070'].find((e) => e.id === item.userId)
            && event.orderData.additionalDisplayData['100_58be96d00e823f552aa1a071'].find((e) => e.id === item.entityId))
        }))
      )
    }
    fetchBusySlots()
  }, [start, end, assignees])

  return (
    <S.AppointmentForm>
      <div className="form-group row clearfix">
        <div className="col-sm-6">
          <label>{`${__('Start')}*`}</label>
          <Field
            name="start"
            component={renderField}
            type="datetime"
            inputCol="overwrite"
          />
        </div>
        <div className="col-sm-6 noPaddingRight">
          <label>{`${__('End')}*`}</label>
          <Field
            name="end"
            component={renderField}
            type="datetime"
            inputCol="overwrite"
          />
        </div>
      </div>
      <div className="form-group clearfix">
        <label>{`${__('Assignees')}*`} ({(assignees && JSON.parse(assignees)?.length) || 0})</label>
        <Field
          name="assignees"
          component={AssignmentField}
          companiesTree={companiesTree}
          maxTagCount={controlData.maxNumberOfAssignees || 50}
          defaultValue={defaultAssignees?.slice(0, 1)}
        />
      </div>

      { busyAssignees?.filter((e) => e.busy)?.length > 0 && (
        <S.BusyAssignee><Alert
          message={`${__('Busy assignees')}: ${busyAssignees.filter((e) => e.busy).map(({ user, entity }) => `(${user.username} - ${entity.shortName}) `)}`}
          type="warning"
          closable
          showIcon
        />
        </S.BusyAssignee>
      )}

      <div className="modal-footer" style={{ margin: '0 -15px', padding: '15px 25px 0px' }}>
        <button
          type="submit"
          onClick={handleSave}
          className="btn btn-primary"
          disabled={!assignees || JSON.parse(assignees)?.length === 0}
        >
          {__('Save')}
        </button>
      </div>
    </S.AppointmentForm>
  )
}

const AppointmentForm = reduxForm({ form: 'appointmentForm' })(Appointment)

export default connect(
  (state, props) => ({
    guiUser: state.user.data,
    modelValues: state.form?.appointmentForm?.values,
    reduxState: state,
    initialValues: {
      start: props.start,
      end: props.end
    }
  }),
  {}
)(AppointmentForm)
