import React, {useEffect, useRef} from 'react';
import {useSelector} from "react-redux";

import { hashHistory } from '../../providers/HistoryProvider'
import {
  showMessage,
  fetchView,
  createView,
  updateView,
  fetchReport,
  createReport,
  updateReport,
  fetchAttributeTree,
  fetchRemoteSystems,
  fetchValueTypesMappings,
  fetchEntities,
  fetchPublishedFlowsLastVersion,
  getAttributesByFlows,
  fetchEnumById,
  fetchEnumValuesByIds,
} from '../../utils/appHelper'
import {getGuiUser} from "../../providers/ReduxProvider/actions/userActions";
import ContextProvider, { useConfigurationConfiguratorContext } from "./context";
import Loading from "../../components/Loading";
import Header from './components/Header'
import Footer from './components/Footer'
import Body from './components/Body'
import * as C from './constants'
import * as S from './styles'
import * as U from './utils'
import { __ } from '../../utils/translationUtils'

const View = ({ id, apis }) => {

  const { state } = useConfigurationConfiguratorContext()
  const { mode } = state
  const user = useSelector((state) => state?.user)
  const viewPropertiesRef = useRef(null)

  useEffect(() => {
    if (!user) getGuiUser()
  }, [user])

  const handleSubmit = () => {
    const payload = U.mapConfigurationPayload.FrontendToBackend(state.configurations, user.data.id)
    switch (mode) {
      case C.mode.CREATE:
        if (!apis.createConfiguration) return
        apis.createConfiguration(payload).then((resp) => {
          if(resp.status === 200){
            hashHistory.goBack()
            showMessage('success', __('configuration_create_success'))
          }
        })
        break
      case C.mode.EDIT:
        if (!apis.updateConfiguration) return
        apis.updateConfiguration(payload, id).then((resp) => {
          if(resp.status === 200){
            hashHistory.goBack()
            showMessage('success', __('configuration_update_success'))
          }
        })
        break
      default:
        return
    }
  }

  return (
    <S.Background>
      <Header/>
      {state.loading && <Loading />}
      <Body
        activeStep={state.activeStep}
        viewPropertiesRef={viewPropertiesRef}
      />
      <Footer
        handleSubmit={handleSubmit}
      />
    </S.Background>
  )
}

const Configurator = (props) => {
  const {
    type,
    mode,
    id,
    apis
  } = props
  return (
    <ContextProvider
      type={type}
      mode={mode}
      id={id}
      apis={apis}>
      <View
        id={id}
        apis={apis}
      />
    </ContextProvider>
  )
}


export default (props) => {
  const { mode, type } = props
  const id = props?.match?.params?.id
  const apis = {
    fetchConfiguration: type === C.type.VIEW ? fetchView : fetchReport,
    createConfiguration: type === C.type.VIEW ? createView : createReport,
    updateConfiguration: type === C.type.VIEW ? updateView : updateReport,
    fetchAttributeTree: fetchAttributeTree,
    fetchRemoteSystems: fetchRemoteSystems,
    fetchValueTypesMappings: fetchValueTypesMappings,
    fetchEntities: fetchEntities,
    fetchPublishedFlowsLastVersion: fetchPublishedFlowsLastVersion,
    fetchEnumById: fetchEnumById,
    fetchEnumValuesByIds: fetchEnumValuesByIds,
    getAttributesByFlows: getAttributesByFlows,
  }
  return (
    <Configurator
      type={type}
      mode={mode}
      id={id}
      apis={apis}
    />
  )
}
