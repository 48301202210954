import { ERROR } from 'components/dist/Utils/LoggerUtils';
import Cookies from 'js-cookie';
import * as types from '../types';
import { hashHistory } from '../../HistoryProvider';
import {
  checkUserRights,
  getApiHandler,
  getAuthData,
  logoutUser,
  refreshToken,
  showMessage,
} from '../../../utils/appHelper';
import {api, authenticate} from '../../ApiProvider';
import * as config from '../../../constants/globalConfiguration';
import { urlAny, urlGodoo, urlLocalhost } from '../../../constants/regexes';
import { availableBehaviours } from '../../../components/EntitySetting/constants';
import { __ } from '../../../utils/translationUtils';

export const loggingInAction = () => ({ type: types.USER_LOGGING_IN });

export const loggedInAction = (payload) => ({
  type: types.USER_LOGGED_IN, payload,
});

export const loginErrorAction = (payload) => ({
  type: types.USER_LOGIN_ERROR, payload,
});

export const refreshTokenStartAction = (payload) => ({
  type: types.TOKEN_REFRESH_START, payload,
});

export const refreshTokenEndAction = () => ({
  type: types.TOKEN_REFRESH_END,
});

export const handleRefreshToken = (dispatch) => {
  dispatch(refreshTokenStartAction(Date.now()));
  refreshToken()
    .then((res) => {
      handleStoreAuthToken({
        authToken: res?.data?.token,
      }, dispatch);
      dispatch(refreshTokenEndAction());
    })
    .catch((e) => {});
};

export const loginErrorActionCreator = (payload) => (dispatch) => {
  dispatch(loginErrorAction(payload));
};

export const loginActionCreator = (data) => {
  const { credentials, nextUrl } = data;

  return async (dispatch) => {
    dispatch(loggingInAction());

    authenticate(credentials)
      .then((response) => {
        handleStoreAuthToken({ authToken: response?.data?.token }, dispatch);
        goToNextURL(nextUrl);
      })
      .catch((error) => {
        dispatch(loginErrorAction(error.response?.data?.message));
      });
  };
};

export function isLocalhost() {
  return (
    typeof window !== 'undefined'
    && (
      window.location.hostname === 'localhost'
      || window.location.hostname === '127.0.0.1'
      || window.location.hostname === '10.10.110.51'
    )
  );
}

function getCookieDomain() {
  if (isLocalhost()) {
    return null;
  }
  // Split the hostname by dots
  const parts = window.location.hostname.split('.');

  // Check if there are at least three parts (subdomain, domain, top-level domain)
  if (parts.length < 3 || parts[1] !== 'godoo') {
    return null;
  }

  return parts.slice(parts.length - 3).join('.');
}

export const getAuthCookieName = () => {
  if (isLocalhost()) {
    return 'auth';
  }
  // Split the hostname by dots
  const parts = window.location.hostname.split('.');

  return `${parts.slice(parts.length - 3, 1)[0]}-auth`;
};
export const geAuthCookieAttributes = () => {
  const cookieAttributes = ({ path: '/', sameSite: 'Lax' });

  if (!isLocalhost()) {
    cookieAttributes.domain = getCookieDomain();
    cookieAttributes.secure = true;
  }

  return cookieAttributes;
};

export const handleStoreAuthToken = ({ authToken }, dispatch) => {
  const cookieAttributes = geAuthCookieAttributes();
  cookieAttributes.expires = 7;

  Cookies.set(getAuthCookieName(), authToken, cookieAttributes);
  dispatch(loggedInAction({ authToken }));
  setDefAuthHeader();
};

const setDefAuthHeader = () => {
  const token = getAuthData();

  if (token) {
    api.defaults.headers.common['x-auth-token'] = token;
  }
};

export const goToNextURL = (nextUrl = '/') => {
  if (nextUrl.match(urlAny)) {
    if (nextUrl.match(urlGodoo) || nextUrl.match(urlLocalhost)) {
      const url = new URL(nextUrl);
      window.location = url.toString();
    } else {
      window.location = nextUrl;
    }
  } else {
    hashHistory.push(nextUrl);
  }
};

export function forgotPasswordRequest(data) {
  return function (dispatch) {
    dispatch({ type: types.USER_FORGOT_PASSWORD_REQUEST_START });
    return tryForgotPassword(data)
      .then((response) => {
        showMessage('success', __('Request sent successfully. Please check your email'));
        dispatch({
          type: types.USER_FORGOT_PASSWORD_REQUEST_FINISHED, payload: response.data,
        });
      }, (error) => dispatch({
        type: types.USER_FORGOT_PASSWORD_REQUEST_ERROR, payload: error.response.data.message,
      }));
  };
}

export function resetPassword(data) {
  return function () {
    return api.post('forgotPassword/code/', data)
      .then(() => {
        showMessage('success', __('Password set successfully'));
      }).catch(() => hashHistory.push('/login'))
      .then(() => {
        hashHistory.push('/login');
      });
  };
}

const blacklistRedirects = ['/login', '/custom-system-env'];

export function logout(callback) {
  return function (dispatch) {
    dispatch({ type: types.USER_LOGGED_OUT });
    setTimeout(() => {
      logoutUser();
      let redirect = window.location.hash.slice(1);
      if (redirect === '/' || blacklistRedirects.some((r) => redirect.startsWith(r))) { redirect = undefined; }

      hashHistory.push(`/login${redirect ? `?redirect=${redirect}` : ''}`);
      Cookies.remove(getAuthCookieName(), geAuthCookieAttributes());
      localStorage.removeItem('entities');
      localStorage.removeItem('calendarDefaultSelection');
      localStorage.removeItem('mostRecentItems');
      callback?.();
    }, 1000);
    window.translatorPopupShowDate = undefined;
  };
}

export function setLanguage(lang) {
  let newLang = lang;
  if (!newLang) {
    newLang = 'en_GB';
  }

  api.defaults.headers.common.Language = window.localStorage.getItem('lang');
  localStorage.setItem('lang', newLang);

  return function (dispatch) {
    dispatch({
      type: types.LANGUAGE_SELECTED, payload: { lang: newLang },
    });
  };
}

export function redirect(path) {
  return function () {
    hashHistory.push(path);
  };
}

function tryForgotPassword(data) {
  return api.post('forgotPassword', data.values);
}

function tryGetData(key) {
  return api.get(`/users/${key}`);
}

export function getUser(key) {
  return function (dispatch) {
    return tryGetData(key)
      .then((response) => {
        dispatch({
          type: types.USER_GOT_DATA, payload: response.data,
        });

        // get User Rights
        api.get(`users/${response.data.id}/rights`).then((response) => {
          dispatch({
            type: types.USER_GOT_RIGHTS, payload: response.data,
          });
        });
      });
  };
}

export function getGuiUser() {
  const { rightMappings } = global.constants;

  return function (dispatch) {
    return api.get('/me')
      .then((response) => {
        dispatch({
          type: types.USER_GOT_DATA, payload: response.data,
        });
        if (response?.data?.belongsTo) {
          const userCompanies = response.data.belongsTo;
          localStorage.setItem('entities', JSON.stringify(userCompanies));
          // TODO: get value from UI
          api.defaults.headers.common['x-entity-id'] = userCompanies?.[0]?.id;
        }

        // get User Rights
        api.get(`users/${response.data.id}/rights`).then((response) => {
          dispatch({
            type: types.USER_GOT_RIGHTS, payload: response.data,
          });
          if (checkUserRights(response.data, rightMappings.CAN_LIST_REPORTS)) {
            getUserReports(dispatch);
          }

          if (checkUserRights(response.data, rightMappings.CAN_LIST_VIEWS)) {
            getUserViews(dispatch);
          }

          if (checkUserRights(response.data, rightMappings.CAN_LIST_ORDERGROUPS)) {
            getUserOrderGroups(dispatch);
          }
          if (checkUserRights(response.data, rightMappings.CAN_LIST_ORDERTYPES)) {
            getUserOrderTypes(dispatch);
          }
          getRemoteSystems(dispatch);
          getLanguages(dispatch);
          getSystemSettings(dispatch);
        });
      }).catch(() => {
        ERROR(__('GetGUIUserServerSideError'));
      });
  };
}

export function getUserReports(dispatch) {
  return api.get('/configurations/reports')
    .then((response) => {
      dispatch({
        type: types.USER_GOT_REPORTS, payload: response.data,
      });
    });
}

export function getUserViews(dispatch) {
  return api.get('/configurations/views?pageSize=200')
    .then((response) => {
      dispatch({
        type: types.USER_GOT_VIEWS, payload: response.data,
      });
    });
}

export function getUserOrderGroups(dispatch) {
  return api.get('/orderGroups')
    .then((response) => {
      dispatch({
        type: types.USER_GOT_ORDERGROUPS, payload: response.data,
      });
    });
}

export function dispatchUserOrderTypes() {
  return function (dispatch) {
    getUserOrderTypes(dispatch);
  };
}

export function getUserOrderTypes(dispatch) {
  return api.get('/orderTypes?sort=name&pageNumber=1&pageSize=200')
    .then((response) => {
      dispatch({
        type: types.USER_GOT_ORDERTYPES, payload: response.data,
      });
    });
}

export function getRemoteSystems(dispatch) {
  return api.get('/remoteSystems')
    .then((response) => {
      dispatch({
        type: types.REMOTE_SYSTEMS_GOT, payload: response.data,
      });
    });
}

export function getLanguages(dispatch) {
  return api.get(`${config.prefix()?.TRANSLATION || ''}/languages?enabled=true`, { data: null })
    .then((response) => {
      dispatch({
        type: types.LANGUAGE_AVAILABLE, payload: response.data,
      });
    }).catch((e) => console.error(e));
}

export function storeAttributesMap(payload) {
  return {
    type: types.GOT_ATTRIBUTES,
    payload,
  };
}

export function storeAttributesList(payload) {
  return {
    type: types.GOT_ATTRIBUTES_LIST,
    payload,
  };
}

export function storeAttributesTree(payload) {
  return {
    type: types.GOT_ATTRIBUTES_TREE,
    payload,
  };
}

export const updateAttributesTree = (payload) => ({
  type: types.GOT_ATTRIBUTES_TREE, payload,
});

export function getLanguagesWrapped() {
  return getLanguages;
}

export function getSystemSettingsWrapped() {
  return getSystemSettings;
}

export function getSystemSettings(dispatch) {
  const unauthenticatedAPI = getApiHandler();

  unauthenticatedAPI.defaults.headers.get['Content-Type'] = 'application/json';
  unauthenticatedAPI.defaults.data = null;

  return unauthenticatedAPI.get('/systemConfigurations')
    .then((response) => {
      const payload = {};
      response.data.forEach((c) => {
        if (c.key === 'instanceOwner') {
          payload.instanceOwner = !!parseInt(c.value);
          return;
        }
        payload[c.key] = c.value;
      });
      dispatch({
        type: types.GOT_SYSTEM_SETTINGS, payload,
      });
    }).catch((err) => ERROR('Cannot fetch system settings. Got:', err));
}

export function changePassword(userId, newPassword, shouldLogout) {
  return function () {
    return api.patch(`/users/${userId}/password`, { password: newPassword })
      .then((response) => {
        if (response.status === 200) {
          // If is current gui user, logout (jwt token will be invalidated anyway because it contains the token)
          if (shouldLogout) {
            logout();
          }
          showMessage('success', __('Password set successfully'));
        }
      });
  };
}

export function updateUser(params, templatess = null, redirect = false) {
  const { templates, userRights, ...rest } = params;

  return function () {
    const payload = { ...rest };
    if (payload.validFrom === '') {
      payload.validFrom = null;
    }
    if (payload.validTo === '') {
      payload.validTo = null;
    }

    return api.put(`/users/${payload.id}`, { ...payload })
      .then((response) => {
        if (response.status === 200) {
          if (redirect) {
            hashHistory.push('/users');
          }

          setTimeout(() => {
            showMessage('success', __('User updated successfully'));
          }, 200);
        }
      });
  };
}

export function createUser(params, templates = null, redirect = false) {
  delete params.userRights;
  delete params.templates;

  return function () {
    return api.post('/users', { ...params })
      .then((response) => {
        if (response.status === 201) {
          if (redirect) {
            hashHistory.push(`/users/${response.data.id}/edit?showRolesModal=true`);
          }

          setTimeout(() => {
            showMessage('success', __('User created successfully'));
            showMessage('warning', __('SetUserRolesWarning'));
          }, 200);
        }
      })
      .catch((error) => {
        showMessage('error', `${__('ServerSideError')}. ${error}`);
      });
  };
}

function trySignUp(entityId, data) {
  if (!entityId) return Promise.reject('Entity ID is required');
  return api.post(`register?entityId=${entityId}`, data);
}

export function signUpActionCreator(entity, data, onSuccessfulSignUp, nextUrl) {
  return function (dispatch) {
    dispatch({ type: types.USER_SIGN_UP_START });
    return trySignUp(entity.id, data)
      .then((response) => {
        showMessage('success', __('Registered successfully'));

        switch (onSuccessfulSignUp) {
          case availableBehaviours.REDIRECT_TO_SUCCESSFUL_SIGN_UP_PAGE:
            hashHistory.push(`/successfulRegistration/${entity.name}`);
            break;
          case availableBehaviours.AUTOMATIC_LOGIN:
            if (response?.data?.token) {
              handleStoreAuthToken({ authToken: response.data.token }, dispatch);
              goToNextURL(nextUrl);
            }
	          break;
          case availableBehaviours.REDIRECT_TO_LOGIN_PAGE:
          default:
            hashHistory.push(`/login${nextUrl ? `?redirect=${nextUrl}` : ''}`);
        }

        return dispatch({ type: types.USER_SIGN_UP_FINISHED, payload: response });
      })
      .catch((error) => dispatch({
        type: types.USER_SIGN_UP_ERROR, payload: error.response,
      }));
  };
}

export function getInstanceSettings() {
  return function (dispatch) {
    const DEFAULT_ENTITY = '100_58be96d00e823f552aa9a730'; // todo change when implement the godoo level entity selection
    return api.get(`entitySettings/${DEFAULT_ENTITY}`)
      .then((response) => {
        dispatch({
          type: types.GOT_INSTANCE_SETTINGS, payload: response.data,
        });
      });
  };
}

export function getEntitySettingsActionCreator(entityId) {
  return function (dispatch) {
    if (!entityId) return ERROR('Entity ID is required');
    return api.get(`entitySettings/${entityId}`)
      .then((response) => {
        dispatch({
          type: types.GOT_INSTANCE_SETTINGS, payload: response.data,
        });
      });
  };
}
