import svgToImage from 'svg-to-image';
import { isPNG } from "../../../../../../utils/appHelper";
import FileUploaderService from "../services/fileUploaderService";
import { uploadFullFile } from "../../../../../../providers/ApiProvider/filesIO";


export const getFileBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export const resizeImage = (file, maxWidth, maxHeight, quality) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = function () {

      let width = img.naturalWidth
      let height = img.naturalHeight

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width
          width = maxWidth
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height
          height = maxHeight
        }
      }
      canvas.width = width;
      canvas.height = height

      // Resize the image
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

      // Convert the canvas to a Blob
      canvas.toBlob((blob) => {
        blob.name = file.name
        resolve(blob);
      }, isPNG(file?.type) ? file?.type : 'image/jpeg', quality);
    };

    img.onerror = function () {
      reject(new Error("Error loading image."));
    };

    img.src = URL.createObjectURL(file);
  });
}

export const convertSvgToPNG = (file, maxWidth, maxHeight, quality) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (event) {
      const svgString = event.target.result
      const options = {
        width: maxWidth,
        height: maxHeight,
        encoderOptions: quality,
      }

      // Fix SVG document for Firefox
      const parser = new DOMParser()
      const svgDoc = parser.parseFromString(svgString, 'image/svg+xml')
      const fixedSvgDoc = fixSvgDocumentFF(svgDoc)

      // Convert SVG to image
      svgToImage(fixedSvgDoc.documentElement.outerHTML, function (err, image) {
        if (err) {
          reject(err)
        }
        else {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const context = canvas.getContext('2d')

          context.drawImage(image, 0, 0)
          canvas.toBlob(function (blob) {
            resolve(blob)
          }, 'image/png', quality)
        }
      }, options)
    }
    reader.readAsText(file)
  })
}

function fixSvgDocumentFF(svgDocument) {
  try {
    let widthInt = parseInt(svgDocument.documentElement.width.baseVal.value) || 500
    let heightInt = parseInt(svgDocument.documentElement.height.baseVal.value) || 500
    svgDocument.documentElement.width.baseVal.newValueSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX, widthInt)
    svgDocument.documentElement.height.baseVal.newValueSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX, heightInt)
    return svgDocument
  } catch (e) {
    return svgDocument
  }
}

export const getImageThumbnail = async (file, authToken, callback) => {
  if (file.smallUrl) {
    let smallBlob = await FileUploaderService.getUrlFile(file.smallUrl, authToken)
    smallBlob = smallBlob?.slice(0, smallBlob.size, 'image/png')
    if (smallBlob)
      getFileBase64(smallBlob).then((smallBase64) => callback(smallBase64))
    else {
      const fileBlob = await FileUploaderService.getUrlFile(file.url, authToken)
      resizeImage(fileBlob, 200, 200, 0.8).then(async (smallBlob) => {
        getFileBase64(smallBlob).then((smallBase64) => {
          callback(smallBase64)
          uploadFullFile(file.smallUrl, smallBlob)
        })
      })
      resizeImage(fileBlob, 720, 720, 0.8).then(async (medium) => {
        uploadFullFile(file.mediumUrl, medium)
      })
    }
  }
}
