import React from 'react'
import {__} from "../../../../../utils/translationUtils";
import TreeSelect from "../../../../UI/TreeSelect";
const { SHOW_PARENT } = TreeSelect

export default (props) => {
  const { companiesTree } = props

  return (
    <TreeSelect
      dropdownStyle={{ maxHeight: 500, overflowY: 'scroll' }}
      treeData={companiesTree}
      fieldNames={{ label: 'title', value: 'key', children: 'children' }}
      treeCheckable
      multiple
      treeNodeFilterProp="searchValue"
      showCheckedStrategy={SHOW_PARENT}
      placeholder={__('Search user(s)')}
      maxTagCount={15}
      { ...props }
    />
  )
}
