import React, {useMemo} from 'react'
import _ from 'lodash'
import {FormViewer, getDefaultFormViewerOptions} from 'components/dist/FormBuilder'
import {getInitialValues, initControllerOptions} from "./initialization"
import {getFieldsCustomProps} from "./initialization/customProps"
import ObjectSelector, {objectSelectorFieldValidation} from "../../customRender/ObjectSelector"
import FileUploader, {fileUploaderFieldValidation} from "../../customRender/FileUploader"

import {getUpdatedValue, getWatchedFieldsId, LoadingSkeleton} from "../../../utils"
import {StepPageInfo, Tag} from "../../styles"
import {Flex} from "components/dist/styles"
import {commonTextFieldValidation} from "components/dist/FormBuilder/Components/ControllerTypes/Text/utils";
import {errorLevel} from "components/dist/FormBuilder/Components/UI/Grid/utils";
import {__} from '../../../../../utils/translationUtils'
import OrderOverviewTrigger from "../../OrderOverviewTrigger";
import {UseCombinedQueries} from "../../../../../providers/QueryClientProvider/queries";

const shortTextValidation = (options) => {
  return [
    {
      message: {value: 'This field is invalid!', visible: errorLevel.CHANGE},
      validator: (_, value) => {
        if (value && options?.regex?.js) {
          const regex = new RegExp(options?.regex?.js)

          if (!regex.test(value))
            return Promise.reject()
        }
        return Promise.resolve()
      },
    },
    ...commonTextFieldValidation(options)
  ]
}

const NormalStep = ({formRef, order, activeProcess, step, openOrderOverview}) => {
  const {fields, buttons, ...stepData} = step

  const {attributes, attributesTree} = UseCombinedQueries(["attributes", "attributesTree"])

  const attributesDataStructures = useMemo(
    () => (attributes && attributesTree) ? {attributes, attributesTree} : null
    , [attributes, attributesTree])

  const sorted = useMemo(() => fields && _.sortBy(fields, 'position'), [fields])
  const viewerOptions = useMemo(() => {
    const defaultFormViewerOptions = getDefaultFormViewerOptions()
    const {availableControllers} = defaultFormViewerOptions

    return {
      ...defaultFormViewerOptions,
      availableControllers: {
        ...availableControllers,
        ShortText: {
          ...availableControllers.ShortText,
          features: {
            ...availableControllers.ShortText.features,
            fieldValidation: shortTextValidation
          }
        },
        ObjectBinder: {
          ...availableControllers.ObjectBinder,
          features: {
            ...availableControllers.ObjectBinder.features,
            fieldValidation: objectSelectorFieldValidation
          },
          customRender: (props) => (<ObjectSelector key={props.id} {...props} />)
        },
        ObjectPicker: {
          ...availableControllers.ObjectPicker,
          features: {
            ...availableControllers.ObjectPicker.features,
            fieldValidation: objectSelectorFieldValidation
          },
          customRender: (props) => (<ObjectSelector key={props.id} {...props} />)
        },
        FileUploader: {
          ...availableControllers.FileUploader,
          customRender: (props) => (<FileUploader key={props.id} {...props} />),
          features: {
            ...availableControllers.FileUploader.features,
            fieldValidation: fileUploaderFieldValidation
          }
        },
      }
    }
  }, [])

  const initialValues = useMemo(() => step && getInitialValues(step), [step])
  const customFieldsProps = useMemo(() => attributesDataStructures && fields && getFieldsCustomProps(fields, {...attributesDataStructures}), [fields, attributesDataStructures])

  if (!order || !step) return null

  return (
    attributesDataStructures ?
      <FormViewer
        formRef={formRef}
        options={viewerOptions}
        additionalInfo={{
          stepInfo: {
            id: stepData.id,
            values: {
              ...stepData,
              title:
                <Flex style={{lineHeight: "18px"}}>
                  {__(stepData.title)}
                  <StepPageInfo>
                    {activeProcess && <Tag>{__(activeProcess.title)}</Tag>}
                    {order &&
                      <Tag>{order && <OrderOverviewTrigger order={order} openOrderOverview={openOrderOverview}/>}</Tag>}
                  </StepPageInfo>
                </Flex>,
            },
          },
          fieldList: sorted
            .map(({attributes, controllerOptions, controllerType, id, ...values}, index) => {
              const {valueToBeTranslated, ...cellProperties} = attributes?.extendedProperties || {}

              const fieldValues = {
                ...customFieldsProps[id],
                ...values,
                ...initControllerOptions(controllerType, controllerOptions),
                title: values.title && __(values.title),
                description: values.description && __(values.description),
                hidden: !values.visible,
                fieldsWatcher: {
                  callback: (currentValue, fieldsValue, setFieldValue) => {
                    if (!Object.keys(fieldsValue).length) return
                    const updatedValue = getUpdatedValue(currentValue, fieldsValue, {
                      ...values,
                      controllerOptions
                    }, step)
                    if (!_.isEqual(currentValue, updatedValue)) {
                      setFieldValue(updatedValue)
                    }
                  },
                  deps: getWatchedFieldsId({...values, controllerOptions, id, controllerType}, step),
                }
              }

              return {
                id,
                controllerType,
                values: fieldValues,
                cellProperties
              }
            })
        }}
        initialValues={initialValues}
      /> : <LoadingSkeleton/>
  )
}

export default NormalStep
