import React, {useEffect} from 'react';
import {useSelector} from "react-redux";

import {getGuiUser} from "../../providers/ReduxProvider/actions/userActions";
import {Background} from '../ConfigurationConfiguratorBeta/styles'
import ContextProvider, { useROEConfiguratorContext } from "./context";
import Loading from "../../components/Loading";
import Header from './components/Header'
import Footer from './components/Footer'
import Body from './components/Body'
import * as C from './constants'
import {
  createReport,
  fetchAttributeTree,
  fetchEntities,
  fetchEnumById,
  fetchRemoteObjectConfiguration,
  fetchRemoteObjects,
  fetchRemoteSystems,
  fetchValueTypesMappings,
  updateReport,
  createRemoteObjectConfiguration,
  updateRemoteObjectConfiguration,
  fetchEnumValuesByIds,
} from "../../utils/appHelper";

const View = ({ ROEId }) => {
  const { state } = useROEConfiguratorContext()
  const { activeStep } = state || {}
  const user = useSelector((state) => state?.user)

  useEffect(() => {
    if (!user) getGuiUser()
  }, [user])

  const theme = (
    activeStep === C.steps.LISTING_PREVIEW ||
    activeStep === C.steps.CONTENT_PREVIEW ||
    activeStep === C.steps.SYSTEM_OBJECT_SELECTION
  ) ? 'dark' : 'light'


  return (
    <Background theme={theme}>
      <Header/>
      {state.loading && <Loading />}
      <Body/>
      <Footer theme={theme} ROEId={ROEId}/>
    </Background>
  )
}

export default (props) => {
  const { mode } = props
  const ROEId = props?.match?.params?.id
  const apis = {
    fetchConfiguration: async(id) => fetchRemoteObjectConfiguration(id).then((r) => r.data).catch(() => null),
    createConfiguration: createReport,
    updateConfiguration: updateReport,
    fetchAttributeTree: fetchAttributeTree,
    fetchRemoteSystems: fetchRemoteSystems,
    fetchRemoteObjects: fetchRemoteObjects,
    fetchValueTypesMappings: fetchValueTypesMappings,
    fetchEntities: fetchEntities,
    fetchEnumById: fetchEnumById,
    fetchEnumValuesByIds: fetchEnumValuesByIds,
    createRemoteObjectConfiguration,
    updateRemoteObjectConfiguration,
  }
  return (
    <ContextProvider mode={mode} id={ROEId} apis={apis}>
      <View ROEId={ROEId} />
    </ContextProvider>
  )
}
