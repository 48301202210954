import styled from 'styled-components'
import {Collapse} from "antd";

export const CollapseError = styled(Collapse)`
    background-color: #fafafa8f;

    &,
    .ant-collapse-item,
    .ant-collapse-content {
        border: unset;   
    }
    
    .ant-collapse-header {
        border: 1px solid #ffccc6;
    }
`
