import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
	change,
	Field,
	getFormValues,
	reduxForm,
	reset,
	getFormSyncErrors,
} from 'redux-form'
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import _ from 'lodash'
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Empty,
  Row,
  Space,
  Statistic,
  Steps,
  Tag,
  Tooltip,
  Typography
} from 'antd'
import { isRequired, renderField } from '../utils/fieldValidator'
import {
  createFlowRemoteItem,
  editFlowRemoteItem,
  fetchEnumValuesByIds,
  fetchFlowRemoteItem,
  fetchFlowRemoteTableData,
} from '../utils/appHelper'
import { api } from '../providers/ApiProvider'
import { dispatchUserOrderTypes } from '../providers/ReduxProvider/actions/userActions'
import { appDefaults, getApiUrl } from '../constants/globalConfiguration'
import PanelHeader, { CollapseWrapper } from './PanelHeader'
import MessageView from './UI/Lottie/MessageView'
import ObjectSelector from "./DooOrderPage/Step/customRender/ObjectSelector"
import moment from "moment";
import { hashHistory } from "../providers/HistoryProvider";
import Glyphicon from '@strongdm/glyphicon'
import Loading from "./Loading";
import NoData from './UI/NoData';
import { Link } from "react-router-dom";
import { __ } from '../utils/translationUtils'
import {fetchAttributes, fetchAttributesTree} from "../providers/ApiProvider";

const { Panel: AntPanel } = Collapse
const { Step: AntStep } = Steps

const { Text, Title } = Typography;

const ORDER_OVERVIEW_STEP = 'orderOverview'
const ORDER_OVERRIDE_STEP = 'orderOverride'
const PROCESSES_OVERRIDE_STEP = 'processesOverride'
const REMOTE_INITIALIZE_STEP = 'remoteInitialize'
const ORDER_CREATION_STEP = 'orderCreation'
const PANEL_HEIGHT = 660

const StepStyled = styled(AntStep)`
`
const ButtonStyled = styled(Button)`
  margin-left: 1rem;
  width: 14rem;
`

const StepContentStyled = styled.div`
  height: 660px;
  overflow-y: auto;
`

const StepActionStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

const STEPS_PREV_NEXT_SORTED = [ORDER_OVERVIEW_STEP, REMOTE_INITIALIZE_STEP, ORDER_OVERRIDE_STEP, PROCESSES_OVERRIDE_STEP, ORDER_CREATION_STEP]

const validate = (values) => {
  const errors = {}
  errors.orderTypeId = isRequired(values.orderTypeId)
  if (values.assignToMe) {
    errors.defaultAssignees = isRequired(values.defaultAssignees)
  }

  if (values.setExternalId) {
    errors.externalOrderId = isRequired(values.externalOrderId)
  }

  const objectsRequired = Object.entries(values).filter(([k, v]) => k.includes("objectTypeRequiredAtInitialization,"))
  if (objectsRequired?.length !== 0) {
    objectsRequired.forEach(([k, v]) => {
      errors[k] = isRequired(v?.selectedItems)
    })
  }

  return errors
}

class CreateOrderForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    reset: PropTypes.func,
    orderTypes: PropTypes.array,
    guiUser: PropTypes.object,
    isLoading: PropTypes.bool,
    canCreate: PropTypes.bool,
    entities: PropTypes.array,
    dispatchUserOrderTypes: PropTypes.func
  };

  constructor(props) {
    super(props)

    this.state = {
      assignToMe: false,
      processList: [],
      stepsValidation: {},
      currentStep: ORDER_OVERVIEW_STEP,
      flow: { id: null, version: null },
      panelOpen: [],
      objectTypeRequiredAtInitialization: {},
      attributesTree: {},
	    attributesList: [],
      setDeadlineModal: {
        visible: false,
        processSelected: null
      },
      isLoading: false,
      isCreateButtonDisabled: false,
      showRemoteObjSectionErrors: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getAttributesConfigurations() {
	  this.setState({ loading: true })

	  Promise.all([fetchAttributes(), fetchAttributesTree()])
		  .then((responses) => {
			  this.setState({
				  attributesList: responses[0],
				  attributesTree: responses[1],
			  });
		  })
		  .catch((error) => {
			  console.error("Error fetching data: ", error);
			  this.setState({loading: false});
		  })
		  .finally(() => {
			  this.setState({loading: false});
		  })
  }

  componentDidMount() {
    this.props.dispatchUserOrderTypes()
    this.fullFillStepsValidation()
    this.getAttributesConfigurations()
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (Object.values(this.props.values).sort().join(',') !== Object.values(prevProps.values).sort().join(',')) {
      this.fullFillStepsValidation()
      return
    }

    if (Object.keys(prevState.objectTypeRequiredAtInitialization).sort().join(',') !== Object.keys(this.state.objectTypeRequiredAtInitialization).sort().join(',')) {
      this.fullFillStepsValidation()
    }
  }

  handleSubmit(values) {
    this.setState({ isCreateButtonDisabled: true })
    this.props.handleSubmit(values)
  }

  haveMandatoryObjects() {
    return this.state.objectTypeRequiredAtInitialization && Object.keys(this.state.objectTypeRequiredAtInitialization).length > 0
  }

  renderEntities() {
    const entityGroups = _.groupBy(this.props.entities, (e) => e.belongsTo.shortName)
    if (Object.keys(entityGroups).length === 1) {
      const entity = entityGroups[Object.keys(entityGroups)[0]][0]
      if (this.props.values['defaultAssignees'] !== entity.id) {
        this.props.updateField('createOrderForm', 'defaultAssignees', entity.id)
      }
      return <option key={entity.id} value={entity.id} selected={true}>{entity.name}</option>
    }
    const optionGroups = [<option key="000" value="">{__('Select One')}</option>]
    for (const entityGroup in entityGroups) {
      optionGroups.push(
        <optgroup key={entityGroup} label={entityGroup}>
          {entityGroups[entityGroup].map((entity) => <option key={entity.id} value={entity.id}>{entity.name}</option>)}
        </optgroup>
      )
    }
    return optionGroups
  }

  handleValidationOnChangeSteps = () => {
    if (this.props?.registeredFields) {
      Object.keys(this.props.registeredFields).forEach(key => this.props.blur(key));
    }
  }

  isNotFinalStep = (step) => {
    return [ORDER_OVERVIEW_STEP, ORDER_OVERRIDE_STEP, PROCESSES_OVERRIDE_STEP, REMOTE_INITIALIZE_STEP].includes(step);
  };


  render() {
    return (
      <>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} xxl={{ span: 16, offset: 4 }}>
            <div className="ant-card-body">
              <form>
                <Row>
                  <Col md={6} xs={0}>
                    <Steps current={this.state.currentStep} direction="vertical" style={{ flex: 1, height: PANEL_HEIGHT }}>
                      <StepStyled
                        status={this.state.currentStep === ORDER_OVERVIEW_STEP ? 'process' : this.state.stepsValidation[ORDER_OVERVIEW_STEP] ? 'done' : 'error'}
                        key={ORDER_OVERVIEW_STEP}
                        title={__('order information')}
                      />
                      <StepStyled
                        status={this.state.currentStep === REMOTE_INITIALIZE_STEP ? 'process' : !this.state.stepsValidation[REMOTE_INITIALIZE_STEP] ? 'error' : Object.keys(this.state.objectTypeRequiredAtInitialization).length < 1 ? 'wait' : 'done'}
                        key={REMOTE_INITIALIZE_STEP}
                        title={__('remote object selection')}
                      />
                      <StepStyled
                        status={this.state.currentStep === ORDER_OVERRIDE_STEP ? 'process' : this.state.stepsValidation[ORDER_OVERRIDE_STEP] ? 'done' : 'error'}
                        key={ORDER_OVERRIDE_STEP}
                        title={__('additional order details')}
                      />
                      <StepStyled
                        status={this.state.currentStep === PROCESSES_OVERRIDE_STEP ? 'process' : this.state.stepsValidation[PROCESSES_OVERRIDE_STEP] ? 'done' : 'error'}
                        key={ORDER_OVERRIDE_STEP}
                        title={__('additional process details')}
                      />
                      <StepStyled
                        status={this.state.currentStep === ORDER_CREATION_STEP ? 'process' : 'done'}
                        key={ORDER_CREATION_STEP}
                        title={__('summary')}
                      />
                    </Steps>
                  </Col>
                  <Col md={18} xs={24}>
                    {this.state.currentStep === ORDER_OVERVIEW_STEP
                      && <StepContentStyled>{this.computeOrderOverview()}</StepContentStyled>}
                    {this.state.currentStep === ORDER_OVERRIDE_STEP
                      && <StepContentStyled>{this.computeOrderOverride()}</StepContentStyled>}
                    {this.state.currentStep === PROCESSES_OVERRIDE_STEP
                      && <StepContentStyled>{this.computeProcessesOverride()}</StepContentStyled>}
                    {this.state.currentStep === REMOTE_INITIALIZE_STEP
                      && <StepContentStyled>{this.computeRemoteInitialize()}</StepContentStyled>}
                    {this.state.currentStep === ORDER_CREATION_STEP
                      && <StepContentStyled>{this.computeOrderCreationStep()}</StepContentStyled>}
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={24}>
                    <StepActionStyled>

                      <ButtonStyled className="btn btn-default pull-left"
                        onClick={() => hashHistory.goBack()}
                      ><Glyphicon glyph="remove" />
                        {__('Cancel')}
                      </ButtonStyled>
                      {/* Not the best way for managing step forms but the code was already there */}
                      <div>
                        <ButtonStyled
                          className="btn btn-default pull-left"
                          onClick={() => this.prevStep()}
                          disabled={this.state.currentStep === ORDER_OVERVIEW_STEP}
                        ><Glyphicon glyph="chevron-left" />
                          {__('Back')}
                        </ButtonStyled>
                        {this.isNotFinalStep(this.state.currentStep) && (
                          <ButtonStyled
                            className="btn btn-default pull-left"
                            type="primary"
                            onClick={() => this.nextStep()}
                            disabled={this.state.isLoading}
                          >
                            {__('Next')}
                            <Glyphicon glyph="chevron-right" />
                          </ButtonStyled>
                        )}
                      </div>
                      {this.isNotFinalStep(this.state.currentStep) ? (
                        <ButtonStyled className="btn btn-default pull-left" type="primary"
                          onClick={() => this.summaryStep()}
                          disabled={!((this.haveMandatoryObjects() && STEPS_PREV_NEXT_SORTED.indexOf(this.state.currentStep) > 0) || !this.haveMandatoryObjects())}
                        >
                          <Glyphicon glyph="ok" />
                          {__('Complete')}
                        </ButtonStyled>
                      ) : (
                        <ButtonStyled
                          className="btn btn-default pull-left"
                          type="primary"
                          onClick={this.handleSubmit}
                          disabled={this.state.isCreateButtonDisabled}
                        ><Glyphicon glyph="arrow-up" />
                          {this.state.isCreateButtonDisabled ? __('Sending') : __('Create')}
                        </ButtonStyled>
                      )}
                    </StepActionStyled>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
        <Loading fullHeight={'full-page'} loading={this.state.isLoading} />
      </>
    )
  }

  prevStep() {
    let currentStepIndex = STEPS_PREV_NEXT_SORTED.indexOf(this.state.currentStep)
    this.validateStep(STEPS_PREV_NEXT_SORTED[currentStepIndex])
    let nextIndex = --currentStepIndex % STEPS_PREV_NEXT_SORTED.length
    if (nextIndex === 1) {
      if (!this.haveMandatoryObjects()) {
        nextIndex--;
      }
    }
    if (nextIndex < 0) nextIndex = 0
    this.setState({ currentStep: STEPS_PREV_NEXT_SORTED[nextIndex] })
    this.setState({ isCreateButtonDisabled: false })
  }

  nextStep() {
    let currentStepIndex = STEPS_PREV_NEXT_SORTED.indexOf(this.state.currentStep)
    if (!this.validateStep(STEPS_PREV_NEXT_SORTED[currentStepIndex])) {
      this.handleValidationOnChangeSteps(); //trigger blur to allow fields to show error message
      if (this.state.currentStep === REMOTE_INITIALIZE_STEP)
	      this.setState({ showRemoteObjSectionErrors: true })
      return;
    }
    let nextIndex = ++currentStepIndex % STEPS_PREV_NEXT_SORTED.length
    if (nextIndex === 1) {
      if (!this.haveMandatoryObjects()) {
        nextIndex++;
      }
    }
    this.setState({ currentStep: STEPS_PREV_NEXT_SORTED[nextIndex], showRemoteObjSectionErrors: false })
  }

  summaryStep() {
    let currentStepIndex = STEPS_PREV_NEXT_SORTED.indexOf(this.state.currentStep)
    if (!this.validateStep(STEPS_PREV_NEXT_SORTED[currentStepIndex])) {
      this.handleValidationOnChangeSteps(); //trigger blur to allow fields to show error message
	    this.setState({ showRemoteObjSectionErrors: true })
      return;
    }
    let nextIndex = 4
    if (this.haveMandatoryObjects() && currentStepIndex !== 1) {
      nextIndex = 1;
    }
    this.setState({ currentStep: STEPS_PREV_NEXT_SORTED[nextIndex] })
  }


  handleOrderTypeChange(orderTypeId) {
    const { orderTypes } = this.props
    const orderType = orderTypes.find((ot) => ot.id === orderTypeId)
    if (!orderType || !orderType.flowId || !orderType.flowVersion) {
      return
    }

    /*
      Reset some form values when orderType changes
     */
    this.props.updateField('createOrderForm', 'processes', '');
    Object.entries(this.state.objectTypeRequiredAtInitialization).map(([objectTypeId, configuration]) => {
      this.props.updateField('createOrderForm', `objectTypeRequiredAtInitialization,${objectTypeId}`, '');
    });
    //---

    this.setState({ isLoading: true }, () => {
      api.get(`${getApiUrl()}/publishedFlows/${orderType.flowId}/${orderType.flowVersion}`).then((res) => {
        const { processList = [], objectTypeRequiredAtInitialization = {}, version, id } = res?.data || {}
        Object.entries(objectTypeRequiredAtInitialization).map(([objectTypeId, configuration]) => {
          this.props.updateField('createOrderForm', `objectTypeRequiredAtInitialization,${objectTypeId}`, null);
        });
        this.setState({
          isLoading: false,
          flow: { id, version },
          processList,
          objectTypeRequiredAtInitialization
        })
      })

    })
  }

  computeOrderOverride() {
    const { priorityValues } = global.constants

    return (
      <div>
        <MessageView
          type="deadline-lottie"
          title={__('additional order details')}
        />
        <Divider />
        <div>
          <Field
            name="priority"
            component={renderField}
            label={__('Priority')}
            className="form-control"
            items={priorityValues
              && Object.entries(priorityValues).map(([key, value]) => (
                <option
                  key={key}
                  value={value.name}
                >{value.name}
                </option>
              ))}
            type="select"
          />
          <Field
            name="deadlineFormula.endExpression"
            component={renderField}
            label={__('Order Deadline')}
            className="form-control datetime-in-ordercreation"
            type="DateTimeInput"
            style={{ overflow: 'initial' }}
          />
        </div>
      </div>
    )
  }

  computeProcessesOverride() {
    const setPanelOpen = (value) => {
      this.setState({ panelOpen: value })
    }
    return (
      <div>
        <MessageView
          type="deadline-lottie"
          title={__('additional process details')}
        />
        <Divider />
        <div>
          {
            (!this.state.processList || !this.state?.processList?.length) ? (
              <Empty description={<span>{__('There are no processes in the order')}</span>} />
            ) : (
              <CollapseWrapper>
                <Collapse
                  bordered={false}
                  activeKey={this.state.panelOpen}
                  destroyInactivePanel={false}
                >
                  {
                    this.state.processList.map((process) => {
                      const id = (process.id) ? process.id : process.title; //just for VCB and FTTH Postrollout because processes has not id but they use title as id
                      const deadline = (this.props.values?.processes && this.props.values?.processes[id]?.deadlineEndExpression) ? moment(this.props.values?.processes[id]?.deadlineEndExpression).format(appDefaults.dateTimeFormat) : null
                      const startExpression = (this.props.values?.processes && this.props.values?.processes[id]?.startExpression) ? moment(this.props.values?.processes[id]?.startExpression).format(appDefaults.dateTimeFormat) : null
                      const endExpression = (this.props.values?.processes && this.props.values?.processes[id]?.endExpression) ? moment(this.props.values?.processes[id]?.endExpression).format(appDefaults.dateTimeFormat) : null
                      const latitude = (this.props.values?.processes && this.props.values?.processes[id]?.location?.latitude) ? this.props.values?.processes[id]?.location?.latitude : null
                      const longitude = (this.props.values?.processes && this.props.values?.processes[id]?.location?.longitude) ? this.props.values?.processes[id]?.location?.longitude : null

                      let edited = deadline || startExpression || endExpression || latitude || longitude;

                      const subtitleComponent = (
                        <Descriptions
                          column={2}
                        >
                          {deadline && <Descriptions.Item
                            label={__('Deadline')}>{deadline}</Descriptions.Item>}
                          {startExpression && <Descriptions.Item
                            label={__('Not Before Date')}>{startExpression}</Descriptions.Item>}
                          {endExpression && <Descriptions.Item
                            label={__('Not After Date')}>{endExpression}</Descriptions.Item>}
                          {latitude && <Descriptions.Item
                            label={__('Latitude')}>{latitude}</Descriptions.Item>}
                          {longitude && <Descriptions.Item
                            label={__('Longitude')}>{longitude}</Descriptions.Item>}
                        </Descriptions>
                      );

                      return (
                        <AntPanel
                          key={id}
                          showArrow={false}
                          header={(
                            <PanelHeader
                              panelKey={id}
                              titleComponent={<Space>
                                <Title level={5}>{__(`${process.title}`)}</Title>
                                {
                                  (deadline || startExpression || endExpression || latitude || longitude) &&
                                  <Tag color="green">{__('edited')}</Tag>
                                }
                              </Space>}
                              panelOpen={this.state.panelOpen}
                              setPanelOpen={setPanelOpen}
                              subtitleComponent={
                                (edited && !this.state.panelOpen.includes(id)) ? subtitleComponent : null
                              }
                              expandText={__('Edit')}
                              collapseText={__('Close')}
                            />
                          )}
                        >
                          <CollapseWrapper>
                            <Collapse bordered={false} activeKey={this.state.panelOpen} destroyInactivePanel={false}>
                              <AntPanel key={id + 'deadline'} showArrow={false}
                                header={(
                                  <PanelHeader
                                    panelKey={id + 'deadline'}
                                    panelOpen={this.state.panelOpen}
                                    setPanelOpen={setPanelOpen}
                                    titleComponent={
                                      <Space style={{ marginTop: 10 }}>
                                        <Text strong>{__(`Deadline`)}</Text>
                                        <Text>{deadline}</Text>
                                        {
                                          (process.deadlineFormula && !deadline) &&
                                          <Tooltip title={__('Deadline defined in flow')}>
                                            <Tag color="blue">{__('Defined')}</Tag>
                                          </Tooltip>
                                        }
                                        {
                                          (process.deadlineFormula && deadline) &&
                                          <Tooltip title={__('Deadline defined in flow will be overridden')}>
                                            <Tag color="geekblue">{__('Overridden')}</Tag>
                                          </Tooltip>
                                        }
                                      </Space>}
                                    expandText={(!process.deadlineFormula) ? __('Edit') : __('Override deadline formula')}
                                    collapseText={__('Close')}
                                    buttonType={'link'}
                                  />
                                )}
                              >
                                <Field
                                  name={`processes.${id}.deadlineEndExpression`}
                                  component={renderField}
                                  label={__('Deadline')}
                                  className="form-control"
                                  type="DateTimeInput"
                                />
                              </AntPanel>
                            </Collapse>
                          </CollapseWrapper>
                          <CollapseWrapper>
                            <Collapse bordered={false} activeKey={this.state.panelOpen} destroyInactivePanel={false}>
                              <AntPanel key={id + 'betweenDates'} showArrow={false}
                                header={(
                                  <PanelHeader
                                    panelKey={id + 'betweenDates'}
                                    panelOpen={this.state.panelOpen}
                                    setPanelOpen={setPanelOpen}
                                    titleComponent={
                                      <Space style={{ marginTop: 10 }}>
                                        <Text strong>{__(`Between Dates `)}</Text>
                                        <Text>{startExpression}</Text>
                                        <Text>{' '}</Text>
                                        <Text>{endExpression}</Text>
                                      </Space>
                                    }
                                    expandText={__('Edit')}
                                    collapseText={__('Close')}
                                    buttonType={'link'}
                                  />
                                )}
                              >
                                <Field
                                  name={`processes.${id}.startExpression`}
                                  component={renderField}
                                  label={__('Not Before Date')}
                                  className="form-control"
                                  type="DateTimeInput"
                                />
                                <Field
                                  name={`processes.${id}.endExpression`}
                                  component={renderField}
                                  label={__('Not After Date')}
                                  className="form-control"
                                  type="DateTimeInput"
                                />
                              </AntPanel>
                            </Collapse>
                          </CollapseWrapper>
                          <CollapseWrapper>
                            <Collapse bordered={false} activeKey={this.state.panelOpen} destroyInactivePanel={false}>
                              <AntPanel key={id + 'location'} showArrow={false}
                                header={(
                                  <PanelHeader
                                    panelKey={id + 'location'}
                                    panelOpen={this.state.panelOpen}
                                    setPanelOpen={setPanelOpen}
                                    titleComponent={
                                      <Space style={{ marginTop: 10 }}>
                                        <Text strong>{__(`Location`)}</Text>
                                        {latitude && <Text>{__('Latitude')}:</Text>}
                                        <Text>{latitude}</Text>
                                        {
                                          (longitude) && <Text>/</Text>
                                        }
                                        {longitude && <Text>{__('Longitude')}:</Text>}
                                        <Text>{longitude}</Text>
                                      </Space>
                                    }
                                    expandText={__('Edit')}
                                    collapseText={__('Close')}
                                    buttonType={'link'}
                                  />
                                )}
                              >
                                <Field
                                  name={`processes.${id}.location.latitude`}
                                  component={renderField}
                                  label={`${__('Latitude')}`}
                                  className="form-control"
                                  type="text"
                                />
                                <Field
                                  name={`processes.${id}.location.longitude`}
                                  component={renderField}
                                  label={`${__('Longitude')}`}
                                  className="form-control"
                                  type="text"
                                />
                              </AntPanel>
                            </Collapse>
                          </CollapseWrapper>
                        </AntPanel>
                      )
                    })
                  }

                </Collapse>
              </CollapseWrapper>
            )
          }
        </div>
      </div>
    )
  }

  computeRemoteInitialize() {
    const { values, formSyncErrors } = this.props
	  const { objectTypeRequiredAtInitialization, flow, showRemoteObjSectionErrors, attributesTree, attributesList } = this.state

    const MissingConfigurationMessage = () => (
      <NoData
        title={__("You have not configured any views for this object")}
        subTitle={<Link to="/remoteObjects/create">{__('create')}</Link>}
        size={'large'}
        centered
        subTitleWrap={{
          before: __('To see results here'),
          after: __('a Remote Object Configuration or talk with your admin.')
        }}
        image={false}
      />
    )



    const objectSelectorApiProps = { flowId: flow?.id, flowVersion: flow?.version }
    return (
      <div>
        <MessageView
          type="initialization-lottie"
          title={__('remote object selection')}
        />
        <Divider />
        {
          (!objectTypeRequiredAtInitialization || !Object.keys(objectTypeRequiredAtInitialization).length || !flow?.id || !flow?.version) ? (
            <Empty
              description={
                <span>{__('the selected order type does not require you to select any remote object')}</span>
              }
            />
          )
            : (
              <div>
                {
                  Object.entries(objectTypeRequiredAtInitialization).map(([objectTypeId, configuration]) => {
                    const { systemId } = configuration

                    const objectTypeName = attributesTree?.[systemId]?.find(( { objectTypeId: id } ) => id.toString() === objectTypeId)?.label

                    const props = {
                      label: objectTypeName ? <Title level={4}>{`ObjectType Name: ${objectTypeName}*`}</Title> : '',
                      missingConfigurationMessage: <MissingConfigurationMessage />,
                      api: {
                        createItem: (payload) => createFlowRemoteItem({ ...objectSelectorApiProps, objectTypeId, payload }),
                        editItem: ({ mainKey, payload }) => editFlowRemoteItem({ ...objectSelectorApiProps, objectTypeId, payload, mainKey }),
                        fetchTableData: (payload, ids) => fetchFlowRemoteTableData({ ...objectSelectorApiProps, objectTypeId, ids, payload }),
                        fetchAttributesList: async () => (attributesList),
                        fetchAttributesTree: async () => (attributesTree),
                        fetchEnumValuesByIds: fetchEnumValuesByIds,
                        fetchRemoteObject: (mainKey) => fetchFlowRemoteItem({ ...objectSelectorApiProps, objectTypeId, mainKey }),
                      }
                    }

                    const error = showRemoteObjSectionErrors && formSyncErrors[`objectTypeRequiredAtInitialization,${objectTypeId}`]

                    return (
                      <div style={{ padding: "10px", ...(error ? { border: '1px solid #a000a0', borderRadius: '8px' } : {})  } }>
                        <ObjectSelector
                          onChange={(value) => {
                            this.props.updateField('createOrderForm', `objectTypeRequiredAtInitialization,${objectTypeId}`, value)
                            this.setState({ showRemoteObjSectionErrors: false })
                          }}
                          value={{
                            selectedItems: values[`objectTypeRequiredAtInitialization,${objectTypeId}`]?.selectedItems || [],
                            objectConfiguration: {
                              ...configuration,
                              selectionMode: 'SINGLE',
                              editableEntries: true,
                              addableEntries: true,
                              removableEntries: true,
                            }
                          }}
                          key={`objectTypeRequiredAtInitialization,${objectTypeId}`}
                          {...props}
                        />

                        {
	                        error && <span style={{ "margin": 1 }} className="text-danger">{formSyncErrors[`objectTypeRequiredAtInitialization,${objectTypeId}`]}</span>
                        }
                      </div>
                    )
                  })
                }
              </div>
            )
        }
      </div>
    )
  }

  computeOrderCreationStep() {
    const { values, orderTypes, canCreate } = this.props
    if (!canCreate) return null

    if (Object.values(this.state.stepsValidation).some((sV) => sV === false)) {
      return (
        <MessageView
          type="error"
          title={__('Order cannot be created')}
          subtitle={__('Not all the steps are completed, please go back and fill all the mandatory fields')}
        />
      )
    }

    return (
      <Space direction="vertical">
        <MessageView
          type="create-lottie"
          title={__('Order can be created')}
          subtitle={__('You will be redirected to the homepage once done')}
          showImage={true}
        />
        <Divider />
        <div style={{ margin: 8, padding: 8, backgroundColor: 'white' }}>
          <Divider>{__('Order Information')}</Divider>
          <Row gutter={[16, 32]} justify="space-around" align="middle">
            <Col offset={4} span={6}>
              <Statistic title={__('Title')} value={(values.title) ? values.title : __('Not set')} />
            </Col>
            <Col offset={4} span={6}>
              <Statistic title={__('Order Type')}
                value={orderTypes.filter((ot) => (ot.id === values.orderTypeId)).map(ot => ot?.name)} />
            </Col>
            <Col span={4}>
            </Col>
          </Row>
          <Row gutter={[16, 32]} justify="space-around" align="middle">
            <Col offset={4} span={6}>
              <Statistic title={__('Assign To Me')} value={(values.assignToMe ? __('Yes') : __('No'))} />
            </Col>
            <Col offset={4} span={6}>
              {
                (values.setExternalId) && <Statistic title={__('External Order Id')} value={values.externalOrderId} />
              }
            </Col>
            <Col span={4}>
            </Col>
          </Row>
          <Divider>{__('Additional Order Details')}</Divider>
          <Row gutter={[16, 32]} justify="space-around" align="middle">
            <Col offset={4} span={6}>
              <Statistic title={__('Priority')} value={values.priority} />
            </Col>
            <Col offset={4} span={6}>
              <Statistic title={__('Order Deadline')}
                value={(values.deadlineFormula) ? moment(values.deadlineFormula).format(appDefaults.dateTimeFormat) : __('No deadline')} />
            </Col>
            <Col span={4}>
            </Col>
          </Row>
          {
            (values?.processes) &&
            <div>
              <Divider>{__('Additional Process Details')}</Divider>
              {
                (Object.keys(values?.processes).map(key => (
                  <Row gutter={[16, 32]}>
                    <Col offset={4} span={20}>
                      <Descriptions
                        title={this.state.processList.filter(process => process.id === key).map(process => __(`${process.title}`))}
                        column={2}
                      >
                        {values?.processes[key]?.deadlineEndExpression && <Descriptions.Item
                          label={__('Deadline')}>{moment(values?.processes[key]?.deadlineEndExpression).format(appDefaults.dateTimeFormat)}</Descriptions.Item>}
                        {values?.processes[key]?.startExpression && <Descriptions.Item
                          label={__('Not Before Date')}>{moment(values?.processes[key]?.startExpression).format(appDefaults.dateTimeFormat)}</Descriptions.Item>}
                        {values?.processes[key]?.endExpression && <Descriptions.Item
                          label={__('Not After Date')}>{moment(values?.processes[key]?.endExpression).format(appDefaults.dateTimeFormat)}</Descriptions.Item>}
                        {values?.processes[key]?.location?.latitude && <Descriptions.Item
                          label={__('Latitude')}>{values?.processes[key]?.location?.latitude}</Descriptions.Item>}
                        {values?.processes[key]?.location?.longitude && <Descriptions.Item
                          label={__('Longitude')}>{values?.processes[key]?.location?.longitude}</Descriptions.Item>}
                      </Descriptions>
                    </Col>
                  </Row>
                )))
              }
            </div>
          }
        </div>
      </Space>
    )
  }

  computeOrderOverview() {
    const { orderTypes } = this.props
    const { assignToMe, setExternalId } = this.state

    return (
      <div>
        <MessageView
          type="overview-lottie"
          title={__('order information')}
        />
        <Divider />
        <Field
          name="title"
          required={false}
          component={renderField}
          label={__('Title')}
          className="form-control"
          type="text"
        />

        <Field
          name="orderTypeId"
          component={renderField}
          label={`${__('Order Type')}*`}
          className="form-control"
          items={orderTypes && [
            <option key="select-one" value="">{__('Select one')}</option>,
            orderTypes
              .filter((ot) => ot.creatableByUser)
              .map((ot) => <option key={ot.id} value={ot.id}>{ot.name}</option>)
          ]}
          type="select"
          onChange={(HtmlElement) => this.handleOrderTypeChange(HtmlElement.target.value)}
        />
        <Field
          name="setExternalId"
          component={renderField}
          label={__('set external id')}
          className="form-control"
          type="checkbox"
          onChange={(e) => {
            this.setState({ setExternalId: e.target.checked })
          }}
        />
        {setExternalId ? (
          <Field
            name="externalOrderId"
            component={renderField}
            label={__('external order id') + "*"}
            className="form-control"
            type="text"
          />
        ) : null}
        <Field
          name="assignToMe"
          component={renderField}
          label={__('assign to me')}
          className="form-control"
          type="checkbox"
          onChange={(e) => {
            this.setState({ assignToMe: e.target.checked })
          }}
        />
        {assignToMe ? (
          <Field
            name="defaultAssignees"
            component={renderField}
            label={`${__('Company / User Group')}*`}
            className="form-control"
            type="select"
            items={this.renderEntities()}
          />
        ) : null}
      </div>
    )
  }

  fullFillStepsValidation() {
    const stepsValidation = {}
    stepsValidation[ORDER_OVERVIEW_STEP] = this.validateStep(ORDER_OVERVIEW_STEP)
    stepsValidation[ORDER_OVERRIDE_STEP] = this.validateStep(ORDER_OVERRIDE_STEP)
    stepsValidation[PROCESSES_OVERRIDE_STEP] = this.validateStep(PROCESSES_OVERRIDE_STEP)
    stepsValidation[REMOTE_INITIALIZE_STEP] = this.validateStep(REMOTE_INITIALIZE_STEP)
    stepsValidation[ORDER_CREATION_STEP] = true
    this.setState({ stepsValidation })
  }

  validateStep(currentStep) {
    const { values } = this.props
    const { objectTypeRequiredAtInitialization } = this.state

    switch (currentStep) {
      case ORDER_OVERVIEW_STEP:
        return (values.orderTypeId != null && (!values.assignToMe || values.defaultAssignees) && (!values.setExternalId || values.externalOrderId))
      case ORDER_OVERRIDE_STEP:
        return true
      case PROCESSES_OVERRIDE_STEP:
        return true
      case REMOTE_INITIALIZE_STEP:

        const keyword = 'objectTypeRequiredAtInitialization,'
        const objectTypesFullFilled = Object.entries(values).filter(([keyField, valueField]) => keyField.startsWith(keyword) && valueField?.selectedItems.length)
        return Object.keys(objectTypeRequiredAtInitialization).length === objectTypesFullFilled.length
      default:
        return false
    }
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

const getFormState = state => state.form;
const getNamedFormState = formName => createSelector([getFormState], (formState = {}) => formState[formName]);
const registeredFieldsSelector = formName => createSelector([getNamedFormState(formName)], (namedFormState = {}) => {
  return namedFormState.registeredFields
});

const getRegisteredFields = registeredFieldsSelector('createOrderForm');

const CreateOrderFormConnected = connect(
  (state) => ({
    orderTypes: state.user.orderTypes,
    values: getFormValues('createOrderForm')(state),
	  formSyncErrors: getFormSyncErrors('createOrderForm')(state),
    registeredFields: getRegisteredFields(state) //only mounted fields
  }),
  (dispatch) => ({
    change,
    reset,
    updateField: (form, field, newValue) => dispatch(change(form, field, newValue)),
    dispatch,
    dispatchUserOrderTypes,
    getFormValues,
  })
)(CreateOrderForm)

export default reduxForm({
  form: 'createOrderForm', // a unique identifier for this form
  validate,
}, mapDispatchToProps)(CreateOrderFormConnected)
