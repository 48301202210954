import React, { useState, useEffect, useMemo } from 'react'
import { Panel } from 'react-bootstrap'
import Glyphicon from '@strongdm/glyphicon'
import moment from 'moment'
import styled from 'styled-components'
import { Modal as AntdModal, Spin, message, Space, Divider } from 'antd'

import { api } from '../providers/ApiProvider'
import { hashHistory } from '../providers/HistoryProvider'
import * as c from '../utils/calendarEvents'

import CalendarStandardVersion from '../components/Calendar/CalendarStandardVersion'
import CalendarEventForm from '../components/Calendar/CalendarEventForm'
import CalendarWorkingTable from '../components/Calendar/CalendarWorkingTable'
import { __ } from '../utils/translationUtils'
import * as S from "../components/Calendar/CalendarStandardVersion/styles";

const { WORKING_EVENT, NON_WORKING_EVENT, GODOO_EVENT } = global.constants.eventTypes

export default ({ match }) => {
  const [isLoading, setLoading] = useState(null)
  const [user, setUser] = useState(null)
  const [userEntities, setUserEntities] = useState(null)
  const userId = match?.params?.userId

  const [isSubmitting, setSubmit] = useState(null)
  const [calendarView, setCalendarView] = useState('week')
  const [selectedDate, setSelectedDate] = useState(moment())
  const [eventTypeCodes, setEventTypeCodes] = useState(null)
  const [events, setEvents] = useState(null)
  const [attributes, setAttributes] = useState(null)
  const [selectedSlot, selectSlot] = useState()
  const [editEvent, setEditEvent] = useState()
  const [entities, setEntities] = useState([])
  const { startDate, endDate } = c.getCalendarVisibleDateRange({ selectedDate, calendarView })
  function calculateDateInTheMiddleOfARange(range) {
    // week or day
    if (Array.isArray(range)) {
      const middleDateIndex = Math.floor(range.length / 2)
      setSelectedDate(range[middleDateIndex])
      return
    }

    // month or agenda
    setSelectedDate(moment(range.start).add(15, 'day'))
  }

  useEffect(() => {
    async function fetchUserEvents() {
      setLoading(true)
      if (!attributes) await api.get('configurations/attributes').then((data) => setAttributes(data.data))
      await c.getCalendarEvents({ user, userEntities, startDate, endDate, selectedEventTypes: [WORKING_EVENT, NON_WORKING_EVENT, GODOO_EVENT] }).then((e) => setEvents(e))
      setLoading(false)
    }
    if (selectedDate && calendarView && userEntities) {
      fetchUserEvents()
    }
  }, [attributes, calendarView, selectedDate, startDate, endDate, user, userEntities])

  function handleUnselectSlot() {
    selectSlot(null)
  }
  function handleUnselectEdit() {
    setEditEvent(null)
  }

  async function handleEventDelete(event) {
    const deletedCalendarEvent = await c.deleteCalendarEvent(event)
    if (deletedCalendarEvent) {
      message.success('Event deleted!')
      setEvents((prevEvents) => prevEvents.filter((e) => e.id !== deletedCalendarEvent))
    }
  }

  async function handleEditEvent(formValues) {
    const { entityId, eventType, nonWorkingTypeCode } = formValues
    const entity = user.belongsTo.find((e) => e.id === entityId)
    const { id } = editEvent
    setSubmit(true)
    const editedEvent = await c.editCalendarEvent({ ...formValues, id })
    setSubmit(false)
    if (editedEvent) {
      message.success(`${eventType} edited!`)
      setEvents((prevEvents) => {
        const eventsWithoutTheCurrentlyEdited = prevEvents.filter((event) => event.id !== editedEvent.id)
        return [...eventsWithoutTheCurrentlyEdited, { ...editedEvent, nonWorkingTypeCode, entity, eventType, entityId }]
      })
      setEditEvent(null)
    }
  }

  async function handleCreateEvent(formValues) {
    const { entitiesId, eventType, nonWorkingTypeCode } = formValues

    entitiesId.forEach((entityId) => {
      const entity = user.belongsTo.find((e) => e.id === entityId)
      setSubmit(true)
      c.createCalendarEvent({ ...formValues, userId: user.id, entityId })
        .then((createdEvent) => {
          message.success(`${eventType} created!`)
          setEvents((prevEvents) => [...prevEvents, { ...createdEvent, nonWorkingTypeCode, eventType, entity, entityId }])
          selectSlot(null)
        })
      setSubmit(false)
    })
  }
  useEffect(() => {
    async function fetchInitialData() {
      setLoading(true)
      const entities = await api.get('/entities?pageSize=200&status=enabled,reserved').then((data) => data.data)
      await api.get(`users/${userId}`).then((data) => {
        setUser(data.data)
        setUserEntities(data.data.belongsTo)
      })
      setEntities(entities)

      // const evenTypeCodesFromApi = await api.get('${config.prefix()?.CALENDAR || ''}/nonWorkingPeriods/types?reservedFor=user').then((data) => data.data).catch((e) => console.log(JSON.stringify(e)))
      setEventTypeCodes(global.constants.eventTypeCodes.user)

      setLoading(false)
    }

    fetchInitialData()
  }, [userId, setLoading, setUser, setUserEntities])

  const workingEvents = useMemo(() => events
    ?.filter((e) => e?.eventType === WORKING_EVENT)
    ?.map((event) => ({
      ...event,
      ...c.produceWorkingDayEvent(event)
    })), [events])

  return (
    <div id="main-content">
      <h1 className="sticky">
        <div className="container">
          { __('Calendar')}
        </div>
      </h1>
      <div className="container">

        <Panel>
          <Panel.Heading>{user?.status === 'deleted' ? __('deleted_user') : user?.firstName} { __('Calendar')}</Panel.Heading>
          <Panel.Body>
            <CalendarWrapper>
              {(events && attributes?.length > 0) && (
                // NB: Uncontrolled component Calendar!
                <Space size="large" direction="vertical" style={{ width: '100%' }}>
                  <CalendarStandardVersion
                    attributes={attributes}
                    userEntities={userEntities}
                    user={user}
                    events={events}
                    onRangeChange={calculateDateInTheMiddleOfARange}
                    onView={setCalendarView}
                    view={calendarView}
                    selectable
                    editable
                    onSelectSlot={selectSlot}
                    onDelete={handleEventDelete}
                    onEdit={setEditEvent}
                    entities={entities}
                  />
                  <Divider orientation="left">Working periods</Divider>
                  <CalendarWorkingTable
                    events={workingEvents}
                    onDelete={handleEventDelete}
                    onEdit={setEditEvent}
                    onAdd={selectSlot}
                  />
                </Space>
              )}
              {selectedSlot && (
                <AntdModal
                  title={`Event creation: ${user.firstName} ${user.lastName}`}
                  visible
                  footer={null}
                  onOk={handleUnselectSlot}
                  onCancel={handleUnselectSlot}
                  afterClose={document.body.removeAttribute('style')} // for bug described here: https://github.com/ant-design/ant-design/issues/21539
                >
                  <CalendarEventForm
                    eventTypeCodes={eventTypeCodes}
                    initialValues={{
                      dateRange: [selectedSlot.startDate, selectedSlot.endDate],
                      eventType: selectedSlot.eventType || NON_WORKING_EVENT,
                      dayOfTheWeekCode: selectedSlot.dayOfTheWeekCode
                    }}
                    user={user}
                    isSubmitting={isSubmitting}
                    onSubmit={handleCreateEvent}
                  />
                </AntdModal>
              )}
              {editEvent && (
                <AntdModal
                  title={editEvent.title ? `Editing the event ${editEvent.title}` : 'Editing event'}
                  visible
                  footer={null}
                  onOk={handleUnselectEdit}
                  onCancel={handleUnselectEdit}
                  afterClose={document.body.removeAttribute('style')} // for bug described here: https://github.com/ant-design/ant-design/issues/21539
                >
                  <CalendarEventForm
                    eventTypeCodes={eventTypeCodes}
                    initialValues={{
                      ...editEvent,
                      dateRange: [moment(editEvent.start), moment(editEvent.end)],
                      startingTime: moment(editEvent.start),
                      endingTime: moment(editEvent.end)
                    }}
                    user={user}
                    mode="edit"
                    isSubmitting={isSubmitting}
                    onSubmit={handleEditEvent}
                  />
                </AntdModal>
              )}

              {isLoading && (
                <SpinWrapper>
                  <Spin size="large" />
                </SpinWrapper>
              )}
            </CalendarWrapper>
          </Panel.Body>
          <Panel.Footer>
            <div className="group">
              <button className="btn btn-default pull-left" onClick={() => { hashHistory.goBack() }}>
                <Glyphicon glyph="chevron-left" /> {__('Back')}
              </button>
            </div>
          </Panel.Footer>
        </Panel>
      </div>
    </div>
  )
}

const CalendarWrapper = styled.div`
  position: relative;
  min-height: 50vh;
`
const SpinWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: .5;
  z-index: 111;

`
