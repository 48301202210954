/* eslint-disable no-console */
import Moment from 'moment'
import { api } from '../providers/ApiProvider'
import * as config from '../constants/globalConfiguration'
import { onlyUnique } from "./misc";
import { __ } from './translationUtils'

const STARTING_DATETIME = '100_58be96d00e823f552aa1a038'
const ENDING_DATETIME = '100_58be96d00e823f552aa1a040'
const ORDER_TYPE = '100_58be96d00e823f552aa1a009'
const ONLY_ACTIVE_APPOINTMENTS = '?internalOrderStatuses=ASSIGNED,STARTED&internalProcessStatuses=ASSIGNED,STARTED,POSTPONED,REJECTED'

// AGENDA:
// user -> understand if the working or non working event is personal or public
// start, end -> calendar
// start_time, end_time -> timeline
// starting*, ending* -> apis related

// it returns the visible date range as an object
export function getCalendarVisibleDateRange({ selectedDate, calendarView }) {
  if (calendarView === 'agenda') {
    return {
      startDate: Moment(selectedDate).subtract(15, 'day').toISOString(), // UTC (Greenwich)
      endDate: Moment(selectedDate).add(15, 'day').toISOString() // UTC (Greenwich)
    }
  }

  if (calendarView === 'week') {
    return {
      startDate: Moment(selectedDate).startOf('isoWeek').toISOString(), // UTC (Greenwich)
      endDate: Moment(selectedDate).endOf('isoWeek').toISOString() // UTC (Greenwich)
    }
  }

  return {
    startDate: Moment(selectedDate).startOf(calendarView).toISOString(), // UTC (Greenwich)
    endDate: Moment(selectedDate).endOf(calendarView).toISOString() // UTC (Greenwich)
  }
}

export const makeNonWorkingEvent = ({ dateRange, nonWorkingTypeCode, ...props }) => ({
  ...props,
  nonWorkingTypeCode,
  startingDateTime: Moment(dateRange[0]).toISOString(), // UTC (Greenwich)
  endingDateTime: Moment(dateRange[1]).toISOString() // UTC (Greenwich)
})

export const produceWorkingDayEvent = ({ selectedDate, dayOfTheWeekCode, startHour, startMinute, endHour, endMinute }) => {

  const start = Moment(selectedDate).day(dayOfTheWeekCode + 1).hour(startHour).minute(startMinute).second(0) // GMT: (locale)
  const end = Moment(selectedDate).day(dayOfTheWeekCode + 1).hour(endHour).minute(endMinute).second(0) // GMT: (locale)
  return {
    start,
    end,
    start_time: start,
    end_time: end
  }
}

export const transformNonWorkingEvent = ({ startingDateTime, endingDateTime, nonWorkingType, ...props }) => ({
  ...props,
  nonWorkingTypeCode: nonWorkingType?.code,
  eventType: global.constants.eventTypes.NON_WORKING_EVENT,
  start: new Date(startingDateTime), // GMT: (locale)
  end: new Date(endingDateTime), // GMT: (locale)
  start_time: Moment(startingDateTime), // GMT: (locale)
  end_time: Moment(endingDateTime) // GMT: (locale)
})

export const transformGodooEvent = ({ startingDateTime, endingDateTime, ...props }) => ({
  ...props,
  eventType: global.constants.eventTypes.GODOO_EVENT,
  start: new Date(startingDateTime), // GMT: (locale)
  end: new Date(endingDateTime), // GMT: (locale)
  start_time: Moment(startingDateTime), // GMT: (locale)
  end_time: Moment(endingDateTime) // GMT: (locale)
})

function pad(str, max) {
  str = str.toString()
  return str.length < max ? pad("0" + str, max) : str
}

const makeWorkingEvent = ({startingTime, endingTime, dayOfTheWeekCode}) => {
  return {
    startingTime: Moment(startingTime).utc().format('HH:mm:ss'), // UTC (Greenwich)
    endingTime: Moment(endingTime).utc().format('HH:mm:ss'), // UTC (Greenwich)
    dayOfTheWeekCode
  }
}

export const transformWorkingEvent = ({ startingTime, endingTime, ...props }) => {
  const [startHour, startMinute] = startingTime.split(':') // UTC (Greenwich)
  const [endHour, endMinute] = endingTime.split(':') // UTC (Greenwich)

  const originalStart = Moment.utc({ hour: startHour, minute: startMinute }).local() // GMT: (locale)
  const originalEnd = Moment.utc({ hour: endHour, minute: endMinute }).local() // GMT: (locale)

  return {
    ...props,
    eventType: global.constants.eventTypes.WORKING_EVENT,
    startHour: pad(originalStart.hours(), 2), // GMT: (locale)
    startMinute: pad(originalStart.minutes(), 2), // GMT: (locale)
    endHour: pad(originalEnd.hours(), 2), // GMT: (locale)
    endMinute: pad(originalEnd.minutes(), 2) // GMT: (locale)
  }
}

export function editCalendarEvent({ eventType, id, dateRange, title, nonWorkingTypeCode, description, dayOfTheWeekCode, startingTime, endingTime }) {
  if (eventType === global.constants.eventTypes.WORKING_EVENT) {
    return api.patch(`${config.prefix()?.CALENDAR || ''}/workingDays/${id}`, makeWorkingEvent({dayOfTheWeekCode, startingTime, endingTime}), { resolveWithFullResponse: true, data: null })
      .then((data) => transformWorkingEvent(data.data))
      .catch((e) => console.log(e))
  }
  if (eventType === global.constants.eventTypes.NON_WORKING_EVENT) {
    return api.patch(`${config.prefix()?.CALENDAR || ''}/nonWorkingPeriods/${id}`, makeNonWorkingEvent({ dateRange, title, nonWorkingTypeCode, description }), { resolveWithFullResponse: true, data: null })
      .then((data) => transformNonWorkingEvent(data.data)) // ✅ fuffoo non mi ritorna nonWorkingTypeCode appena creato
      .catch((e) => console.log(e))
  }
  return null
}

// ✅ sfruttare array di working days in fase di creazione
export function createCalendarEvent({ eventType, userId, entityId, dateRange, title, nonWorkingTypeCode, description, dayOfTheWeekCode, startingTime, endingTime }) {

  if (eventType === global.constants.eventTypes.GODOO_EVENT) {
    // do something with the data

    return transformGodooEvent({
      startingDateTime: dateRange[0],
      endingDateTime: dateRange[1],
      entityId
    })
  }
  if (eventType === global.constants.eventTypes.WORKING_EVENT) {
    const url = userId ? `${config.prefix()?.CALENDAR || ''}/calendars/personal/working/${userId}/${entityId}` : `${config.prefix()?.CALENDAR || ''}/calendars/public/working/${entityId}`
    return api
      .post(url, { workingDays: [makeWorkingEvent({dayOfTheWeekCode, startingTime, endingTime})] })
      .then((data) => transformWorkingEvent(data?.data?.[0]))
      .catch((e) => console.log(e))
  }

  if (eventType === global.constants.eventTypes.NON_WORKING_EVENT) {
    const url = userId ? `${config.prefix()?.CALENDAR || ''}/calendars/personal/nonWorking/${userId}/${entityId}` : `${config.prefix()?.CALENDAR || ''}/calendars/public/nonWorking/${entityId}`
    return api
      .post(url, { nonWorkingPeriods: [makeNonWorkingEvent({ dateRange, title, nonWorkingTypeCode, description })] })
      .then((data) => transformNonWorkingEvent(data?.data?.[0]))
      .catch((e) => console.log(e))
  }
  return null
}

export function deleteCalendarEvent({ id, eventType }) {
  if (eventType === global.constants.eventTypes.WORKING_EVENT) {
    return api.delete(`${config.prefix()?.CALENDAR || ''}/workingDays/${id}`, { resolveWithFullResponse: true, data: null })
      .then(() => id)
      .catch((e) => { console.log(e) })
  }
  if (eventType === global.constants.eventTypes.NON_WORKING_EVENT) {
    return api.delete(`${config.prefix()?.CALENDAR || ''}/nonWorkingPeriods/${id}`, { resolveWithFullResponse: true, data: null })
      .then(() => id)
      .catch((e) => { console.log(e) })
  }
  return null
}


// this function is needed in order to gather all the data needed by the Planner + Calendar
export async function getCalendarEvents({ user, userEntities, startDate, endDate, selectedEventTypes }) {
  // di ciascun utente prendo tutti gli ordini che ha associato
  let godooEvents = []
  if (selectedEventTypes?.includes(global.constants.eventTypes.GODOO_EVENT) && user) {
    godooEvents = await api
      .get(`processes${ONLY_ACTIVE_APPOINTMENTS}&userIds=${user.id}&minAppointmentDatetime=${startDate}&maxAppointmentDatetime=${endDate}&sortString=${STARTING_DATETIME}&pageSize=1000&onlySelfOrders=false&additionalDisplayAttributes=100_58be96d00e823f552aa1a070,100_58be96d00e823f552aa1a071,${STARTING_DATETIME},${ENDING_DATETIME},${ORDER_TYPE}`)
      .then((data) => data?.data?.reduce((acc, val) => {
        const { processOverview, additionalDisplayData, orderOverview } = val

        const events = [processOverview]

        return [
          ...acc,
          ...events.map((event) => {

            const start = new Date(additionalDisplayData?.[STARTING_DATETIME]?.find(({ parentObjectId }) => parentObjectId === event.id)?.value)
            const end = new Date(additionalDisplayData?.[ENDING_DATETIME]?.find(({ parentObjectId }) => parentObjectId === event.id)?.value)

            return {
              ...event,
              eventType: global.constants.eventTypes.GODOO_EVENT,
              orderData: val,
              title: __(orderOverview.title),
              start,
              start_time: Moment(start),
              end,
              end_time: Moment(end),
              backgroundColor: orderOverview?.orderType?.color
            }
          })
        ]
      }, []))
      .catch((e) => console.log(e)) || []
  }

  // per ciascuna sua entity prendo i relativi eventi non working (Luigi api's)
  let nonWorkingEvents = []
  if (selectedEventTypes.includes(global.constants.eventTypes.NON_WORKING_EVENT)) {
    nonWorkingEvents = await Promise.all(userEntities?.map((entity) => api.get(user ? `${config.prefix()?.CALENDAR || ''}/calendars/personal/nonWorking/${user.id}/${entity.id}?union=true&startingDateTime=${startDate}&endingDateTime=${endDate}` : `${config.prefix()?.CALENDAR || ''}/calendars/public/nonWorking/${entity.id}?startingDateTime=${startDate}&endingDateTime=${endDate}`, { data: null }).catch((e) => console.log(e))
      .then((data) => data?.data.filter((e) => e).map((event) => ({
        ...event,
        entity,
        ...transformNonWorkingEvent(event.nonWorkingPeriod)
      })))
      .catch((e) => console.log(e)))) || []
  }

  // per ciascuna sua entity prendo i relativi eventi (Luigi api's)
  let workingEvents = []
  if (selectedEventTypes.includes(global.constants.eventTypes.WORKING_EVENT)) {
    workingEvents = await Promise.all(userEntities?.map((entity) => api.get(user ? `${config.prefix()?.CALENDAR || ''}/calendars/personal/working/${user.id}/${entity.id}?fallback=true` : `${config.prefix()?.CALENDAR || ''}/calendars/public/working/${entity.id}`, { data: null }).catch((e) => console.log(e))
      .then((data) => data?.data.filter((e) => e).map((event) => ({
        ...event,
        entity,
        ...transformWorkingEvent(event.workingDay)
      })))
      .catch((e) => console.log(e)))) || []
  }
  return [...nonWorkingEvents, ...godooEvents, ...workingEvents].flat()
}

// this function is needed in order to retrieve the full list of calendars data in one single shot
export async function getCalendarEventList({ items, startDate, endDate, selectedEventTypes }) {

  const users = items.map(e => e.userId).filter(onlyUnique).join(",")
  
  const godooEvents = selectedEventTypes?.includes(global.constants.eventTypes.GODOO_EVENT)
    ?  await api.get(`processes${ONLY_ACTIVE_APPOINTMENTS}&userIds=${users}&minAppointmentDatetime=${startDate}&maxAppointmentDatetime=${endDate}&sortString=${STARTING_DATETIME}&pageSize=1000&onlySelfOrders=false&additionalDisplayAttributes=100_58be96d00e823f552aa1a070,100_58be96d00e823f552aa1a071,${STARTING_DATETIME},${ENDING_DATETIME},${ORDER_TYPE}`)
    .then((data) => data?.data?.map((appointment) => {
      const { processOverview, additionalDisplayData, orderOverview } = appointment
  
      const start = new Date(additionalDisplayData?.[STARTING_DATETIME]?.find(({ parentObjectId }) => parentObjectId === processOverview.id)?.value)
      const end = new Date(additionalDisplayData?.[ENDING_DATETIME]?.find(({ parentObjectId }) => parentObjectId === processOverview.id)?.value)
      
      const event = {
        ...processOverview,
        eventType: global.constants.eventTypes.GODOO_EVENT,
        orderData: appointment,
        title: __(orderOverview.title),
        start,
        start_time: Moment(start),
        end,
        end_time: Moment(end),
        backgroundColor: orderOverview?.orderType?.color,
        entityId: additionalDisplayData["100_58be96d00e823f552aa1a071"][0].id
      }
      
      return additionalDisplayData?.["100_58be96d00e823f552aa1a070"]?.map((user) => ({
        ...event,
        userId: user.id
      }))
    }).flat())
    : []

  const nonWorkingEvents = selectedEventTypes.includes(global.constants.eventTypes.NON_WORKING_EVENT)
    ? await api.post(`${config.prefix()?.CALENDAR || ''}/calendars/personal/nonWorking/search?union=true&startingDateTime=${startDate}&endingDateTime=${endDate}`, items)
        .then((data) => data?.data.filter((e) => e).map((event) => ({
          ...event,
          ...transformNonWorkingEvent(event.nonWorkingPeriod)
        })))
        .catch((e) => console.log(e)) || []
    : []

  const workingEvents = selectedEventTypes.includes(global.constants.eventTypes.WORKING_EVENT)
      ? await api.post(`${config.prefix()?.CALENDAR || ''}/calendars/personal/working/search?fallback=true`, items)
          .then((data) => data?.data.filter((e) => e).map((event) => ({
          ...event,
          ...transformWorkingEvent(event.workingDay)
        })))
        .catch((e) => console.log(e)) || []
      : []
 
  return [...nonWorkingEvents, ...godooEvents, ...workingEvents ].flat()
}


export function getCalendarAggregatedEvents ({ items, startDate, endDate }) {
  const s = Moment(startDate).toISOString()
  const e = Moment(endDate).toISOString()

  return api.post(`${config.prefix()?.CALENDAR || ''}/calendars/aggregated?startingDateTime=${s}&endingDateTime=${e}`, items)
    .then((data) => data.data)
    .catch((e) => console.log(e))
}
