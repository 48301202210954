/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import { Icon } from '@ant-design/compatible'
import { Chart, Axis, Tooltip, Geom, PieChart } from 'bizcharts'
import { Table, Statistic, Col, Row, Dropdown, Menu, Modal } from 'antd'
import { hashHistory } from '../../providers/HistoryProvider'
import { renderGodooElement } from '../../utils/appHelper'
import ResultSet from './utility/ResultSet'
import getFieldKey from './utility/getFieldKey'
import * as S from './styles'
import { __ } from '../../utils/translationUtils'
import {isValidDate} from "../../utils/dateHelper";
import ErrorBoundary from "../UI/ErrorBoundary";



const Chartino = (chartProps) => {
  const [expandedChartProps, setExpandedChartProps] = useState(null)
  const { query, results, filterOutLastGranularityBin, presentation, visualization, mappings, reportsWidgetConfiguration, enumeration, attributes, hideMenu, granularity, xLabel, yLabel, error } = chartProps

  if (error) throw new Error(error)

  const { pivotConfig } = visualization || {}
  let { stackResults } = visualization || {}
  stackResults = stackResults === 'true'
  const InstanceResultSet = new ResultSet({
    queryType: 'regularQuery',
    showQuery: false,
    results, // ✅ TBD ? probabilmente i results qui vanno manipolati uniformemente per tutti i grafici
    pivotQuery: {
      ...query,
      rowLimit: query.limit,
      queryType: 'regularQuery',
      timezone: 'UTC'
    }
  })

  function getLabel(label, defaultLabel){
    return label || defaultLabel;
  }

  function axisLabel(axis){
    if(axis === 'x'){
      return getLabel(xLabel, pivotConfigTransformed.x.map(getReadableChartValue).join('\n'))
    }
    if(axis === 'y'){
      return getLabel(yLabel, pivotConfigTransformed.y.map(getReadableChartValue).join('\n'))
    }
    return '';
  }

  function changeToPic() {
    //Div ID is required to download chart as image https://www.jianshu.com/p/df18dce77299
    const wrapDiv = document.getElementById(chartProps.id);
    if (wrapDiv) {
      const canvas = wrapDiv.querySelector('canvas');
      if (canvas) {
        exportCanvasAsPNG(canvas, `${chartProps?.name} - ${chartProps?.presentation}`);
      }
    }
  }

  function exportCanvasAsPNG(canvasElement, fileName) {
    //Download chart as image https://bizcharts.net/faq#faq-15
    let MIME_TYPE = "image/png";
    let imgURL = canvasElement.toDataURL(MIME_TYPE, 1.0);
    let dlLink = document.createElement('a');
    dlLink.download = fileName;
    dlLink.href = imgURL;
    dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':');
    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  }

  // Gestisce il caso in cui è un attribute di mattew, sfruttando poi gli attributes di godoo
  const getAttributeFromWidgetAttributeKey = (widgetAttributeKey) => {
    const value = widgetAttributeKey
      ?.split('_countDistinct')[0]
      .split('_second')[0]
      .split('.second')[0]
      .split('_minute')[0]
      .split('.minute')[0]
      .split('_hour')[0]
      .split('.hour')[0]
      .split('_day')[0]
      .split('.day')[0]
      .split('_month')[0]
      .split('.month')[0]
      .split('_year')[0]
      .split('.year')[0]

    const { dimensions, filters, measures, times } = reportsWidgetConfiguration

    const widgetAttributes = [...(dimensions || []), ...(filters || []), ...(measures || []), ...(times || [])]

    const { attributeId } = widgetAttributes.find((e) => e.fieldKey === value) || {}
    return attributes.find((e) => e.id === attributeId)
  }

  // Gestisce qualunque cosa gli arrivi: oggetti, numeri, date, renderGodooElements, ... ecc
  const getReadableChartValue = (e) => {

    if (Number(e) || Number(e) === 0) return e
    if (e == undefined ) return 0

    try {
      const momentDate = moment(e)
      return momentDate.isValid() && isValidDate(e)
        ? moment(e).format((granularity === 'year' && 'YYYY') || (granularity === 'month' && 'YYYY-MM') || 'YYYY-MM-DD')
        : __(JSON.parse(e).value)
    } catch (error) {
      const attribute = getAttributeFromWidgetAttributeKey(e)
      let value = attribute?.dependenciesLabel && attribute?.propertyLabel ? `${__(attribute.dependenciesLabel)} --> ${__(attribute?.propertyLabel)}` : __(e)

      if (e?.includes('_countDistinct')) {
        value += ' (Count)'
      }

      return value
    }
  }

  const pivotConfigTransformed = {
    x: pivotConfig?.filter((e) => e?.pivot === 'x')?.map((e) => getFieldKey(e.value, enumeration)) || [],
    y: pivotConfig?.filter((e) => e?.pivot === 'y')?.map((e) => getFieldKey(e.value, enumeration)) || [],
    fillMissingDates: true,
    joinDateRange: false
  }

  let stackedChartData = InstanceResultSet
    .pivot(pivotConfigTransformed)
    .map(({ xValues, yValuesArray }) => yValuesArray.map(([yValues, m]) => {
      const xArray = InstanceResultSet.axisValuesString(xValues, '---').split('---')
      const yArray = InstanceResultSet.axisValuesString(yValues, '---').split('---')
      return {
        x: xArray.map(getReadableChartValue).join(' \n '),
        color: yArray.map(getReadableChartValue).join(' \n '),
        y: (m && Number.parseFloat(m)) || 0
      }
    }))
    .reduce((a, b) => a.concat(b), [])

  if(filterOutLastGranularityBin){
    let lastDay = new Date();
    switch (granularity) {
      case 'year':
        lastDay = new Date(lastDay.getFullYear(), 1, 1);
        break;
      case 'month':
        lastDay = new Date(lastDay.getFullYear(), lastDay.getMonth(), 1);
        break;
      case 'week':
        let days = ((lastDay.getDay() + 7) - 1) % 7;
        lastDay.setDate(lastDay.getDate() - days - 7);
        break;
    }
    stackedChartData = stackedChartData.filter(val => {
      //Remove last bin in chart
      return Date.parse(val?.x) < lastDay.getTime();
    });
  }

  let ChartComponent = () => null

  if (presentation === 'TABLE') {
    // eslint-disable-next-line no-inner-declarations
    // noinspection JSAnnotator
    function renderTableCell({ column, cellValue }) {
      // NB: eventuali celle differenti tra la versione csv e la versione del sito lo sono perchè nel sito viene renderizzato il godooElement.
      const attribute = getAttributeFromWidgetAttributeKey(column.key)

      let maybeParsed = cellValue

      // le date il renderGodooElement le formatta diversamente da quello che è necessario visualizzare qui, quindi devo gestire questo caso localmente
      if(attribute?.type === "DATE"){
        if (moment(maybeParsed).isValid()) return getReadableChartValue(maybeParsed);
      }

      if (attribute) {
        const { defaultControllerType } = mappings[attribute.type] || {}
        try {
          maybeParsed = JSON.parse(cellValue)
        } catch (error) {
          // console.log('cannot parse it')
        }

        return renderGodooElement(maybeParsed, null, attribute, defaultControllerType)
      }

      return getReadableChartValue(cellValue)
    }
    // eslint-disable-next-line no-inner-declarations
    // noinspection JSAnnotator
    function renderTableTitle(column) {
      return getReadableChartValue(column.dataIndex)
    }

    ChartComponent = () => (
      <S.TableWrapper>
        <Table
          className="borderless"
          pagination={false}
          columns={InstanceResultSet.tableColumns(pivotConfigTransformed).map((column) => ({
            ...column,
            render: (cellValue) => renderTableCell({ column, cellValue }),
            title: renderTableTitle(column)
          }))}
          dataSource={InstanceResultSet.tablePivot(pivotConfigTransformed)}
        />
      </S.TableWrapper>

    )
  }
  if (presentation === 'PIE') {
    ChartComponent = () => (
      <PieChart
        data={
          (InstanceResultSet.series()[0]?.key) ?
          InstanceResultSet.chartPivot().map((e) => {
          const xValues = e.xValues.map(getReadableChartValue)
          return {
            ...e,
            category: xValues.join('\n'),
            x: xValues.join('\n'),
            xValues
          }
        }) : [{x: __('No data'), value: 1}]}
        autoFit
        angleField={InstanceResultSet.series()[0]?.key || 'value'}
        colorField="x"
        label={{
          visible: true,
          offset: 20,
          type: 'inner',
          formatter: (angleField, colorField) => {
            const value = angleField?.[InstanceResultSet.series()[0]?.key];
            if(!value){
              return __('No data');
            }
            if(value.toFixed(2) === (angleField?.percent * 100).toFixed(2)){
              return `${value}%`;
            }
            return value;
          }
        }}
        legend={{ position: 'bottom' }}
      />
    )
  }
  if (presentation === 'NUMBER') {
    ChartComponent = () => (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ height: '100%' }}
      >
        <Col>
          <S.StatisticWrapper>
            {InstanceResultSet.seriesNames().map((s) => (
              <Statistic value={InstanceResultSet.totalRow()[s.key]} />
            ))}
          </S.StatisticWrapper>
        </Col>
      </Row>
    )
  }

  if (presentation === 'AREA' || presentation === 'LINE') {
    ChartComponent = () => (
      <Chart
        autoFit
        data={stackedChartData}
        forceFit
      >
        <Axis
          name="x"
          title={{ text: axisLabel('x') }}
          label={{
            autoRotate: true
          }}
        />
        <Axis
          name="y"
          title={{ text: axisLabel('y') }}
        />
        <Tooltip
          showMarkers
          shared
          showNil
          customItems={(originalItems) => {
            const total = {
              title: originalItems[0]?.title,
              name: __('total'),
              color: '#FFFFFF',
              x: 0,
              y: 0,
              value: originalItems.map((e) => e.value).reduce((acc, val) => acc + parseInt(val, 10), 0).toString()
            }
            originalItems.push(total)
            return originalItems
          }}
          showCrosshairs
          crosshairs={{ type: 'x', line: { style: { lineDash: [4], stroke: 'rgba(255, 0, 0, 0.5)', lineWidth: 1 } } }}
        />
        <Geom type={presentation.toLowerCase()} shape="smooth" adjust={stackResults ? { type: 'stack', reverseOrder: false } : undefined} position="x*y" size={2} color="color" />
      </Chart>
    )
  }

  if (presentation === 'BAR') {
    ChartComponent = () => (
      <Chart
        autoFit
        data={stackedChartData}
        forceFit
      >
        <Axis
          name="x"
          title={{ text: axisLabel('x') }}
          label={{
            autoRotate: true
          }}
        />
        <Axis
          name="y"
          title={{ text: axisLabel('y') }}
        />
        <Tooltip shared showNil />
        <Geom type="interval" adjust={stackResults ? 'stack' : undefined} position="x*y" color="color" />
      </Chart>
    )
  }

  const csvData = InstanceResultSet.tablePivot(pivotConfigTransformed).map((dataObject) => Object.entries(dataObject).reduce((acc, [key, val]) => ({
    ...acc,
    [`${getReadableChartValue(key)}`]: getReadableChartValue(val)
  }), {}))

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => setExpandedChartProps(chartProps)}>
        <Icon type="fullscreen" /> {__('Expand')}
      </Menu.Item>
      <Menu.Item disabled={chartProps.presentation === 'TABLE'} key="1" onClick={() => setExpandedChartProps({ ...chartProps, presentation: 'TABLE' })}>
        <Icon type="table" />  {__('View as table')}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => hashHistory.push(`/widgets/${chartProps.id}`)}>
        <Icon type="edit" />  {__('Edit')}
      </Menu.Item>
      <Menu.Item key="3">
        <CSVLink data={csvData} filename="export.csv">
          <Icon type="download" /> {__('Export CSV')}
        </CSVLink>
      </Menu.Item>
      <Menu.Item key="4"
                 onClick={changeToPic}
      >
        <Icon type="picture" /> {__('Save as image')}
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <div id={chartProps.id} style={{display: 'flex', width: '100%', height: '100%'}}>
        <ChartComponent />
      </div>
      {!hideMenu && (
        <S.ChartHandlerWrapper>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className="btn-no-padding btn-std">
              <Icon type="more" />
            </div>
          </Dropdown>
        </S.ChartHandlerWrapper>
      )}
      {
        expandedChartProps && (
          <Modal
            title={__(expandedChartProps.name)}
            visible
            width="90vw"
            bodyStyle={{ height: '75vh', overflowY: 'scroll' }}
            footer={null}
            onOk={() => setExpandedChartProps(null)}
            onCancel={() => setExpandedChartProps(null)}
          >
            <ErrorBoundary>
              <Chartino {...expandedChartProps} hideMenu />
            </ErrorBoundary>
          </Modal>
        )
      }
    </>
  )
}

export default Chartino
