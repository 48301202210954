import { __ } from './translationUtils'

export const getDisplayAttributes = (displayAttributes, attributeList) => {
  return (displayAttributes?.sort((a, b) => a.position - b.position) || []).map((dAttribute) => (
    attributeList?.find(a => a.id === dAttribute.attributeId)
  )).filter(x => x)
}

export const getDisplayAttributesItems = (displayAttributes, attributeList) => {
  const attributesUsedInDisplay = getDisplayAttributes(displayAttributes, attributeList)

  return attributesUsedInDisplay?.filter((a) => (a.displayable)).map((a) => ({
    value: a.id,
    label: __(a.propertyLabel)
  }))
}
