import React, {useEffect, useMemo, useState} from 'react'

import {FormViewer, getDefaultFormViewerOptions} from 'components/dist/FormBuilder'

import {StepPageInfo, Tag} from "../../styles"
import {Flex} from "components/dist/styles"
import {__} from '../../../../../utils/translationUtils'
import UserPicker from "../../customRender/UserPicker";
import OrderOverviewTrigger from "../../OrderOverviewTrigger";
import {fetchCompaniesTree} from "../../../../../providers/ApiProvider/entity";

export default ({stepRef, formRef, order, activeProcess, step, openOrderOverview}) => {
  if (!order || !step) return null
  const {fields, buttons, ...stepData} = step

  const initialValues = useMemo(() => ({
    "assignment-step": stepData?.attributes?.showCurrentAssignees ? stepData?.attributes?.assignees?.map((a) => (
      JSON.stringify({
        type: a.type,
        userId: a.userId,
        entityId: a.entityId
      })
    )) : []
  }), [stepData?.attributes?.assignees])

  const [companiesTree, setCompaniesTree] = useState(undefined)

  useEffect(() => {
    fetchCompaniesTree().then((tree) => setCompaniesTree(tree))
  }, []);

  const viewerOptions = useMemo(() => {
    const defaultFormViewerOptions = getDefaultFormViewerOptions()
    const {availableControllers} = defaultFormViewerOptions

    return {
      ...defaultFormViewerOptions,
      availableControllers: {
        ...availableControllers,
        UserPicker: {
          ...availableControllers.ImageViewer || {},
            name: 'User Picker',
            icon: 'user',
            controllerType: 'UserPicker',
          customRender: (props) => (<UserPicker key={props.id} companiesTree={companiesTree} {...props} />),
          features: {
            ...availableControllers.ImageViewer.features, // TODO: temporary
          }
        },
      }
    }
  }, [companiesTree])

  return !companiesTree ? null : (
    <FormViewer
      formRef={formRef}
      options={viewerOptions}
      initialValues={initialValues}
      additionalInfo={{
        stepInfo: {
          id: stepData.id,
          values: {
            ...stepData,
            title:
              <Flex style={{lineHeight: "18px"}}>
                {__(stepData.title)}
                <StepPageInfo>
                  {activeProcess && <Tag>{__(activeProcess.title)}</Tag>}
                  {order &&
                    <Tag>{order && <OrderOverviewTrigger order={order} openOrderOverview={openOrderOverview}/>}</Tag>}
                </StepPageInfo>
              </Flex>,
          },
        },
        fieldList: [{
          id: "assignment-step",
          controllerType: 'UserPicker',
          values: {
            title: __("Please select a user"),
            required: true,
            position: 1,
            iconHidden: true,
          },
          cellProperties: {
            h: 8,
            minH: 1,
            minW: 1,
            w: 4,
            x: 0,
            y: 60
          },
        }]
      }}
    />
  )
}
