import React from "react";
import { Badge, Collapse } from "antd";
import styled from "styled-components";
import { Flex, FlexRow, FlexRowRight } from "components/dist/styles";
import { ColoredIcon } from "components/dist/FormBuilder/Components/UI/styles";

import { __ } from '../../../utils/translationUtils'


export const ChatContainer = styled(Collapse)`
  margin: 20px 46px;
  //border: 1px solid #acaaaa; //TODO: get feedback on this or next line
  box-shadow: 0 1px 3px 1px rgba(0,0,0,0.15),0 1px 2px rgba(0,0,0,0.3);
  border-radius: 8px;
  padding: 0.5rem 14px;
  
  .ant-collapse-item {
    border: unset !important;
    margin: 0 !important;
    .ant-collapse-header {
      background-color: unset !important;
      padding: 0 !important;
    }
    .ant-collapse-content {
      border: unset !important;
    }
  }
`

const ChatHeaderHelper = styled(Flex)`
  width: fit-content;
  color: #00629D;
  font-size: initial;
  font-weight: normal;
`

export const ChatHeader = ({ title, total }) => (
  <ChatHeaderHelper>
    <FlexRow>
      <ColoredIcon type='message' color='#00629D' style={{ fontSize: "1.3rem" }} />
      <div style={{ padding: "0 8px" }}>{__(title)}</div>
    </FlexRow>
    <FlexRowRight>
      <Badge
        showZero
        count={total}
        style={{ backgroundColor: 'rgba(148,146,146,0.2)', color: 'gray', boxShadow: 'unset' }}
      />
    </FlexRowRight>
  </ChatHeaderHelper>
)

export const Chatroom = styled.div`
  display: flex;
  max-height: 300px;
  overflow: auto;
  width: 100%;
  padding: 10px 15px !important;
`

export const MessageList = styled.div`
  width: 100%
`

export const MessageContent = styled.div`
  width: 96%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #C6C6C9;
  border-radius: 8px;
  background-color: #fdfdfd;
  float: ${(props) => props.isCreator ? "right" : "left"};
  cursor: pointer;

  &:hover {
    border: 1px solid #EB0089;
  }
`

export const NoComments = styled.div`
  font-style: italic;
  font-size: 12px;
  color: #999;
`
