import React from "react";
import { formatBytes, partialPathToFileInfo } from "../../../utils/fileUtils";
import ButtonDownloader from "../../UI/IOComponents/ButtonDownloader";

import { CheckCircleOutlined, DeleteOutlined, FileExcelOutlined, LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Icon } from "components";
import { Tooltip } from "antd";
import { __ } from '../../../utils/translationUtils'


const Status = ({ status, progress, error }) => {
  if (status === 'IN_PROGRESS') return <div><LoadingOutlined /> {` ${progress}%`}</div>
  if (status === 'ERROR') {

    const { logId, message } = error || {}

    return (
      <Tooltip
        title={
          <div>
            {message}
          </div>
        }
      >
        <a target="_blank" href={`${window.location.origin}#logs/${logId}`} style={{ color: '#DCDCDC' }} rel="noreferrer">
          <Button icon={<Icon component={WarningOutlined} color="red" />} type="text" shape="circle" />
        </a>
      </Tooltip>
    )
  }
  if (status === 'CREATED') return <Icon component={CheckCircleOutlined} color="green" />
  if (status === 'DELETED') return <Icon component={DeleteOutlined} color="red" />
}

export const exportsTableColumns = [
  {
    title: 'Name',
    key: `table-column-name`,
    width: 150,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: 'Last Update',
    key: `table-column-last-update`,
    width: 150,
    dataIndex: 'lastUpdateDatetime',
    sorter: true,
    render: (date) => new Date(date).toLocaleString(),
  },
  {
    title: 'Size',
    key: `table-column-size`,
    width: 80,
    dataIndex: 'size',
    sorter: true,
    render: (size) => formatBytes(size),
  },
  {
    title: 'Status',
    key: `table-column-status`,
    width: 120,
    dataIndex: 'status',
    sorter: true,
    render: (status, { errorLogId, errorMessage, progress }) => <Status status={status} progress={progress} error={{ logId: errorLogId, message: errorMessage }} />,
  },
  {
    title: '',
    key: `table-column-path`,
    width: 50,
    dataIndex: 'path',
    render: (path, { status }) => {
      const fileInfo = partialPathToFileInfo(path)
      return <ButtonDownloader
        fileInfo={fileInfo}
        downloadButtonProps={{
          disabled: status !== 'CREATED',
          items:
            [
              { title: 'Excel', option: 'xlsx', icon: <FileExcelOutlined style={{ color: "green" }} /> },
              { title: 'CSV', option: 'csv', icon: <Icon component="csv" color="blue" /> }
            ]
        }} />
    },
  }
]

export const extensionToMimeType = (extension) => {
  return fileExtensionToMimeType[extension] || fileExtensionToMimeType['xlsx']
}

const fileExtensionToMimeType = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
}
