
import { INFO, ERROR } from "components/dist/Utils/LoggerUtils"
import { api } from "./index";
import { showMessage } from "../../utils/appHelper";
import { __ } from '../../utils/translationUtils'


/* SELECTORS ( e.g. 'remoteObjects/') */
/*
 * Add or remove relations between objects in selectors without checking rights.
 *
 * @param {object} parentObject - The parent object.
 *    - {string} parentObject.objectTypeId - The type ID of the parent object.
 *    - {string} parentObject.mainKey - The main key of the parent object.
 *
 * @param {object} payload - The payload object.
 *    - {string} payload.childObject - The ID of the child object.
 *    - {string[]} payload.childObjectIds - An array of object IDs.
 *
 * @returns {Promise} - A promise that resolves to a result after the operation is completed.
 */

export const addRelations = async (props) => {
  const {
    parentObject,
    fieldId,
    orderId,
    overviewObjectConfigurationId,
    position,
    payload,
    sourceAccess
  } = props || {}

  const { objectTypeId, mainKey } = parentObject || {}

  return api.post(`remoteObjects/${objectTypeId}/${mainKey}/addRelations?${fieldId ? `fieldId=${fieldId}` : `orderId=${orderId}&overviewObjectConfigurationId=${overviewObjectConfigurationId}&overviewObjectConfigurationDisplayAttributePosition=${position}`}${sourceAccess ? `&sourceAccess=${sourceAccess}` : ``}`, payload)
    .then((res) => {
      showMessage('success', __('relations_added_successfully'))

      return res
    })
    .catch((error) => {
      showMessage('error', __('error_adding_relations'))
      ERROR(error)
    })
}

export const removeRelations = async (props) => {
  const {
    parentObject,
    fieldId,
    orderId,
    overviewObjectConfigurationId,
    position,
    payload,
    sourceAccess
  } = props || {}

  const { objectTypeId, mainKey } = parentObject || {}

  return api.post(`remoteObjects/${objectTypeId}/${mainKey}/removeRelations?${fieldId ? `fieldId=${fieldId}` : `orderId=${orderId}&overviewObjectConfigurationId=${overviewObjectConfigurationId}&overviewObjectConfigurationDisplayAttributePosition=${position}`}${sourceAccess ? `&sourceAccess=${sourceAccess}` : ``}`, payload)
    .then((res) => {
      showMessage('success', __('relations_removed_successfully'))
      return res
    })
    .catch((error) => {
      showMessage('error', __('error_removing_relations'))
      ERROR(error)
    })
}

/*
 * Add or remove relations between objects checking the ROE right.
 *
 * @param {object} parentObject - The parent object.
 *    - {string} parentObject.objectTypeId - The type ID of the parent object.
 *    - {string} parentObject.mainKey - The main key of the parent object.
 *
 * @param {object} payload - The payload object.
 *    - {string} payload.childObject - The ID of the child object.
 *    - {string[]} payload.childObjectIds - An array of object IDs.
 *
 * @returns {Promise} - A promise that resolves to a result after the operation is completed.
 */

export const addRelationsROE = async (parentObject, payload) => {
  const { objectTypeId, mainKey } = parentObject || {}

  return api.post(`configurations/remoteObjects/${objectTypeId}/${mainKey}/addRelations`, payload)
    .then((res) => {
      showMessage('success', __('relations_added_successfully'))

      return res
    })
    .catch((error) => {
      showMessage('error', __('error_adding_relations'))
      ERROR(error)
    })
}

export const removeRelationsROE = async (parentObject, payload) => {
  const { objectTypeId, mainKey } = parentObject || {}

  return api.post(`configurations/remoteObjects/${objectTypeId}/${mainKey}/removeRelations`, payload)
    .then((res) => {
      showMessage('success', __('relations_removed_successfully'))
      return res
    })
    .catch((error) => {
      showMessage('error', __('error_removing_relations'))
      ERROR(error)
    })
}
