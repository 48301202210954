import React from 'react';
import PropTypes from 'prop-types';
import {DropdownButton, MenuItem} from "react-bootstrap";
import {TableHeaderColumn} from 'react-bootstrap-table';
import {find} from "lodash";
import {Link} from 'react-router-dom';
import {renderCAlgorithm, renderResultInfo} from "../utils/appHelper";
import * as config from '../constants/globalConfiguration';
import {StickyScrollableTable} from "../providers/StyleProvider/styles";
import {__} from '../utils/translationUtils'


class ReportSnapshotDataTable extends React.Component {

  constructor(props) {
    super(props);

    this.snapshotLinkFormatter = this.snapshotLinkFormatter.bind(this);
  }


  renderPagination() {
    const {setPageSize, pagination} = this.props;
    return (
      <DropdownButton
        onSelect={setPageSize.bind(this)}
        bsStyle="link"
        title={pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {config?.pageSizeOptions?.map((val, i) => {
          return <MenuItem key={i} eventKey={val}>{val}</MenuItem>;
        })}
      </DropdownButton>
    );
  }

  extractConfiguration(id) {
    const {attributesConfiguration} = this.props;
    const found = find(attributesConfiguration, (c) => c.id === id);
    return found;
  }

  extractDisplayConfiguration(id) {
    const {reportSpec} = this.props;
    const found = find(reportSpec.displayAttributes, (c) => c.attributeId === id);
    return found;
  }

  snapshotLinkFormatter(cell, row) {
    return (<Link
      to={`/snapshots/${row.id}`}
      className="btn btn-success btn-sm btn-no-padding"
    >
      {__('View')}
    </Link>);
  }

  renderTableHeaderColumnViewButton() {
    return (
      <TableHeaderColumn
        className="doo"
        width="125"
        dataFormat={this.snapshotLinkFormatter}
      />
    )
  }

  renderTableHeaderColumns() {
    const {reportSpec, selectedColumns, isBilling} = this.props;
    return (

      reportSpec.displayAttributes.map((a, i) => {
        const data = this.extractConfiguration(a.attributeId);
        const propertyLabel = a.propertyLabel || data.propertyLabel;
        return (
          <TableHeaderColumn
            key={data.id}
            dataSort
            dataFormat={(cell, row) => {
              return renderCAlgorithm(row.displayData[data.id], row, data, this.extractDisplayConfiguration(a.attributeId), true);
            }}
            // Check if displayable and user selected this column from "visible columns" checkboxes
            hidden={(selectedColumns.indexOf(data.propertyLabel) < 0) || !data.displayable}
            isKey={i === 0}
            dataField={isBilling ? data.propertyLabel : data.id}
          >
            {__(propertyLabel)}
          </TableHeaderColumn>
        );
      })

    )
  }

  renderBootStrapTable() {
    const {data, pagination, onPageChange, onSortChange, isLoading} = this.props;
    return (
      <StickyScrollableTable
        data={data}
        ref="reportDataTable"
        remote={true}
        pagination={true}
        hover
        striped
        className="large-table"
        fetchInfo={{dataTotalSize: pagination.totalCount}}
        options={{
          onPageChange: onPageChange.bind(this),
          onSortChange: onSortChange.bind(this),
          noDataText: isLoading ? __('loading_data') : __('NoDataFound'),
          hideSizePerPage: false,
          firstPage: 'First',
          lastPage: 'Last',
          sizePerPageList: config.pageSizeOptions,
          paginationShowsTotal: renderResultInfo.bind(this),
          page: pagination.pageNumber,
          sizePerPage: pagination.pageSize,
        }}
      >
        {this.renderTableHeaderColumnViewButton()}
        {this.renderTableHeaderColumns()}
      </StickyScrollableTable>
    )

  }

  render() {
    const {reportSpec} = this.props;
    if (reportSpec && reportSpec.displayAttributes.length) {
      return this.renderBootStrapTable();
    } else {
      return (<div/>);
    }

  }


}


ReportSnapshotDataTable.propTypes = {
  data: PropTypes.array,
  reportSpec: PropTypes.object,
  selectedColumns: PropTypes.array,
  attributesConfiguration: PropTypes.array,
  pagination: PropTypes.object,
  selectedTab: PropTypes.string,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  isBilling: PropTypes.bool.isRequired,
  setPageSize: PropTypes.func.isRequired
};

ReportSnapshotDataTable.defaultProps = {
  data: [],
  reportSpec: [],
  selectedColumns: [],
  attributesConfiguration: [],
  pagination: {},
  selectedTab: '',
};


export default ReportSnapshotDataTable;
