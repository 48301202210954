import React, {useState} from 'react'
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'
import {Button, Modal, Table} from 'antd'
import {CellDoo, CellDooText, Full} from '../ReportDataTable'
import {api} from '../../providers/ApiProvider'
import {showMessage} from '../../utils/appHelper'
import {hashHistory} from '../../providers/HistoryProvider'
import {css} from '@emotion/css';
import { __ } from '../../utils/translationUtils'

const tableCSS = css({
  '& thead': {
    height: '30px',
  },
  '& thead > tr > th': {
    backgroundColor: '#1a618b',
    height: '30px',
    paddingTop: 0,
    paddingBottom: 0,
    color: 'white',
    borderRight: '2px solid white'
  },
  '& tbody > tr > td': {
    color: '#0a4f7a',
    borderRight: '2px solid white',
    height: '28px',
    paddingTop: 0,
    paddingBottom: 0,
  },
})

const tableRowCSS = css({
  backgroundColor: "#d4f2ff",
})

function DooOrderButton({orderDetails, location}) {

  const {id, accessRightsInfo} = orderDetails

  const [selectActiveProcessIsOpen, setSelectActiveProcessIsOpen] = useState(false)
  const [activeProcessId, setActiveProcessId] = useState(null)
  const [processes, setProcesses] = useState([])

  const handleOpenModal = () => {
    fetchProcessData()
  }
  const handleCloseModal = () => setSelectActiveProcessIsOpen(false)

  const retrieveActiveProcesses = (_processes) => _processes.filter(p => p && p.accessRightsInfo && p.accessRightsInfo.doo)

  const handleActiveProcess = activeProcess => {
    setActiveProcessId(activeProcess.id)
  }

  const handleDooProcess = () => {
    hashHistory.push(`/orders/${id}/doo?backUrl=${location.pathname}${location.search}&activeProcessId=${activeProcessId}`)
  }

  const fetchProcessData = async () => {
    if (id == null) return

    const response = await api.get(`orders/${id}`)

    if (response == null) return

    const _processes = response?.data.processes

    if (_processes == null) {
      showMessage('error', __('NoActiveProcessFound'));
      hashHistory.goBack();
    }

    const activeProcesses = retrieveActiveProcesses(_processes)
    setProcesses(activeProcesses)

    if(!activeProcesses || activeProcesses?.length === 0){
      return;
    }

    if (activeProcesses?.length > 1) {

      setSelectActiveProcessIsOpen(true)
    } else {
      hashHistory.push(`/orders/${id}/doo?backUrl=${location.pathname}${location.search}&activeProcessId=${activeProcesses[0]?.id}`)
    }


  }

  const processColumns = [
    {
      title: __('100_58be96d00e823f552aa1a036'), // Process title
      dataIndex: 'title',
      key: 'name',
      render: text => __(text)
    },
    {
      title: __('100_58be96d00e823f552aa1a044'), // Process status
      dataIndex: 'processStatus',
      key: 'status',
      render: text => __(text)
    }
  ]


  return (
    <div style={{...(accessRightsInfo.doo ? {} : {"pointerEvents": "none", "opacity": .4})}}>
      {/* I Use link here in order to avoid redo all the style */}
      <Link
        /* to={`/orders/${_row.id}/doo?backUrl=${location.pathname}`}*/
        to={"#"}
        className="btn btn-success btn-sm btn-no-padding"
        onClick={handleOpenModal}
        style={{
          ...Full,
          "paddingLeft": "0.1rem",
        }}
      >
        <div style={CellDooText}>doo</div>
      </Link>
      <Modal
        title={__('Select') + " " + __('Process')}
        centered
        onCancel={handleCloseModal}
        visible={selectActiveProcessIsOpen}
        afterClose={document.body.removeAttribute('style')} //for bug described here: https://github.com/ant-design/ant-design/issues/21539
        footer={[
          <Button onClick={handleCloseModal}
                  style={{borderRadius: '5px', backgroundColor: '#5e717a', color: 'white'}}>Cancel</Button>,
          <Button disabled={activeProcessId == null} onClick={handleDooProcess}
                  style={{backgroundColor: '#88bd4f', color: 'white', borderRadius: '5px'}}>
            doo
          </Button>

        ]}
      >
        <div>
          <div style={{paddingBottom: '15px'}}>{__('multipleProcessWarningText')}</div>
          <Table
            rowSelection={{type: 'radio', onSelect: handleActiveProcess}}
            rowClassName={(_, index) => index % 2 !== 0 && tableRowCSS}
            columns={processColumns}
            dataSource={processes}
            pagination={false}
            className={tableCSS}
            rowKey={'id'}
          /></div>
      </Modal>
    </div>)

}

DooOrderButton.propTypes = {
  orderDetails: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired
}

export default DooOrderButton
