import Cookies from "js-cookie";
import {geAuthCookieAttributes, getAuthCookieName} from "../actions/userActions";

export default {
  user:{
    authToken: Cookies.get(getAuthCookieName(), geAuthCookieAttributes()) || null,
    data: null,
    isLoading: false,
    reports: [],
    views: [],
    orderGroups: [],
    newEditButton: true,
  },
  enumValues: {},
  errors: {
    loginError: null
  },
  menu: {
    "items": []
  },
  ui: {
    language: null,
    remoteSystems: [],
    systemSettings: null,
    instanceSettings: null,
    attributes: null,
    attributesTree: null,
  },
  entities: {
    searchOrganisationsForm: null,
    searchOrganisationsResults: []
  },
  import: {
    reportData: null,
  },
  selectedTemplateRights: null,
};
