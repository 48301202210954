import {getCompaniesTree} from "../../utils/companyUtils";
import {api} from "./index";

import {ERROR} from 'components/dist/Utils/LoggerUtils'
import {showMessage} from "../../utils/appHelper";
import {__} from "../../utils/translationUtils";
import {cases} from "../../utils/stringUtils";


type FetchCompaniesProps = {
  withUsers?: boolean
}

export const fetchCompanies = async ({ withUsers = true } : FetchCompaniesProps = {}) => api.get('/hierarchy/organisations', {params:  {withUsers }})
  .then((res) => res.data)
  .catch((e) => ERROR(e))

export const fetchCompaniesTree = (onlyLeafs?: boolean) => fetchCompanies()
  .then((companies) => getCompaniesTree(companies, onlyLeafs))

export const fetchEntities = async (type: EntityTypes = "user_group"): Promise<Entity[]> => api.get('/entities/', {params: {pageSize: 100, type, status: 'enabled,reserved'}})
  .then((res) => res.data)
  .catch((e) => ERROR(e))

export const fetchBlockedOrdersById = async (userId: string): Promise<number> => api.get(`/orders/ongoing/onlyAssignee/${userId}/count`)
  .then((res) => res.data)
  .catch((e) => ERROR(e))

export type FetchUsersProps = {
  pageSize?: number,
  statuses?: Statuses[],
  ids?: string
}

export const fetchUsers = async ({ pageSize = 200, statuses = ['enabled', 'reserved'], ids } : FetchUsersProps = {}) : Promise<User[]> =>
  api.get('/users', {params: {pageSize, status: statuses.join(), ids}})
    .then((res) => res.data)
    .catch((e) => ERROR(e))

export const updateUserStatus = async (userId: string, status: ChangeStatusAction, deputyId: string) => api.post(`/users/${userId}/status/${status}`, { deputyId, replaceWithDeputy: !!deputyId })
  .then((res) => {
    showMessage('success', __('user_status_updated'))
    return res.data
  })
  .catch(() => showMessage('error', __('user_status_not_updated', cases.CAPITALIZE_SENTENCE)))

export const changeDeputy = async (userId: string, deputyId: string, replaceWithDeputy: boolean) => api.put(`/users/${userId}/deputy`, { deputyId, replaceWithDeputy })
  .then((res) => {
    showMessage('success', __('user_deputy_updated'))
    return res.data
  })
  .catch(() => showMessage('error', __('user_deputy_not_updated', cases.CAPITALIZE_SENTENCE)))

export const fetchUsersDashboards = async (userId: string) : Promise<unknown> =>
  api.get(`/users/${userId}/dashboards`)
    .then((res) => res.data)
    .catch((e) => ERROR(e))

export const setUserRights = async (userId: string, templates: Array<string>) => api.post(`/users/${userId}/roles`, templates)
  .then((res) => {
    showMessage('success', __('user_role_updated'))
  })
  .catch(() => showMessage('error', __('user_role_not_updated', cases.CAPITALIZE_SENTENCE)))
