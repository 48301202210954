import React, { useCallback, useMemo } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import _ from "lodash";

import { __ } from '../../../../utils/translationUtils'


const DragHandle = SortableHandle(() => <span className="drag-handle">::</span>);

const SortableItem = SortableElement(props => {
  const { attr, attributesList, activeIds } = props
  const itemFound = _.find(attributesList, config => config.id === attr.attributeId);
  if (!itemFound) { return null }

  const propertyLabel = attr.propertyLabel || itemFound.propertyLabel
  const onChangeCheckBox = () => {
    props.handleCheckBox(attr)
  }

  const onChangeSelectSorting = (e) => {
    props.handleSelectSorting(attr, e.target.value)
  }

  return (
    <div className="sorting-list-item">
      <div className="header">
        <DragHandle />

        <input
          type="checkbox"
          checked={activeIds.includes(attr.attributeId)}
          onChange={onChangeCheckBox}
        />
        <strong>{__(propertyLabel)}</strong>
        {' '}
        <select
          value={attr.value}
          onChange={onChangeSelectSorting}
        >
          <option value="ASC">{__('ASC')}</option>
          <option value="DESC">{__('DESC')}</option>
        </select>

        {' '}<span className="sorting-list-item_position">{__('Position')}: {attr.position}</span>
      </div>
    </div>
  )
});

const SortableList = SortableContainer(props => {

  const {
    items,
    lockAxis,
    lockToContainerEdges,
    useDragHandle,
    onSortEnd,
    ...itemProps
  } = props

  const sorted = _.sortBy(items, 'position')
  return (
    <div className="sorting-list clearfix">
      {sorted.map((attr, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          attr={attr}
          {...itemProps}
        />
      ))}
    </div>
  )
})

export default (props) => {

  const {
    value = [],
    onChange = () => { },
    attributesList,
  } = props

  const activeIds = useMemo(() => (
    value.filter((attr) => attr.enabled).map((item) => item.attributeId)
  ), [value])

  const onSelect = useCallback((attr, a) => {
    if (!attr) return
    onChange((prev) => prev.map((item) => {
      if (item.attributeId !== attr.attributeId) return item
      return { ...item, enabled: !attr.enabled }
    }))
  }, [onChange])

  const onUpdate = useCallback((attr, value) => {
    if (!attr) return
    onChange((prev) => prev.map((item) => {
      if (item.attributeId !== attr.attributeId) return item
      return { ...item, value: value }
    }))
  }, [onChange])

  const onSort = useCallback((props) => {
    const { oldIndex, newIndex } = props || {}
    onChange((prev) => prev.map((item) => {
      if (oldIndex === item.position) return { ...item, position: newIndex }
      if (newIndex > oldIndex && item.position >= oldIndex && item.position <= newIndex) return { ...item, position: item.position - 1 }
      if (newIndex < oldIndex && item.position >= newIndex && item.position <= oldIndex) return { ...item, position: item.position + 1 }
      return item
    }))
  }, [onChange])

  return (
    <SortableList
      items={value}
      attributesList={attributesList}
      activeIds={activeIds}
      handleCheckBox={onSelect}
      handleSelectSorting={onUpdate}
      lockAxis="y"
      lockToContainerEdges
      useDragHandle={true}
      onSortEnd={onSort}
    />
  )
}
