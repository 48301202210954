import React, {ReactNode} from "react";
import {Typography} from 'components'
import {cases} from "../../../utils/stringUtils";
import {TFunction} from "../../../utils/translationUtils";

const {Title, Text} = Typography

type StatusInputsProps = {
  data: { status: Statuses }
  __: TFunction
}

export const statusInputs = ({data, __}: StatusInputsProps) => ([
  {
    name: 'status',
    type: 'radio',
    size: 'large',
    items: [
      ...(data.status === "enabled" ? [] : [{
        value: "enabled",
        label: (
          <Title level={5}>{__('enable', cases.CAPITALIZE_SENTENCE)}</Title>
        )
      },]),
      ...(data.status === "disabled" ? [] : [{
        value: "disabled",
        label: (
          <div>
            <Title level={5}>{__('disable', cases.CAPITALIZE_SENTENCE)}</Title>
            <Text>{__('disable_user_description', cases.CAPITALIZE_SENTENCE)}</Text>
          </div>
        )
      }]),
      ...(data.status === "deleted" ? [] : [{
        value: "deleted",
        label: (
          <div>
            <Title level={5}>{__('delete', cases.CAPITALIZE_SENTENCE)}</Title>
            <Text>{__('delete_user_description', cases.CAPITALIZE_SENTENCE)}</Text>
          </div>
        )
      }]),

    ] as { value: Statuses, label: ReactNode }[],
  }
])

export type ResolutionType = "do_nothing" | "reassign"

export type OrgItem = {
  value: string
  label: string
}

export type UserItem = {
  value: string
  label: string
  belongsTo: string[]
}

type reassignUserInputsProps = {
  data: { orgItems: OrgItem[], userItems: UserItem[] }
  __: TFunction
}

export const reassignUserInputs = ({data, __}: reassignUserInputsProps) => ([
  {
    name: 'resolution',
    type: 'radio',
    size: 'large',
    items: [
      {
        value: "do_nothing",
        label: (
          <div>
            <Title level={5}>{__('do_nothing', cases.CAPITALIZE_SENTENCE)}</Title>
            <Text>{__('do_nothing_description', cases.CAPITALIZE_SENTENCE)}</Text>
          </div>
        )

      },
      {
        value: "reassign",
        label: (
          <div>
            <Title level={5}>{__('reassign_to_deputy', cases.CAPITALIZE_SENTENCE)}</Title>
            <Text>{__('reassign_to_deputy_description', cases.CAPITALIZE_SENTENCE)}</Text>
          </div>
        )
      },
    ] as { value: ResolutionType, label: ReactNode }[],
  },
  {
    name: 'organization',
    type: 'select',
    placeholder: __('select_organisation', cases.CAPITALIZE_SENTENCE),
    showSearch: true,
    filterOption: (input: string, option: any) => input ? option?.label?.toLowerCase()?.indexOf(input?.toLowerCase?.() as string) >= 0 : true,
    allowClear: true,
    items: data.orgItems,
    condition: ({watch}: { watch: (fieldName: string) => unknown }) => watch('resolution') as ResolutionType === "reassign"
  },
  {
    name: 'user',
    type: 'select',
    placeholder: `${__('select_deputy', cases.CAPITALIZE_SENTENCE)}*`,
    showSearch: true,
    filterOption: (input: string, option: any) => input ? option?.label?.toLowerCase()?.indexOf(input?.toLowerCase?.() as string) >= 0 : true,
    allowClear: true,
    items: ({watch}: { watch: (fieldName: string) => unknown }) => {
      const org = watch('organization') as string
      return org ? data.userItems.filter(({belongsTo}) => belongsTo.includes(org)) : data.userItems
    },
    fieldsWatcher: {
      callback: ({setValue}: { setValue: (fieldName: string, value: any) => void }) => {
        setValue('user', null)
      },
      dependencies: ['organization']
    },
    condition: ({watch}: { watch: (fieldName: string) => unknown }) => watch('resolution') as ResolutionType === "reassign"
  },
])


export const changeDeputyInputs = ({data, __}: reassignUserInputsProps) => ([
  {
    name: 'organization',
    type: 'select',
    placeholder: __('filter_by_org', cases.CAPITALIZE_SENTENCE),
    showSearch: true,
    filterOption: (input: string, option: any) => input ? option?.label?.toLowerCase()?.indexOf(input?.toLowerCase?.() as string) >= 0 : true,
    allowClear: true,
    items: data.orgItems,
  },
  {
    name: 'user',
    type: 'select',
    placeholder: `${__('select_deputy', cases.CAPITALIZE_SENTENCE)} *`,
    showSearch: true,
    filterOption: (input: string, option: any) => input ? option?.label?.toLowerCase()?.indexOf(input?.toLowerCase?.() as string) >= 0 : true,
    allowClear: true,
    items: ({watch}: { watch: (fieldName: string) => unknown }) => {
      const org = watch('organization') as string
      return org ? data.userItems.filter(({belongsTo}) => belongsTo.includes(org)) : data.userItems
    },
    fieldsWatcher: {
      callback: ({setValue}: { setValue: (fieldName: string, value: any) => void }) => {
        setValue('user', null)
      },
      dependencies: ['organization']
    },
  },
  {
    name: 'replaceWithDeputy',
    label: __('replace_with_deputy', cases.CAPITALIZE_SENTENCE),
    type: 'switch',
    placeholder: __('filter_by_org', cases.CAPITALIZE_SENTENCE),
  }
])
