import React from 'react'

import PropTypes from 'prop-types'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import {DropdownButton, MenuItem} from 'react-bootstrap'
import {Popconfirm, Spin, Tooltip} from 'antd'
import {DeleteOutlined, SearchOutlined} from "@ant-design/icons";
import {connect} from "react-redux";

import {checkUserRights, renderCAlgorithm, renderResultInfo} from '../../utils/appHelper'
import * as config from '../../constants/globalConfiguration'
import {TableFirstChildTdHiddenOverflow} from "../../providers/StyleProvider/styles";
import {hashHistory} from "../../providers/HistoryProvider";
import {deleteRemoteObjectItem} from "../../providers/ReduxProvider/actions/remoteObjectActions";
import {extractDetailedDisplayableColumn, extractDisplayConfiguration} from '../../utils/attributeUtils'
import {__} from '../../utils/translationUtils'

class RemoteObjectTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bulkAssignLoading: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.guiUser.rights && !state.permissions) {
      const {rightMappings} = global.constants
      return {
        ...state,
        permissions: {
          hasRoeReadAccess: checkUserRights(props.guiUser.rights, rightMappings.HAS_ROE_READ_ACCESS),
          hasRoeEditAccess: checkUserRights(props.guiUser.rights, rightMappings.HAS_ROE_EDIT_ACCESS),
          canUpdateRemoteObjectItem: checkUserRights(props.guiUser.rights, rightMappings.CAN_UPDATE_REMOTE_OBJECT_ITEM),
          canDeleteRemoteObjectItem: checkUserRights(props.guiUser.rights, rightMappings.CAN_DELETE_REMOTE_OBJECT_ITEM)
        }
      };
    }
    return state;
  }

  renderPagination() {
    return (
      <DropdownButton
        onSelect={this.props.setPageSize.bind(this)}
        bsStyle="link"
        title={this.props.pagination.pageSize}
        className="btn-link"
        id="pageSize"
      >
        {
          config?.pageSizeOptions?.map((val, i) => <MenuItem key={i} eventKey={val}>{val}</MenuItem>)}
      </DropdownButton>
    )
  }

  render() {
    const {
      remoteObjectSpec,
      pagination,
      isLoading,
      onPageChange,
      onSortChange,
      selectedColumns,
      deleteRemoteObjectItem,
      data
    } = this.props

    const {
      permissions
    } = this.state

    if (remoteObjectSpec.displayAttributes.filter((a) => a.summary).length === 0) {
      return (<center><h2>No summary attributes selected!</h2></center>)
    }

    return (
      <Spin spinning={this.state.bulkAssignLoading} tip={__("It may take a while")}>
        <div>
          {remoteObjectSpec?.displayAttributes?.length > 0
            && (
              <TableFirstChildTdHiddenOverflow>
                <BootstrapTable
                  keyField='id'
                  inverse={true}
                  data={data}
                  ref="remoteObjectTable"
                  remote
                  pagination
                  hover
                  striped
                  bordered={true}
                  className="large-table"
                  fetchInfo={{dataTotalSize: pagination.totalCount}}
                  options={{
                    onPageChange: onPageChange.bind(this),
                    onSortChange: onSortChange.bind(this),
                    noDataText: isLoading ? __('loading_data') : __('NoDataFound'),
                    hideSizePerPage: false,
                    firstPage: 'First',
                    lastPage: 'Last',
                    paginationShowsTotal: renderResultInfo.bind(this),
                    page: pagination.pageNumber,
                    sizePerPageList: config.pageSizeOptions,
                    sizePerPage: pagination.pageSize
                  }}
                >
                  {remoteObjectSpec.displayAttributes.map((a) => {
                    const columnData = extractDetailedDisplayableColumn({
                      field: a,
                      selectedColumns: selectedColumns,
                      detailedFields: this.props.attributesConfiguration
                    })
                    // TODO: this need to be review. do we need this here? should be using the summary information to compute the show cols and then ignore the summary from the second visit?
                    if (!columnData || !a.summary) return null
                    return (
                      <TableHeaderColumn
                        dataSort
                        dataFormat={(cell, row) => renderCAlgorithm(row.displayData[columnData.id], row, columnData,
                          extractDisplayConfiguration(this.props.remoteObjectSpec.displayAttributes, a.attributeId))}
                        key={columnData.id}
                        dataField={columnData.id}
                      >
                        {__(a.propertyLabel || columnData.propertyLabel)}
                      </TableHeaderColumn>
                    )
                  })}
                  <TableHeaderColumn
                    className="edit-remote-object"
                    width="100"
                    dataFormat={(cell, row) => !row.accessRightsInfo?.edit ? null : (
                      <div style={{display: 'flex'}}>
                        {permissions?.hasRoeReadAccess && (
                          <Tooltip title={__('Details')}>
                            <div
                              className="btn-no-padding btn-std"
                              onClick={() => {
                                const {objectTypeId, id} = remoteObjectSpec
                                if (!objectTypeId || !id || !row.id) return null
                                localStorage.removeItem('mostRecentItems')
                                hashHistory.push(`/${config.remoteObjectDetailsUrl}/${objectTypeId}/${id}/${row.id}`)
                              }}
                            >
                              <SearchOutlined/>
                            </div>
                          </Tooltip>
                        )}
                        {permissions?.hasRoeEditAccess && permissions?.canDeleteRemoteObjectItem && (
                          <Popconfirm
                            placement="topRight"
                            title={__('Are you sure you want to delete')}
                            onConfirm={() => {
                              const {objectTypeId, systemId} = remoteObjectSpec
                              if (!objectTypeId || !systemId || !row.id) return null
                              deleteRemoteObjectItem({
                                primaryKey: row.id,
                                systemId,
                                objectTypeId
                              })
                            }}
                            okText={__('Yes')}
                            cancelText={__('No')}
                          >
                            <Tooltip title={__('Delete')}>
                              <div className="btn-no-padding btn-std icon-delete">
                                <DeleteOutlined/>
                              </div>
                            </Tooltip>
                          </Popconfirm>
                        )}
                      </div>
                    )}
                  />
                </BootstrapTable>
              </TableFirstChildTdHiddenOverflow>
            )}
        </div>
      </Spin>
    )
  }
}

RemoteObjectTable.propTypes = {
  data: PropTypes.array,
  remoteObjectSpec: PropTypes.object,
  remoteObjectRecordsSpec: PropTypes.object,
  selectedColumns: PropTypes.array,
  attributesConfiguration: PropTypes.array,
  pagination: PropTypes.object,
  isLoading: PropTypes.bool,
  refreshData: PropTypes.func,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  setPageSize: PropTypes.func.isRequired,
  viewConfiguration: PropTypes.object
}

RemoteObjectTable.defaultProps = {
  data: [],
  remoteObjectSpec: [],
  selectedColumns: [],
  attributesConfiguration: [],
  pagination: {},
}

export default connect((state) => ({
  guiUser: state.user.data,
}), {deleteRemoteObjectItem})(RemoteObjectTable)
