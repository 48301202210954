import React from 'react'
import {Icon} from '@ant-design/compatible'
import styled from "styled-components/macro";

const {COMPANY, ENTITY, USER} = global.constants.entityTypes

export const getCompaniesTree = (companies, onlyLeafs = false) => {
  return companies?.map(company => ({
    ...company,
    totUsers: company.children.reduce((acc, entity) => {
      if (!entity?.users.length) return acc;
      return Number(acc + entity.users.length)
    }, 0)
  }))
    .filter(company => company.totUsers !== 0)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(company => ({
      title: (
        <NodeTitle>
          <Icon type="cluster"/> {company.name} - {company.shortName} ({company.totUsers})
        </NodeTitle>
      ),
      name: company.name,
      shortName: company.shortName,
      totUsers: company.totUsers,
      searchValue: `${company.name} (${company.shortName})`,
      key: JSON.stringify({
        type: COMPANY,
        entityId: company.id,
      }),
      treeCheckable:false,
      disabled: true,
      isLeaf: company.totUsers === 0,
      children: company.children?.map((entity) => ({
        title: (
          <NodeTitle>
            <Icon type={entity.type === 'organisation' ? 'cluster' : 'team'}/> {entity.name} ({entity.users?.length})
          </NodeTitle>
        ),
        searchValue: entity.name,
        key: JSON.stringify({
          type: ENTITY,
          entityId: entity.id,
        }),
        disabled: onlyLeafs,
        children: entity.users?.map((user) => ({
          title: (
            <NodeTitle>
              <Icon type="user"/> {user.firstName} {user.lastName} - {user.username} ({entity.shortName})
            </NodeTitle>
          ),
          searchValue: `${user.firstName} ${user.lastName} (${user.username})`,
          key: JSON.stringify({
            type: USER,
            userId: user.id,
            entityId: entity.id,
          })
        }))
      }))
    }))
}

export const NodeTitle = styled.div`
  font-size:1.25rem;
  display: flex;
  align-items: center;
  white-space: pre;
  svg {
    color: ${(props) => props.theme.colors.thirdary};
    font-size:1.5rem;
    margin-right: .5rem;
  }
  small {
    margin-left: .25rem;
    color: ${(props) => props.theme.colors.grey.primary};
  }
`
