import _ from 'lodash'

export function extractConfiguration (config, attributeId, idKey) {
  return _.find(config, (c) => c[idKey || 'id'] === attributeId)
}

export function extractDisplayConfiguration (config, attributeId) {
  return extractConfiguration(config, attributeId, 'attributeId')
}

export function extractDetailedDisplayableColumn (props) {
  const { field, selectedColumns, detailedFields } = props || {}
  const detailedField = extractConfiguration(detailedFields, field?.attributeId)
  if ((selectedColumns?.indexOf(detailedField?.propertyLabel) < 0) || !detailedField?.displayable) return null
  return detailedField
}

export function computeFiltersCount (formValues, initialValues) {
  if (!formValues || Object.keys(formValues).length === 0) return 0
  return Object.keys(formValues).reduce((totFilled, value) => {
    if (!formValues[value] || (Array.isArray(formValues[value]) && formValues[value].length === 0) || Object.keys(formValues[value]).length === 0) {
      if (!initialValues[value] || (Array.isArray(initialValues[value]) && initialValues[value].length === 0) || Object.keys(initialValues[value]).length === 0) {
        return totFilled
      }
      return totFilled + 1
    }
    if (Array.isArray(formValues[value])) {
      if (_.isEqual(formValues[value], initialValues[value])) return totFilled
      return totFilled + 1
    }
    if (typeof formValues[value] === 'object') {
      if (JSON.stringify(formValues[value]) === JSON.stringify(initialValues[value])) return totFilled
      return totFilled + 1
    }
    if (formValues[value] === initialValues[value]) return totFilled
    return totFilled + 1
  }, 0)
}
