import * as C from "../constants";
import { features, mechanism, visibleFor } from "../../../components/ConfigurationConfiguratorBeta/constants";
import {
  getInitialShowPhasesItems,
  getShowPhasesItems
} from "../../../components/ConfigurationConfiguratorBeta/utils";

export const mapFrontendItemToBackend = (configuration, step) => {
  switch (step) {
    case C.steps.OUTPUT_FIELDS:
      return {
        position: configuration.index,
        attributeId: configuration.attributeId,
        controllerType: configuration.controllerType,
        propertyLabel: configuration.customTitle,
        summary: configuration.summary,
      }
    case C.steps.FILTERS:
      return {
        position: configuration.index,
        attributeId: configuration.attributeId,
        mechanism: configuration.mechanism,
        value: configuration.mechanism === mechanism.SPECIFIC_VALUE
          ? configuration.defaultValue
          : (configuration.mechanism === mechanism.ENUMERATION
            ? (configuration.multiSelect
              ? (configuration.defaultValue.length ? configuration.defaultValue.join(',') : null)
              : configuration.defaultValue)
            : null),
        from: configuration.defaultValue?.from,
        to: configuration.defaultValue?.to,
        exactMatch: configuration.mechanism === mechanism.SPECIFIC_VALUE ? !!configuration.exactMatch : undefined,
        required: configuration.required,
        hidden: configuration.hidden,
        propertyLabel: configuration.customTitle,
        multiSelect: configuration.multiSelect,
      }
    case C.steps.SORTING_PARAMETERS:
      return {
        position: configuration.index,
        attributeId: configuration.attributeId,
        value: configuration.sortMethod,
        propertyLabel: configuration.customTitle,
        enabled: configuration.defaultEnable,
      }
    case C.steps.VIEW_PROPERTIES:
      return {
        name: configuration.viewName,
        type: configuration.visibleFor,
        onlySelfOrders: configuration.onlyMyOrders,
        entityIds: configuration.visibleFor === visibleFor.SPECIFIC_ENTITIES ? configuration.selectEntities.map((entity) => ({ id: entity })) : [],
        settings: {
          pageLayout: {
            pageSize: configuration.defaultPageSize,
            listingLayout: {
              defaultSelected: configuration.defaultLayout,
              available: configuration.layout
            },
            showSnapshot: configuration.features?.includes(features.SNAPSHOT),
            onlySelfOrders: configuration.onlyMyOrders
          },
          allowBatchOperations: configuration.features?.includes(features.MULTIPLE_SELECTION),
          entryFeatures: {
            available: configuration.features?.filter((feature) => (feature !== features.MULTIPLE_SELECTION && feature !== features.SNAPSHOT))
          },
          showOrderPhases: getShowPhasesItems().reduce((acc, phase) => {
            acc[phase.value] = configuration.showPhases?.includes(phase.value)
            return acc
          }, {})
        },
      }
    default:
      return null
  }
}

export const mapFrontendItemsToBackend = (configurationsMap, step) => (
  Object.keys(configurationsMap).map((configurationKey) => (
    mapFrontendItemToBackend({
      ...configurationsMap[configurationKey],
      attributeId: configurationKey
    }, step)
  ))
)

export const mapFrontendPayloadToBackend = (payload, userId) => (
  {
    modifierId: userId,
    creatorId: payload.additionalInfo?.creatorId || null,
    id: payload.additionalInfo?.id || null,
    displayAttributes: mapFrontendItemsToBackend(payload[C.steps.OUTPUT_FIELDS], C.steps.OUTPUT_FIELDS),
    searchAttributes: mapFrontendItemsToBackend(payload[C.steps.FILTERS], C.steps.FILTERS),
    sortAttributes: mapFrontendItemsToBackend(payload[C.steps.SORTING_PARAMETERS], C.steps.SORTING_PARAMETERS),
    ...mapFrontendItemToBackend(payload[C.steps.VIEW_PROPERTIES], C.steps.VIEW_PROPERTIES)
  }
)

export const mapBackendItemToFrontend = (item, step) => {
  switch (step) {
    case C.steps.OUTPUT_FIELDS:
      return {
        index: item.position,
        controllerType: item.controllerType,
        defaultControllerType: item.defaultControllerType,
        defaultCustomTitle: item.defaultCustomTitle,
        customTitle: item.propertyLabel,
        summary: item.summary,
      }
    case C.steps.FILTERS:
      return {
        index: item.position,
        mechanism: item.mechanism,
        defaultValue: item.mechanism === mechanism.RANGE ? { from: item.from, to: item.to } : (item.mechanism === mechanism.ENUMERATION && item.multiSelect ? (item.value ? item.value.split(',') : []) : item.value),
        exactMatch: item.exactMatch,
        required: item.required,
        hidden: item.hidden,
        defaultCustomTitle: item.defaultCustomTitle,
        customTitle: item.propertyLabel,
        multiSelect: item.multiSelect,
      }
    case C.steps.SORTING_PARAMETERS:
      return {
        index: item.position,
        sortMethod: item.value,
        defaultCustomTitle: item.defaultCustomTitle,
        customTitle: item.propertyLabel,
        defaultEnable: item.enabled,
      }
    case C.steps.VIEW_PROPERTIES:
      return {
        viewName: item.name,
        visibleFor: item.type,
        onlyMyOrders: item.onlySelfOrders,
        selectEntities: item.entityIds?.map((entity) => (entity.id)),
        layout: item.settings?.pageLayout?.listingLayout?.available || [item.settings?.pageLayout?.listingLayout?.defaultSelected],
        defaultLayout: item.settings?.pageLayout?.listingLayout?.defaultSelected,
        defaultPageSize: item.settings?.pageLayout?.pageSize,
        [C.viewPropertiesFields.SHOW_PHASES]: getInitialShowPhasesItems(item.settings?.showOrderPhases),
        features: [
          ...(item.settings?.entryFeatures?.available || []),
          ...(item.settings?.pageLayout?.showSnapshot ? [features.SNAPSHOT] : []),
          ...(item.settings?.allowBatchOperations ? [features.MULTIPLE_SELECTION] : [])
        ],
      }
    default:
      return null
  }
}

export const mapBackendItemsToFrontendPayload = (items, step, attributesMap) => (
  items.reduce((prev, item) => {
    const newItem = { ...item, defaultCustomTitle: attributesMap[item.attributeId]?.propertyLabel }
    return { ...prev, [newItem.attributeId]: mapBackendItemToFrontend(newItem, step) }
  }, {})
)

export const mapBackendPayloadToFrontend = (payload, attributesMap) => (
  {
    [C.steps.OUTPUT_FIELDS]: mapBackendItemsToFrontendPayload(payload.displayAttributes, C.steps.OUTPUT_FIELDS, attributesMap),
    [C.steps.FILTERS]: mapBackendItemsToFrontendPayload(payload.searchAttributes, C.steps.FILTERS, attributesMap),
    [C.steps.SORTING_PARAMETERS]: mapBackendItemsToFrontendPayload(payload.sortAttributes, C.steps.SORTING_PARAMETERS, attributesMap),
    [C.steps.VIEW_PROPERTIES]: mapBackendItemToFrontend(payload, C.steps.VIEW_PROPERTIES),
    additionalInfo: {
      creatorId: payload.creatorId,
      id: payload.id
    }
  }
)
