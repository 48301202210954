import styled from "styled-components";
import { Checkbox } from "antd";

export const CustomCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper {
    width: 100%;
    margin: 0;
    padding: 12px 16px;
    transition-duration: .2s;
    font-weight: 700;
    border-bottom: 1px solid #0a4f7a;;
    &:hover {
      background-color: #fafafa;
    }
    &.ant-checkbox-wrapper-checked {
      background-color: #f5f5f5;
      &:hover {
        background-color: #efefef;
      }
    }
  }
`

export const CustomCheckboxGroup = styled(Checkbox.Group)`
  &.ant-checkbox-group {
    display: flex;
    flex-direction: column;
    max-height: min(400px, 90vh);
    min-width: 200px;
    overflow: auto;
    > .ant-checkbox-group-item {
      margin: 0;
      padding: 8px 16px;
      transition-duration: .2s;
      &:hover {
        background-color: #fafafa;
      }
      &:not(:first-child) {
        border-top: 1px solid #e8e8e8;
      }
      &.ant-checkbox-wrapper-checked {
        background-color: #f5f5f5;
        &:hover {
          background-color: #efefef;
        }
      }
    }
  }
`
