/* eslint-disable no-console */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import Moment from 'moment'
import { Button, Space, TreeSelect, Checkbox } from 'antd'
import { Icon } from '@ant-design/compatible'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { api } from '../../../providers/ApiProvider'

import * as S from './styles'
import Dashboard from './Dashboard'
import getCompaniesTree from './utility/getCompaniesTree'
import getSelectedUsersIds from './utility/getSelectedUsersIds'
import Loading from '../../Loading';
import { __ } from '../../../utils/translationUtils'
import {langToMomentLocale} from "../../../utils/langUtils";

const { SHOW_PARENT } = TreeSelect
const { WORKING_EVENT, NON_WORKING_EVENT, GODOO_EVENT } = global.constants.eventTypes
const { DAY, WEEK, MONTH } = global.constants.calendarView
const { CALENDAR, TIMELINE, AGGREGATED } = global.constants.calendarMode

// parsedCalendarSelection: array di stringhe
// albero

// scopo: parsedCalendarSelection deve esser filtrato, controllando che ciascun di quei valori
// sia in qualche modo contenuto in un punto dell albero, a profondita ignota

function cleanCalendarSelection({ parsedCalendarSelection, companiesTree }) {
  const flaggedValues = []

  function checkFlack({ key, children }) {
    const foundValue = parsedCalendarSelection.find((e) => e === key)
    if (foundValue) flaggedValues.push(foundValue)
    if (children?.length) children.forEach(checkFlack)
  }

  companiesTree.forEach(checkFlack)

  return flaggedValues
}

export default ({ selectable = false, editable = false, calendarControlProps }) => {
  const userId = useSelector((state) => state?.user?.data?.id)
  const attributes = useSelector((state) => state?.ui?.attributes)
  const [refetchCount, setRefetchCount] = useState(0)
  const [selectedEventTypes, setSelectedEventTypes] = useState([WORKING_EVENT, NON_WORKING_EVENT, GODOO_EVENT])
  const [eventTypeCodes, setEventTypeCodes] = useState(null)
  const [calendarView, setCalendarView] = useState(WEEK)
  const [viewMode, setViewMode] = useState(CALENDAR)
  const [isLoading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [isSidebarOpen, setSidebarOpen] = useState(true)
  const [selectedDate, setSelectedDate] = useState(new Moment())
  const [defaultSelectedCompanies, setDefaultSelectedCompanies] = useState([])
  const [preSelectedCompanies, preSelectCompanies] = useState(defaultSelectedCompanies)
  const [selectedCompanies, selectCompanies] = useState(defaultSelectedCompanies)
  const [companiesTree, setCompaniesTree] = useState(null)
  const [attachedUsersMap, setAttachedUsersMap] = useState(null)

  const languageCode = useSelector((state) => state?.ui?.language)
  const isToday = useMemo(() => Moment(new Date()).isSame(selectedDate, DAY), [selectedDate])
  const selectedUsersIds = useMemo(() => getSelectedUsersIds({ selectedCompanies, companiesTree }), [selectedCompanies, companiesTree])
  const selectedUsers = useMemo(() => users.filter((user) => selectedUsersIds.find((id) => (id === user.id))), [users, selectedUsersIds])
  const selectedDateLabel = useMemo(() => Moment(selectedDate).format(`${calendarView === DAY ? 'D' : ''} MMMM yyyy`), [selectedDate, calendarView])

  useEffect(() => {
    if (viewMode === AGGREGATED) setCalendarView(WEEK)
  }, [viewMode])

  useEffect(() => {
    preSelectCompanies(defaultSelectedCompanies)
    selectCompanies(defaultSelectedCompanies)
  }, [defaultSelectedCompanies])

  useEffect(() => { // update default selected company
    if ((selectedCompanies?.length > 0) || (defaultSelectedCompanies?.length > 0 && !selectedCompanies?.length)) {
      if (!localStorage.calendarDefaultSelection) {
        localStorage.calendarDefaultSelection = '{}'
      }
      let content = JSON.parse(localStorage.calendarDefaultSelection)
      if (Array.isArray(content)) {
        content = {}
      }
      content[userId] = JSON.stringify(selectedCompanies)
      localStorage.calendarDefaultSelection = JSON.stringify(content)
    }
  }, [defaultSelectedCompanies.length, selectedCompanies])

  const handleRefetch = useCallback(() => {
    setRefetchCount((c) => ++c)
  }, [setRefetchCount])

  const handleUpdateCompanies = useCallback(() => {
    if (JSON.stringify(preSelectedCompanies) === JSON.stringify(selectedCompanies)) {
      handleRefetch()
      return
    }
    selectCompanies(preSelectedCompanies)
  }, [preSelectedCompanies, selectCompanies, handleRefetch, selectedCompanies])

  useEffect(() => {
    function manageEntityFromUsers(response) {
      const result = response.slice()
      // eslint-disable-next-line no-restricted-syntax
      for (const user of result) {
        Object.assign(user, { availableEntities: user.belongsTo })
      }
      return response
    }

    async function fetchInitialData() {
      setLoading(true)

      const newUsers = await api.get('users?pageSize=500&status=enabled,reserved').then((data) => data.data.map(((user) => ({ ...user, title: `${user.firstName} ${user.lastName}` }))))
      const companies = await api.get('/hierarchy/organisations?withUsers=true')
        .then((data) => data.data)
        .catch((e) => console.log(e))

      api.get('/entities/users')
        .then(({ data }) => { setAttachedUsersMap(data) })
        .catch((e) => console.log(e))

      // eslint-disable-next-line no-shadow
      const companiesTree = getCompaniesTree(companies)

      // eslint-disable-next-line no-underscore-dangle
      let _assignees = manageEntityFromUsers(newUsers)

      _assignees = _.sortBy(_assignees, (assignee) => assignee.username.toLowerCase())

      setUsers(_assignees)

      // const evenTypeCodesFromApi = await api.get('${config.prefix()?.CALENDAR || ''}/nonWorkingPeriods/types?reservedFor=user').then((data) => data.data).catch((e) => console.log(JSON.stringify(e)))
      setEventTypeCodes(global.constants.eventTypeCodes.user)
      setCompaniesTree(companiesTree)
      // depending on companiesTree visible by the user, I only want the clean version
      let parsedCalendarSelection = []
      if (localStorage.calendarDefaultSelection) {
        const calendarDefaultSelectionGeneral = JSON.parse(localStorage.calendarDefaultSelection)
        if (calendarDefaultSelectionGeneral[userId]) {
          parsedCalendarSelection = JSON.parse(calendarDefaultSelectionGeneral[userId])
        }
      }
      const cleanedCalendarSelection = cleanCalendarSelection({ parsedCalendarSelection, companiesTree })
      setDefaultSelectedCompanies(cleanedCalendarSelection)

      setLoading(false)
    }
    if (userId) {
      fetchInitialData()
    }
  }, [userId])

  const handleSetToday = useCallback(() => {
    setSelectedDate(Moment())
  }, [setSelectedDate])

  const handleSetDateBefore = useCallback(() => {
    setSelectedDate(Moment(selectedDate).add(-1, calendarView))
  }, [calendarView, selectedDate])

  const handleSetDateAfter = useCallback(() => {
    setSelectedDate(Moment(selectedDate).add(1, calendarView))
  }, [calendarView, selectedDate])

  const handleSidebarToggler = useCallback(() => {
    setSidebarOpen((e) => !e)
  }, [setSidebarOpen])

  function handlePrint() {
    window.print()
  }

  const handleShowMore = useCallback((events, date) => {
    setCalendarView(DAY)
    setSelectedDate(Moment(date))
  }, [setCalendarView, setSelectedDate])

  const handleNavigate = useCallback((date) => {
    setCalendarView(DAY)
    setSelectedDate(Moment(date))
  }, [setCalendarView, setSelectedDate])

  return (
    <S.CalendarsDashboard>
      <Loading loading={isLoading} />
      <S.Top className="dont-print" isSidebarOpen={isSidebarOpen}>
        <S.TopLeft className="dont-print">
          <Space size="large">
            <S.SidebarToggler className="dont-print" onClick={handleSidebarToggler}><Icon type={isSidebarOpen ? 'menu-fold' : 'menu-unfold'} /></S.SidebarToggler>
            <S.ButtonWrapper isToday={isToday}><Button onClick={handleSetToday}>{__('Today')}</Button></S.ButtonWrapper>
            <S.IconWrapper><Icon onClick={handleSetDateBefore} type="left" /></S.IconWrapper>
            <S.IconWrapper><Icon onClick={handleSetDateAfter} type="right" /></S.IconWrapper>
            <S.SelectedDate isToday={isToday}>{selectedDateLabel}</S.SelectedDate>
          </Space>
        </S.TopLeft>
        {
          viewMode !== AGGREGATED && (
            <S.TopRight className="dont-print">
              <Space>
                <S.ButtonWrapper isActive={calendarView === DAY}><Button onClick={() => { setCalendarView(DAY) }}>{__('Day')}</Button></S.ButtonWrapper>
                <S.ButtonWrapper isActive={calendarView === WEEK}><Button onClick={() => { setCalendarView(WEEK) }}>{__('Week')}</Button></S.ButtonWrapper>
                <S.ButtonWrapper isActive={calendarView === MONTH}><Button onClick={() => { setCalendarView(MONTH) }}>{__('Month')}</Button></S.ButtonWrapper>
              </Space>
            </S.TopRight>
          )
        }
      </S.Top>
      <S.Content>
        <S.ContentLeft className="dont-print" isSidebarOpen={isSidebarOpen}>
          <Space direction="vertical" style={{ width: '100%' }} size="middle">
            <Space direction="vertical" style={{ width: '100%' }} size="small">
              <S.DatePickerWrapper>
                <DatePicker
                  selected={selectedDate}
                  onChange={setSelectedDate}
                  inline
                  locale={languageCode ? langToMomentLocale(languageCode) : 'en-gb'}
                  dropdownMode={"select"}
                />
              </S.DatePickerWrapper>
            </Space>

            <Space direction="vertical" style={{ width: '100%' }} size="small">
              <S.Label>{__('Users')}</S.Label>
              <S.TreeSelectWrapper style={{ position: 'relative' }}>
                <TreeSelect
                  style={{ width: '100%' }}
                  value={preSelectedCompanies}
                  onChange={preSelectCompanies}
                  dropdownStyle={{ maxHeight: 500, overflowY: 'scroll' }}
                  treeData={companiesTree}
                  fieldNames={{ label: 'title', value: 'key', children: 'children' }}
                  treeCheckable
                  multiple
                  treeNodeFilterProp="searchValue"
                  showCheckedStrategy={SHOW_PARENT}
                  placeholder={__('Search user(s)')}
                  maxTagCount={2}
                />
                <S.TreeSelectSearch onClick={handleUpdateCompanies}>
                  <Icon type="search" />
                </S.TreeSelectSearch>
                {(!(selectedCompanies?.length && attributes)) && <S.Hint className="bounce" style={{ position: 'absolute', left: '100%', top: 0, margin: '6px 30px', width: '100%' }}><span role="img" aria-label="emoji">👈</span>{__('Select Users')}</S.Hint>}
              </S.TreeSelectWrapper>
            </Space>
            <Space direction="vertical" style={{ width: '100%' }} size="small">
              <S.Label>{__('View')}</S.Label>
              <Space>
                <S.ButtonWrapper isActive={viewMode === CALENDAR}><Button onClick={() => { setViewMode(CALENDAR) }}>{__('Calendar')}</Button></S.ButtonWrapper>
                <S.ButtonWrapper isActive={viewMode === TIMELINE}><Button onClick={() => { setViewMode(TIMELINE) }}>{__('Planning')}</Button></S.ButtonWrapper>
                <S.ButtonWrapper isActive={viewMode === AGGREGATED}><Button onClick={() => { setViewMode(AGGREGATED) }}>{__('Aggregated')}</Button></S.ButtonWrapper>
              </Space>
            </Space>
            <Space direction="vertical" style={{ width: '100%' }} size="small">
              <S.Label>{__('Show')}</S.Label>
              <Checkbox.Group
                options={[
                  {
                    label: `🕒 ${__('Working hours')}`,
                    value: WORKING_EVENT
                  },
                  {
                    label: `📅  ${__('Assigned orders')}`,
                    value: GODOO_EVENT
                  },
                  {
                    label: `🏠  ${__('Personal events')}`,
                    value: NON_WORKING_EVENT
                  }
                ]}
                defaultValue={selectedEventTypes}
                onChange={setSelectedEventTypes}
              />
            </Space>
            <S.PrintWrapper>
              <S.ButtonWrapper className="dont-print">
                <Space>
                  <Button size="regular" onClick={handlePrint}> <Icon type="printer" /> {__('Print Calendar')}</Button>
                  <Button size="regular" onClick={handleRefetch}> <Icon type="reload" /> {__('Reload')}</Button>
                </Space>
              </S.ButtonWrapper>
            </S.PrintWrapper>
          </Space>
        </S.ContentLeft>
        <S.ContentRight>
          {
            (selectedUsers?.length && attributes)
              ? (
                <Dashboard
                  selectedUsers={selectedUsers}
                  selectedCompanies={selectedCompanies}
                  selectedDate={selectedDate}
                  calendarView={calendarView}
                  viewMode={viewMode}
                  attributes={attributes}
                  selectedEventTypes={selectedEventTypes}
                  selectable={selectable}
                  editable={editable}
                  calendarControlProps={calendarControlProps}
                  eventTypeCodes={eventTypeCodes}
                  onShowMore={handleShowMore}
                  onNavigate={handleNavigate}
                  refetchCount={refetchCount}
                  companiesTree={companiesTree}
                  attachedUsersMap={attachedUsersMap}
                />
              )
              : <S.Text>{__('Please select a user')}</S.Text>
          }
        </S.ContentRight>

      </S.Content>

    </S.CalendarsDashboard>
  )
}
